import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateRangePicker, {
  DateRangePickerProps,
} from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { FC } from 'react';

type DateRangePickerUIProps = Omit<DateRangePickerProps<Date>, 'renderInput'>;
const DateRangePickerUI: FC<DateRangePickerUIProps> = ({
  value,
  startText,
  endText,
  onChange,
  ...props
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        {...props}
        startText={startText}
        endText={endText}
        value={value}
        onChange={onChange}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField {...startProps} />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField {...endProps} />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
};

export { DateRangePickerUI };
