import Dialog from '@mui/material/Dialog';
import { FC, useEffect, useState } from 'react';
import { ButtonRounded } from '../ButtonRounded';
import {
  Icon,
  StyleDialogActions,
  StyleDialogContent,
  SubTitle,
  Title,
} from './DialogAlertMessage.styled';

export type DialogAlertMessageProps = {
  message: string;
  typeStatus: string;
  onCloseDialog: () => void;
};

const DialogAlertMessage: FC<DialogAlertMessageProps> = ({
  message,
  typeStatus,
  onCloseDialog,
}) => {
  const [open, setOpenDialog] = useState(false);

  useEffect(() => {
    if (message) {
      setOpenDialog(true);
    } else {
      setOpenDialog(false);
    }
  }, [message]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    onCloseDialog();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-alert"
      open={open}
    >
      <StyleDialogContent>
        <Icon
          src={
            typeStatus === 'error'
              ? '/theme/assets/svg/close.svg'
              : '/theme/assets/svg/success.svg'
          }
        />
        <Title variant="h4">
          {typeStatus === 'error' ? 'Oops...' : 'Success'}
        </Title>
        <SubTitle variant="body1">{message}</SubTitle>
      </StyleDialogContent>
      <StyleDialogActions>
        <ButtonRounded
          autoFocus
          variant="contained"
          onClick={handleCloseDialog}
          color="primary"
          data-test-id="btn-ok"
        >
          OK
        </ButtonRounded>
      </StyleDialogActions>
    </Dialog>
  );
};

export { DialogAlertMessage };
