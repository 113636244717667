import { styled } from '@mui/material/styles';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

export const StyleDialogActions = styled(DialogActions)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled('img')`
  display: block;
  width: 3rem;
`;

export const StyleDialogContent = styled(DialogContent)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled(Typography)`
  font-weight: 500;
  margin: 1rem;
`;

export const SubTitle = styled(Typography)`
  margin: 1rem;
`;
