import { TableCell, TableRow } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { FC, Fragment } from 'react';

type TableRowSkeletonProps = {
  cellLength: number;
  skeletonLength?: number;
  loading?: boolean;
};

const TableRowSkeleton: FC<TableRowSkeletonProps> = ({
  cellLength,
  skeletonLength = 10,
  loading = false,
  children,
}) => {
  const cellTableLength: string[] = new Array(cellLength).fill('');
  const skeletonTableRow: string[] = new Array(skeletonLength).fill('');

  const renderLoading = () => {
    return (
      <Fragment>
        {skeletonTableRow.map((val, index) => (
          <TableRow key={index}>
            {cellTableLength.map((cell, cellIndex) => (
              <TableCell key={cellIndex}>
                <Skeleton />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Fragment>
    );
  };

  return <Fragment>{loading ? renderLoading() : children}</Fragment>;
};

export { TableRowSkeleton };
