import {
  GroupDto,
  IUserList,
  IUserRepresentation,
} from '@bom-nextgen-keycloak/models';
import { useAlertMessage, useResource } from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchgroupListMembers,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  ButtonRounded,
  StyledDialogTitle,
} from '@bom-nextgen-keycloak/web/shared/ui';
import GetAppIcon from '@mui/icons-material/GetApp';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { LoaddingContainer, StyledDialog } from './ExportForm.styled';

type ExportFormDialogProps = {
  open: boolean;
  titleForm: string;
  groupId: string;
  onClose: () => void;
};

function not(
  a: readonly IUserRepresentation[],
  b: readonly IUserRepresentation[]
) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(
  a: readonly IUserRepresentation[],
  b: readonly IUserRepresentation[]
) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(
  a: readonly IUserRepresentation[],
  b: readonly IUserRepresentation[]
) {
  return [...a, ...not(b, a)];
}

const ExportFormDialog: FC<ExportFormDialogProps> = ({
  onClose,
  open,
  titleForm,
  groupId,
}) => {
  const resource = useResource();
  const { setAlertMessage } = useAlertMessage();
  const [checked, setChecked] = useState<readonly IUserRepresentation[]>([]);
  const [left, setLeft] = useState<readonly IUserRepresentation[]>([]);
  const [right, setRight] = useState<readonly IUserRepresentation[]>([]);
  const [activeUser, setActiveUser] = useState<boolean>(true);

  const { isLoading } = useQuery(
    [QUERY_KEY.EXPORT_USER_LIST, resource.groupId, groupId, activeUser],
    () =>
      fetchgroupListMembers(resource.groupId, {
        id: groupId,
        isAll: true,
        first: 0,
        max: 50,
      }).then((data) => ({
        ...data,
        items: data.items.filter((item) => item.enabled === activeUser),
      })),
    {
      onSettled: (data: IUserList | undefined) => {
        setLeft(data?.items || []);
        setRight([]);
      },
      onError: (error: AxiosError<ErrorMessage>) => {
        const message = error.response?.data.message || 'Cannot get group list';
        setAlertMessage({ message, typeStatusMessage: 'error' });
      },
    }
  );

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: IUserRepresentation) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: readonly IUserRepresentation[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: readonly IUserRepresentation[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveUser(event.target.checked);
  };

  const handleExportFile = () => {
    if (right && right.length) {
      const emails = right.map((user) => user.email).join(',');
      const element = document.createElement('a');
      const file = new Blob([emails], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = `member-${new Date().toISOString()}.txt`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  };

  const customList = (
    title: React.ReactNode,
    items: readonly IUserRepresentation[]
  ) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      {isLoading ? (
        <LoaddingContainer>
          <CircularProgress />
        </LoaddingContainer>
      ) : (
        <List
          sx={{
            width: 200,
            height: 230,
            bgcolor: 'background.paper',
            overflow: 'auto',
          }}
          dense
          component="div"
          role="list"
        >
          {items.map((value: IUserRepresentation) => {
            const labelId = `transfer-list-all-item-${value.id}-label`;
            return (
              <ListItem
                key={value.id}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value?.email || '-'} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      )}
    </Card>
  );

  return (
    <StyledDialog onClose={onClose} aria-labelledby="dialog-title" open={open}>
      <StyledDialogTitle id="dialog-title">
        Export Member {titleForm}
      </StyledDialogTitle>
      <DialogContent dividers>
        <Box sx={{ display: 'flex', justifyContent: 'end', mb: 3 }}>
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={activeUser} onChange={handleChange} />}
              label={activeUser ? 'User active' : 'User inactive'}
            />
          </FormGroup>
        </Box>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>{customList('Choices', left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <ButtonRounded
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </ButtonRounded>
              <ButtonRounded
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </ButtonRounded>
            </Grid>
          </Grid>
          <Grid item>{customList('Chosen', right)}</Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonRounded onClick={onClose} color="primary">
          Cancel
        </ButtonRounded>
        <ButtonRounded
          type="submit"
          color="primary"
          variant="contained"
          loadingPosition="start"
          startIcon={<GetAppIcon />}
          disabled={right.length === 0}
          onClick={handleExportFile}
        >
          Export
        </ButtonRounded>
      </DialogActions>
    </StyledDialog>
  );
};

export { ExportFormDialog };
