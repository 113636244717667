import { selectPermissionUserDetail } from '@bom-nextgen-keycloak/web/core';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  BreadcrumbsLink,
  ProtectedRouter,
  useTabsWithRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import AirplayIcon from '@mui/icons-material/Airplay';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet, useParams } from 'react-router-dom';
import { useUserDetail } from './hooks';
import { StyledAppBar } from './UserDetail.styled';

const UserDetail: FC<{ userId: string }> = ({ userId }) => {
  const { userDetail } = useUserDetail(userId);
  const {
    canViewGeneralTab,
    canViewAttributesTab,
    canViewGroupTab,
    canViewPasswordTab,
    canViewRoleMappingTab,
    canViewSessionTab,
    canViewIdpTab,
  } = useSelector(selectPermissionUserDetail);

  const tabValue = useTabsWithRouter(
    [
      `/users/${userId}`,
      `/users/${userId}/attributes`,
      `/users/${userId}/credentials`,
      `/users/${userId}/groups`,
      `/users/${userId}/role-mappings`,
      `/users/${userId}/sessions`,
      `/users/${userId}/identity-provider-links`,
    ],
    `/users/${userId}`
  );

  return (
    <div>
      <BreadcrumbsLink
        name="Users"
        pathName="/users"
        detailName={userDetail?.username}
      />
      <StyledAppBar position="static">
        <Tabs
          value={tabValue}
          aria-label="simple tabs example"
          textColor="inherit"
          variant="fullWidth"
          indicatorColor="secondary"
        >
          {canViewGeneralTab && (
            <Tab
              component={Link}
              label="General"
              value={`/users/${userId}`}
              to={`/users/${userId}`}
              icon={<SettingsIcon />}
            />
          )}
          {canViewAttributesTab && (
            <Tab
              label="Attributes"
              component={Link}
              value={`/users/${userId}/attributes`}
              to={`/users/${userId}/attributes`}
              icon={<AssignmentIndIcon />}
            />
          )}

          {canViewPasswordTab && (
            <Tab
              component={Link}
              label="Password"
              value={`/users/${userId}/credentials`}
              to={`/users/${userId}/credentials`}
              icon={<LockOpenIcon />}
            />
          )}
          {canViewGroupTab && (
            <Tab
              component={Link}
              label="Groups"
              value={`/users/${userId}/groups`}
              to={`/users/${userId}/groups`}
              icon={<PeopleIcon />}
            />
          )}
          {canViewRoleMappingTab && (
            <Tab
              component={Link}
              label="Role Mappings"
              to={`/users/${userId}/role-mappings`}
              value={`/users/${userId}/role-mappings`}
              icon={<AssignmentReturnIcon />}
            />
          )}
          {canViewSessionTab && (
            <Tab
              component={Link}
              label="Sessions"
              to={`/users/${userId}/sessions`}
              value={`/users/${userId}/sessions`}
              icon={<AirplayIcon />}
            />
          )}

          {userDetail?.federatedIdentities &&
            userDetail?.federatedIdentities?.length > 0 &&
            canViewIdpTab && (
              <Tab
                component={Link}
                to={`/users/${userId}/identity-provider-links`}
                label="Identity Provider Links"
                value={`/users/${userId}/identity-provider-links`}
                icon={<InsertLinkIcon />}
              />
            )}
        </Tabs>
      </StyledAppBar>

      <Box sx={{ mt: 3 }}>
        <Outlet />
      </Box>
    </div>
  );
};

const WrappedUserDetailPage: FC = () => {
  const params = useParams();
  const { canViewPage } = useSelector(selectPermissionUserDetail);
  return (
    <ProtectedRouter
      isEditPage
      feature={FeaturePage.USER}
      canView={canViewPage}
    >
      <UserDetail userId={params.id || ''} />
    </ProtectedRouter>
  );
};

export { WrappedUserDetailPage };
