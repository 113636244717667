import { ButtonRounded } from '@bom-nextgen-keycloak/web/shared/ui';
import { styled } from '@mui/material/styles';
export const ActionWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

export const LoaddingContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
`;

export const GroupButton = styled('div')`
  display: flex;
  align-items: center;
`;

export const StyledButton = styled(ButtonRounded)`
  margin-right: 1rem;
`;
