import { selectPermissionResourceDetail } from '@bom-nextgen-keycloak/web/core';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  BreadcrumbsLink,
  ProtectedRouter,
  useTabsWithRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet, useParams } from 'react-router-dom';
import { useResourceDetail } from './hooks/useResourceDetail';
import { StyledAppBar } from './ResourceDetail.styled';

const ResourceDetail: FC<{ resourceId: string }> = ({ resourceId }) => {
  const { resourceDetail } = useResourceDetail(resourceId);
  const { canViewAttributesTab, canViewGeneralTab } = useSelector(
    selectPermissionResourceDetail
  );
  const tabValue = useTabsWithRouter(
    [`/resources/${resourceId}`, `/resources/${resourceId}/attributes`],
    `/resources/${resourceId}`
  );

  return (
    <div>
      <BreadcrumbsLink
        name="Resources"
        pathName="/resources"
        detailName={resourceDetail?.name}
      />
      <StyledAppBar position="static">
        <Tabs
          value={tabValue}
          aria-label="simple tabs example"
          textColor="inherit"
          variant="fullWidth"
          indicatorColor="secondary"
        >
          {canViewGeneralTab && (
            <Tab
              label="Details"
              component={Link}
              to={`/resources/${resourceId}`}
              value={`/resources/${resourceId}`}
              icon={<SettingsIcon />}
            />
          )}
          {canViewAttributesTab && (
            <Tab
              label="Attributes"
              component={Link}
              to={`/resources/${resourceId}/attributes`}
              value={`/resources/${resourceId}/attributes`}
              icon={<AssignmentIndIcon />}
            />
          )}
        </Tabs>
      </StyledAppBar>
      <Box sx={{ mt: 3 }}>
        <Outlet />
      </Box>
    </div>
  );
};

const WrappedResourceDetailPage: FC = () => {
  const params = useParams();
  const { canViewPage } = useSelector(selectPermissionResourceDetail);
  return (
    <ProtectedRouter
      feature={FeaturePage.RESOURCE}
      isEditPage
      canView={canViewPage}
      navigatePath="/resources"
    >
      <ResourceDetail resourceId={params.id || ''} />
    </ProtectedRouter>
  );
};

export { WrappedResourceDetailPage };
