import { styled } from '@mui/material/styles';
export const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled('h1')`
  text-align: center;
  white-space: pre-wrap;
  font-weight: 900;
  font-style: normal;
  font-size: 3rem;
  letter-spacing: 0.29em;
  line-height: 1em;
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
`;

export const SubTitle = styled('p')`
  font-size: 1.5rem;
  text-align: center;
  white-space: pre-wrap;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0em;
  line-height: 1.7em;
  text-transform: none;
  color: rgba(29, 29, 29, 0.7);
`;

export const Row = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Col = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const Image = styled('img')`
  display: block;
  max-width: 350px;
  height: 100%;
`;
