import {
  selectPermissionUserDetail,
  useAlertMessage,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  FeaturePage,
  fetchCredentials,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  PageLoading,
  ProtectedRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { CredentialTable } from './CredentialTable';
import { ResetPasswordForm } from './ResetPasswordForm';
import { AxiosError } from 'axios';

const Credential: FC = () => {
  const params = useParams();
  const userId = params?.id || '';
  const { setAlertMessage } = useAlertMessage();
  const navigate = useNavigate();

  const { isLoading, data: credentialList } = useQuery(
    [QUERY_KEY.USER_CREDENTIALS, userId],
    () => fetchCredentials(userId),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message = error.response?.data.message || 'Cannot get credential';
        setAlertMessage({
          message,
          typeStatusMessage: 'error',
          statusCode: error.response?.status,
        });
        navigate('/users');
      },
    }
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Manage Password
      </Typography>
      <CredentialTable
        listCrednetials={credentialList}
        isLoading={isLoading}
        userId={userId}
      />

      <PageLoading isLoading={isLoading}>
        <Typography variant="h5" sx={{ mb: 2, mt: 3 }}>
          {credentialList && credentialList?.length > 0
            ? 'Reset Password'
            : 'Set Password'}
        </Typography>

        <ResetPasswordForm
          isEmptyCredential={credentialList?.length === 0}
          userId={userId}
        />
      </PageLoading>
    </Box>
  );
};

const WrappedCredentialPage: FC = () => {
  const { canViewPasswordTab, canViewPage } = useSelector(
    selectPermissionUserDetail
  );
  return (
    <ProtectedRouter
      isEditPage
      feature={FeaturePage.USER}
      canView={canViewPasswordTab && canViewPage}
      navigatePath="/users"
    >
      <Credential />
    </ProtectedRouter>
  );
};

export { WrappedCredentialPage };
