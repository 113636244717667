import { GroupDto } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionUserDetail,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  FeaturePage,
  listGroupMembers,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { ProtectedRouter } from '@bom-nextgen-keycloak/web/shared/ui';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { AxiosError } from 'axios';
import { FC, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { GroupContainer, LoaddingContainer } from './Group.styled';
import { GroupAvailable } from './GroupAvailable/GroupAvailable';
import { GroupCompany } from './GroupCompany/GroupCompany';

const findNodeByID = (
  group: GroupDto | null | undefined,
  id: string | undefined
) => {
  let result = null;
  if (id === group?.id) {
    return group;
  } else {
    if (group?.subGroups) {
      group?.subGroups.some((node) => (result = findNodeByID(node, id)));
    }
    return result;
  }
};

const Group: FC = () => {
  const params = useParams();
  const queryClient = useQueryClient();
  const resource = useResource();
  const { setAlertMessage } = useAlertMessage();
  const userId = params?.id || '';
  const { isLoading, data: listMembers = [] } = useQuery(
    [QUERY_KEY.USER_GROUP_MEMBERS, resource.groupId, userId],
    () => listGroupMembers(userId, { groupId: resource.groupId }),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get group members';
        setAlertMessage({
          message,
          typeStatusMessage: 'error',
          statusCode: error.response?.status,
        });
      },
    }
  );

  useEffect(() => {
    return () =>
      queryClient.removeQueries([
        QUERY_KEY.USER_GROUP_MEMBERS,
        resource.groupId,
        userId,
      ]);
  }, [queryClient, resource.groupId, userId]);

  const renderGroup = () => {
    const company = listMembers.find((val) => val?.level === 2);
    return company ? (
      <GroupContainer>
        <GroupAvailable userId={userId} companyId={company.id} />
      </GroupContainer>
    ) : (
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <GroupCompany userId={userId} />
      </Box>
    );
  };

  return (
    <div>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoaddingContainer>
            <CircularProgress />
          </LoaddingContainer>
        </Box>
      ) : (
        renderGroup()
      )}
    </div>
  );
};

const WrappedGroupPage: FC = () => {
  const { canViewGroupTab, canViewPage } = useSelector(
    selectPermissionUserDetail
  );
  return (
    <ProtectedRouter
      isEditPage
      feature={FeaturePage.USER}
      canView={canViewGroupTab && canViewPage}
      navigatePath="/users"
    >
      <Group />
    </ProtectedRouter>
  );
};

export { WrappedGroupPage };
