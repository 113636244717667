import {
  GroupListMemberQueryDto,
  IFederatedIdentities,
  IUserList,
  RoleRepresentationDto,
  UserClientRoleMappings,
  UserCredentials,
  UserDto,
  UserGroupMemeberDto,
  UserGroupQueryDto,
  UserJoinGroupDto,
  UserQueryDto,
  UserRegisterDto,
  UserRepresentationDto,
  UserResetPasswordDto,
  UserSessions,
  UserUpdateDto,
  UserValidEmailDto,
  UserValidEmailReponse,
} from '@bom-nextgen-keycloak/models';
import { axiosInstance } from '@bom-nextgen-keycloak/web/shared/util';
import { AxiosResponse } from 'axios';

export type UpdateUserParams = {
  id: string;
  payload: UserUpdateDto;
};

export type ResetPasswordUserParams = {
  id: string;
  payload: UserResetPasswordDto;
};

export type RemoveCredentials = {
  userId: string;
  credentialId: string;
};

export type RemoveUserGroupParams = {
  userId: string;
  groupId: string;
  clientId: string;
};

export type UserRoleMappingParams = {
  userId: string;
  clientId: string;
  payload: UserClientRoleMappings;
};

export type PayloadDeleteUser = {
  userId: string;
  groupId: string;
};

export const fetchUser = (params: UserQueryDto) =>
  axiosInstance
    .get('/users', { params: { ...params } })
    .then(({ data }: AxiosResponse<IUserList>) => data);

export const fetchUsergroupListMembers = (params: GroupListMemberQueryDto) =>
  axiosInstance
    .get(`/users/list-member`, { params: { ...params } })
    .then(({ data }: AxiosResponse<IUserList>) => data);

export const createUser = (payloadUserRegisterDto: UserRegisterDto) =>
  axiosInstance.post<AxiosResponse<UserDto>>('/users', payloadUserRegisterDto);

export const deleteUser = (payload: PayloadDeleteUser) =>
  axiosInstance.delete(`/users/${payload.userId}/group/${payload.groupId}`);

export const fetchUserDetail = (userId: string, groupId: string) =>
  axiosInstance
    .get(`/users/${userId}/group/${groupId}`)
    .then(({ data }: AxiosResponse<UserRepresentationDto>) => data);

export const fetchCredentials = (userId: string) =>
  axiosInstance
    .get(`/users/${userId}/credentials`)
    .then(({ data }: AxiosResponse<UserCredentials[]>) => data);

export const deleteCredential = ({ userId, credentialId }: RemoveCredentials) =>
  axiosInstance.delete(`/users/${userId}/credentials/${credentialId}`);

export const updateUser = ({ id, payload }: UpdateUserParams) =>
  axiosInstance.put(`/users/${id}`, payload);

export const resetPasswordUser = ({ id, payload }: ResetPasswordUserParams) =>
  axiosInstance.put(`/users/reset-password/${id}`, payload);

export const validateEmail = (payload: UserValidEmailDto) =>
  axiosInstance
    .post('/users/validate-email', payload)
    .then(({ data }: AxiosResponse<UserValidEmailReponse>) => data);

export const joinGroupUser = (payload: UserJoinGroupDto) =>
  axiosInstance.post('/users/join-groups', payload);

export const listGroupMembers = (userId: string, params?: UserGroupQueryDto) =>
  axiosInstance
    .get(`/users/group-members/${userId}`, { params: { ...params } })
    .then(({ data }: AxiosResponse<UserGroupMemeberDto[]>) => data);

export const removeUserGroup = (payload: RemoveUserGroupParams) =>
  axiosInstance.delete(
    `/users/${payload.userId}/groups/${payload.groupId}/client/${payload.clientId}`
  );

export const fetchUserRoleMapping = (userId: string, clientId: string) =>
  axiosInstance
    .get(`/users/${userId}/clients/${clientId}/role-mappings`)
    .then(({ data }: AxiosResponse<RoleRepresentationDto[]>) => data);

export const fetchUserRoleAvailable = (userId: string, clientId: string) =>
  axiosInstance
    .get(`/users/${userId}/clients/${clientId}/role-available`)
    .then(({ data }: AxiosResponse<RoleRepresentationDto[]>) => data);

export const fetchUserRoleComposite = (userId: string, clientId: string) =>
  axiosInstance
    .get(`/users/${userId}/clients/${clientId}/role-composite`)
    .then(({ data }: AxiosResponse<RoleRepresentationDto[]>) => data);

export const addUserRoleMapping = (params: UserRoleMappingParams) =>
  axiosInstance.post<AxiosResponse<void>>(
    `/users/${params.userId}/clients/${params.clientId}/role-mappings`,
    params.payload
  );

export const updateUserRoleMapping = (params: UserRoleMappingParams) =>
  axiosInstance.put<AxiosResponse<void>>(
    `/users/${params.userId}/clients/${params.clientId}/role-mappings`,
    params.payload
  );

export const fetchUserSession = (userId: string) =>
  axiosInstance
    .get(`/users/${userId}/sessions`)
    .then(({ data }: AxiosResponse<UserSessions[]>) => data);

export const fetchUserIdentityProvider = (userId: string) =>
  axiosInstance
    .get(`/users/${userId}/federated-identities`)
    .then(({ data }: AxiosResponse<IFederatedIdentities[]>) => data);
