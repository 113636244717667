import { KeyFeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const selectFeature = (state: RootState) => state.permission.features;

export const selectPermission = (state: RootState) =>
  state.permission.application;

export const selectPermissionUser = (state: RootState) =>
  state.permission.application.user;

export const selectPermissionUserDetail = (state: RootState) =>
  state.permission.application.userDetail;

export const selectPermissionGroup = (state: RootState) =>
  state.permission.application.group;

export const selectPermissionGroupDetail = (state: RootState) =>
  state.permission.application.groupDetail;

export const selectPermissionCompany = (state: RootState) =>
  state.permission.application.company;

export const selectPermissionRole = (state: RootState) =>
  state.permission.application.role;

export const selectPermissionRoleDetail = (state: RootState) =>
  state.permission.application.roleDetail;

export const selectPermissionScope = (state: RootState) =>
  state.permission.application.scope;

export const selectPermissionScopeDetail = (state: RootState) =>
  state.permission.application.scopeDetail;

export const selectPermissionResource = (state: RootState) =>
  state.permission.application.resource;

export const selectPermissionResourceDetail = (state: RootState) =>
  state.permission.application.resourceDetail;

export const selectPermissionPolicy = (state: RootState) =>
  state.permission.application.policy;

export const selectPermissionPolicyDetail = (state: RootState) =>
  state.permission.application.policyDetail;

export const selectPermissionPage = (state: RootState) =>
  state.permission.application.permission;

export const selectPermissionDetail = (state: RootState) =>
  state.permission.application.permissionDetail;

export const selectPermissionEvent = (state: RootState) =>
  state.permission.application.event;

export const selectFeatureByApplication = createSelector(
  [selectFeature, (state, featureName: string) => featureName],
  (items, featureName) =>
    items.find(
      (item) => item.resourceName?.toLowerCase() === featureName?.toLowerCase()
    )
);

export const selectPermissionByApplication = createSelector(
  [selectPermission, (state, featureName: KeyFeaturePage) => featureName],
  (permission, featureName) => permission[featureName]
);
