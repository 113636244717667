import { IRoleRepresentation } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionRole,
  selectPermissionRoleDetail,
} from '@bom-nextgen-keycloak/web/core';
import {
  HeadCell,
  OrderTable,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  EnhancedTableHead,
  IconButtonDelete,
  NotFound,
  Pagination,
  StyledTextEllipsis,
  TableContainer,
  TableRowSkeleton,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  getComparator,
  stableSort,
} from '@bom-nextgen-keycloak/web/shared/util';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

type RoleTableProps = {
  data: IRoleRepresentation[];
  loading?: boolean;
  selectedPage: number;
  totalRecord: number;
  handleChange: (event: SelectChangeEvent<unknown>) => void;
  onClickDelate?: (roleName: string) => void;
};

const headCells: HeadCell<keyof IRoleRepresentation>[] = [
  {
    id: 'name',
    sortKey: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'description',
    sortKey: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'actions',
    sortKey: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

const RoleTable: FC<RoleTableProps> = ({
  data,
  onClickDelate,
  loading,
  selectedPage,
  handleChange,
  totalRecord = 0,
}) => {
  const [order, setOrder] = React.useState<OrderTable>('desc');
  const [orderBy, setOrderBy] =
    React.useState<keyof IRoleRepresentation>('name');

  const { canViewDelete } = useSelector(selectPermissionRole);
  const { canViewPage } = useSelector(selectPermissionRoleDetail);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IRoleRepresentation
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Paper>
      <TableContainer>
        <Table
          aria-label="role table"
          aria-labelledby="tableTitle"
          stickyHeader
        >
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            <TableRowSkeleton loading={loading} cellLength={headCells.length}>
              {data.length ? (
                stableSort<IRoleRepresentation>(
                  data,
                  getComparator(order, orderBy)
                ).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell title={row.name}>
                      <StyledTextEllipsis>{row.name}</StyledTextEllipsis>
                    </TableCell>
                    <TableCell title={row.description}>
                      <StyledTextEllipsis>{row.description}</StyledTextEllipsis>
                    </TableCell>
                    <TableCell>
                      {canViewPage && (
                        <Link to={`/roles/${row.id}`}>
                          <Tooltip title="Edit role">
                            <IconButtonDelete aria-label="edit">
                              <EditIcon fontSize="small" />
                            </IconButtonDelete>
                          </Tooltip>
                        </Link>
                      )}
                      {onClickDelate && canViewDelete && (
                        <Tooltip title="Remove role">
                          <IconButtonDelete
                            aria-label="delete"
                            onClick={() => onClickDelate(row.name)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButtonDelete>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <NotFound />
                  </TableCell>
                </TableRow>
              )}
            </TableRowSkeleton>
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        selectedPage={selectedPage}
        totalRecord={totalRecord}
        handleChange={handleChange}
      />
    </Paper>
  );
};

export { RoleTable };
