import { UserCredentials } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionUserDetail,
  useAlertMessage,
} from '@bom-nextgen-keycloak/web/core';
import {
  deleteCredential,
  ErrorMessage,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  DeleteDialog,
  IconButtonDelete,
  NotFound,
  TableRowSkeleton,
} from '@bom-nextgen-keycloak/web/shared/ui';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

type CredentialTableProps = {
  userId: string;
  isLoading: boolean;
  listCrednetials: UserCredentials[] | undefined;
};

const CredentialTable: FC<CredentialTableProps> = ({
  userId,
  isLoading,
  listCrednetials,
}) => {
  const [open, setOpen] = useState(false);
  const [activeCredential, setActiveCredential] = useState('');
  const { isEditPasswordTab } = useSelector(selectPermissionUserDetail);
  const { setAlertMessage } = useAlertMessage();
  const queryClient = useQueryClient();

  const deleteCredentialMutation = useMutation(deleteCredential, {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot delete user credential';
      setAlertMessage({
        message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
      setOpen(false);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY.USER_CREDENTIALS, userId]);
      setOpen(false);
    },
  });

  const handleClickDeletePassword = (credentialId: string) => {
    setActiveCredential(credentialId);
    setOpen(true);
  };

  const handleDeletePassword = () => {
    if (activeCredential) {
      deleteCredentialMutation.mutate({
        userId,
        credentialId: activeCredential,
      });
    }
  };

  return (
    <Box>
      <Paper>
        <TableContainer>
          <Table
            aria-label="Credentital table"
            aria-labelledby="tableTitle"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRowSkeleton
                loading={isLoading}
                skeletonLength={2}
                cellLength={2}
              >
                {listCrednetials && listCrednetials.length ? (
                  listCrednetials.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.type || '-'}</TableCell>
                      <TableCell>
                        <Tooltip title="Delete password">
                          <IconButtonDelete
                            aria-label="delete"
                            disabled={!isEditPasswordTab}
                            onClick={() => handleClickDeletePassword(row.id)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButtonDelete>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <NotFound />
                    </TableCell>
                  </TableRow>
                )}
              </TableRowSkeleton>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <DeleteDialog
        title="Delete password?"
        description={`You are about to permanently delete user credential. <b>This operation cannot be undone</b>.`}
        titleBtn="Delete"
        open={open}
        loading={deleteCredentialMutation.isLoading}
        onClose={() => setOpen(false)}
        onDelete={handleDeletePassword}
      />
    </Box>
  );
};

export { CredentialTable };
