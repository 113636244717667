import { IEventDto } from '@bom-nextgen-keycloak/models';
import {
  HeadCell,
  OrderTable,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  EnhancedTableHead,
  NotFound,
  Pagination,
  StyledTextEllipsis,
  TableContainer,
  TableRowSkeleton,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  getComparator,
  stableSort,
} from '@bom-nextgen-keycloak/web/shared/util';
import Paper from '@mui/material/Paper';
import { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns-tz';
import React, { FC } from 'react';

type LoginEventTableProps = {
  data: IEventDto[];
  loading?: boolean;
  selectedPage: number;
  totalRecord: number;
  handleChange: (event: SelectChangeEvent<unknown>) => void;
};

const headCells: HeadCell<keyof IEventDto>[] = [
  {
    id: 'time',
    sortKey: 'time',
    numeric: false,
    disablePadding: false,
    label: 'Time',
  },
  {
    id: 'username',
    sortKey: 'username',
    numeric: false,
    disablePadding: false,
    label: 'Logged in as',
  },
  {
    id: 'ipAddress',
    sortKey: 'ipAddress',
    numeric: false,
    disablePadding: false,
    label: 'IP address',
  },
  {
    id: 'error',
    sortKey: 'error',
    numeric: false,
    disablePadding: false,
    label: 'Error',
  },
];

const LoginEventTable: FC<LoginEventTableProps> = ({
  data,
  loading,
  selectedPage,
  handleChange,
  totalRecord = 0,
}) => {
  const [order, setOrder] = React.useState<OrderTable>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof IEventDto>('time');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IEventDto
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Paper>
      <TableContainer>
        <Table
          aria-label="login table"
          aria-labelledby="tableTitle"
          stickyHeader
        >
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            <TableRowSkeleton loading={loading} cellLength={headCells.length}>
              {data.length ? (
                stableSort<IEventDto>(data, getComparator(order, orderBy)).map(
                  (row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {format(new Date(row.time), 'MM/dd/yyyy HH:mm:ss', {
                          timeZone: 'Asia/Bangkok',
                        }) || '-'}
                      </TableCell>
                      <TableCell title={row.username}>
                        <StyledTextEllipsis>
                          {row.username || '-'}
                        </StyledTextEllipsis>
                      </TableCell>
                      <TableCell>{row.ipAddress || '-'}</TableCell>
                      <TableCell>{row.error || '-'}</TableCell>
                    </TableRow>
                  )
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <NotFound />
                  </TableCell>
                </TableRow>
              )}
            </TableRowSkeleton>
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        selectedPage={selectedPage}
        totalRecord={totalRecord}
        handleChange={handleChange}
        options={[
          { label: '1 - 200', value: 0 },
          { label: '201 - 400', value: 200 },
          { label: '401 - 600', value: 400 },
          { label: '601 - 800', value: 600 },
          { label: '801 - 1000', value: 800 },
          { label: '1001 - 1200', value: 1000 },
          { label: '1201 - 1400', value: 1200 },
          { label: '1401 - 1600', value: 1400 },
          { label: '1601 - 1800', value: 1600 },
          { label: '1801 - 2000', value: 1800 },
          { label: '2001 - 2200', value: 2000 },
          { label: '2201 - 2400', value: 2200 },
          { label: '2401 - 2600', value: 2400 },
          { label: '2601 - 2800', value: 2600 },
          { label: '2801 - 3000', value: 2800 },
          { label: '3001 - 3200', value: 3000 },
          { label: '3201 - 3400', value: 3200 },
          { label: '3401 - 3600', value: 3400 },
          { label: '3601 - 3800', value: 3600 },
          { label: '3801 - 4000', value: 3800 },
        ]}
      />
    </Paper>
  );
};

export { LoginEventTable };
