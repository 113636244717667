import { ButtonRounded } from '@bom-nextgen-keycloak/web/shared/ui';
import { styled } from '@mui/material/styles';

export const ActionWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled(ButtonRounded)`
  margin-right: 1rem;
`;
