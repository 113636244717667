import { MenuItem, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { FC, Fragment } from 'react';
import {
  ContainerPagination,
  ContainerPaginationToolBar,
  PaginationSpacer,
  StyleSelect,
} from './Pagination.styled';

export type OptionPagination = {
  label: string;
  value: number;
};

export type PaginationProps = {
  selectedPage?: number;
  totalRecord: number;
  options?: OptionPagination[];
  handleChange: (event: SelectChangeEvent<unknown>) => void;
};

export const defaultOption: OptionPagination[] = [
  { label: '1 - 100', value: 0 },
  { label: '101 - 200', value: 100 },
  { label: '201 - 300', value: 200 },
  { label: '301 - 400', value: 300 },
  { label: '401 - 500', value: 400 },
  { label: '501 - 600', value: 500 },
  { label: '601 - 700', value: 600 },
  { label: '701 - 800', value: 700 },
  { label: '801 - 900', value: 800 },
  { label: '901 - 1000', value: 900 },
];

const Pagination: FC<PaginationProps> = ({
  selectedPage = 0,
  totalRecord = 0,
  options = defaultOption,
  handleChange,
}) => {
  return (
    <ContainerPagination>
      <ContainerPaginationToolBar>
        <PaginationSpacer />
        {(totalRecord > 0 || selectedPage !== 0) && (
          <Fragment>
            <Typography variant="body2">Showing</Typography>
            <StyleSelect
              value={selectedPage}
              onChange={handleChange}
              labelId="select-pagination-label"
              id="select-pagination"
            >
              {options.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </StyleSelect>
            <Typography variant="body2">rows</Typography>
          </Fragment>
        )}
      </ContainerPaginationToolBar>
    </ContainerPagination>
  );
};

export { Pagination };
