import FormLabel from '@mui/material/FormLabel';
import { SwitchProps } from '@mui/material/Switch';
import { FieldHookConfig, useField } from 'formik';
import { FC } from 'react';
import { SwitchToggle } from '../SwitchToggle';
import { WrapperSwitchField } from './SwitchToggleField.styled';

export type SwitchToggleFieldProps = {
  label: string;
};

const SwitchToggleField: FC<
  SwitchProps & FieldHookConfig<string> & SwitchToggleFieldProps
> = (props) => {
  const [field] = useField(props);
  return (
    <WrapperSwitchField>
      <FormLabel component="legend">{props.label}</FormLabel>
      <SwitchToggle
        {...field}
        inputProps={props.inputProps}
        inputRef={props.inputRef}
        sx={props.sx}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        disabled={props.disabled}
        edge={props.edge}
        value={props.value}
      />
    </WrapperSwitchField>
  );
};

export { SwitchToggleField };
