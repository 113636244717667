import {
  selectPermissionGroupDetail,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  ButtonRounded,
  SwitchToggleField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import SaveIcon from '@mui/icons-material/Save';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import 'date-fns';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import * as yup from 'yup';
import { ActionWrapper, ColumnDatePicker } from './SubscriptionsForm.styled';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

const validationSchema = yup.lazy((values) => {
  if (values.unlimited) {
    return yup.object({
      startDate: yup.date().nullable().required('Start date is required'),
      endDate: yup.date().nullable(),
    });
  } else {
    return yup.object({
      startDate: yup.date().nullable().required('Start date is required'),
      endDate: yup.date().nullable().required('End date is required'),
    });
  }
});

type SubscriptionsAttributes = {
  startDate: Date | string | null;
  endDate: Date | string | null;
  unlimited: boolean;
  demo: boolean;
  trial: boolean;
  enabled: boolean;
};

type SubscriptionsFormProps = {
  loading: boolean;
  subscriptionName: string;
  attributes: Record<any, any>;
  onSubmit: (attributes: any) => void;
};

const mapArrayAttributes = (attributesName: any) => {
  if (attributesName) {
    return attributesName[0];
  } else {
    return 'false';
  }
};

const SubscriptionsForm: FC<SubscriptionsFormProps> = ({
  attributes,
  onSubmit,
  loading,
  subscriptionName,
}) => {
  const resource = useResource();
  const { isEditAttributesTab } = useSelector(selectPermissionGroupDetail);

  const initialValues = {
    startDate: attributes?.[`${resource.clientName}.startDate`] || null,
    endDate: attributes?.[`${resource.clientName}.endDate`] || null,
    unlimited:
      mapArrayAttributes(attributes?.[`${resource.clientName}.unlimited`]) ===
      'true'
        ? true
        : false,

    demo:
      mapArrayAttributes(attributes?.[`${resource.clientName}.demo`]) === 'true'
        ? true
        : false,

    trial:
      mapArrayAttributes(attributes?.[`${resource.clientName}.trial`]) ===
      'true'
        ? true
        : false,
    enabled:
      mapArrayAttributes(attributes?.[`${resource.clientName}.enabled`]) ===
      'true'
        ? true
        : false,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(
        payload,
        { setSubmitting }: FormikHelpers<SubscriptionsAttributes>
      ) => {
        const payloadSubmit = {
          [`${resource.clientName}.startDate`]: payload.startDate
            ? [payload.startDate?.toLocaleString()]
            : [''],
          [`${resource.clientName}.endDate`]: payload.endDate
            ? [payload.endDate?.toLocaleString()]
            : [''],
          [`${resource.clientName}.unlimited`]: [payload.unlimited.toString()],
          [`${resource.clientName}.demo`]: [payload.demo.toString()],
          [`${resource.clientName}.trial`]: [payload.trial.toString()],
          [`${resource.clientName}.enabled`]: [payload.enabled.toString()],
          [`${resource.clientName}.name`]: [subscriptionName],
        };
        onSubmit({ ...payloadSubmit });
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        isSubmitting,
        isValid,
        dirty,
        values,
        setFieldValue,
        getFieldMeta,
      }) => (
        <Form>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Start date"
              endText="End date"
              inputFormat="dd/MM/yyyy"
              value={[values.startDate, values.endDate]}
              onChange={(newValue) => {
                setFieldValue('startDate', newValue[0]);
                setFieldValue('endDate', newValue[1]);
              }}
              renderInput={(startProps, endProps) => (
                <ColumnDatePicker>
                  <TextField
                    {...startProps}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="startDate"
                    value={values.startDate}
                    disabled={!isEditAttributesTab}
                    error={!!getFieldMeta('startDate').error}
                    helperText={
                      getFieldMeta('startDate').error
                        ? getFieldMeta('startDate').error
                        : null
                    }
                    InputProps={{
                      readOnly: values.unlimited,
                    }}
                  />

                  <TextField
                    {...endProps}
                    variant="standard"
                    name="endDate"
                    value={values.startDate}
                    disabled={values.unlimited || !isEditAttributesTab}
                    error={!!getFieldMeta('endDate').error}
                    helperText={
                      getFieldMeta('endDate').error
                        ? getFieldMeta('endDate').error
                        : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ marginTop: '1.875rem' }}
                  />
                </ColumnDatePicker>
              )}
            />
          </LocalizationProvider>

          <Box sx={{ mb: 3, mt: 2 }}>
            <SwitchToggleField
              label="Unlimited"
              name="unlimited"
              disabled={!isEditAttributesTab}
              checked={values.unlimited}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <SwitchToggleField
              label="Demo"
              name="demo"
              disabled={!isEditAttributesTab}
              checked={values.demo}
            />
          </Box>

          <Box sx={{ mb: 3 }}>
            <SwitchToggleField
              label="Trial"
              name="trial"
              disabled={!isEditAttributesTab}
              checked={values.trial}
            />
          </Box>

          <Box sx={{ mb: 3 }}>
            <SwitchToggleField
              label="Enabled"
              name="enabled"
              disabled={!isEditAttributesTab}
              checked={values.enabled}
            />
          </Box>

          <ActionWrapper>
            <ButtonRounded
              type="submit"
              color="primary"
              variant="contained"
              loadingPosition="start"
              startIcon={<SaveIcon />}
              loading={loading}
              disabled={
                !(isValid && dirty) || isSubmitting || !isEditAttributesTab
              }
            >
              Save
            </ButtonRounded>
          </ActionWrapper>
        </Form>
      )}
    </Formik>
  );
};

export { SubscriptionsForm };
