import { selectPermissionResourceDetail } from '@bom-nextgen-keycloak/web/core';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  Attributes,
  PageLoading,
  ProtectedRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useResourceDetail } from '../../hooks/useResourceDetail';

const ResourceAttribute: FC = () => {
  const params = useParams();
  const resourceId = params.id || '';
  const { resourceDetail, handleSaveAttribute, isLoading, isLoadingSubmit } =
    useResourceDetail(resourceId);
  const { isEditAttributesTab } = useSelector(selectPermissionResourceDetail);

  return (
    <PageLoading isLoading={isLoading}>
      <Attributes
        loading={isLoadingSubmit}
        attributesDetail={resourceDetail?.attributes || {}}
        onSave={handleSaveAttribute}
        isDisabled={!isEditAttributesTab}
      />
    </PageLoading>
  );
};

const WrappedResourceAttributePage: FC = () => {
  const { canViewPage, canViewAttributesTab } = useSelector(
    selectPermissionResourceDetail
  );
  return (
    <ProtectedRouter
      feature={FeaturePage.RESOURCE}
      isEditPage
      canView={canViewPage && canViewAttributesTab}
      navigatePath="/resources"
    >
      <ResourceAttribute />
    </ProtectedRouter>
  );
};

export { WrappedResourceAttributePage };
