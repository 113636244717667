/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { Component, ErrorInfo } from 'react';
import { Link } from 'react-router-dom';

class ErrorBoundary extends Component {
  public state = {
    hasError: false,
  };

  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('ErrorBoundary caught an error', error, info);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: 'center', marginTop: 100 }}>
          <h3>Something went wrong!</h3>

          <h4>
            <Link to="/" onClick={() => this.setState({ hasError: false })}>
              Click here
            </Link>{' '}
            to back to the home page.
          </h4>
        </div>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
