import { ButtonRounded } from '@bom-nextgen-keycloak/web/shared/ui';
import { useKeycloak } from '@react-keycloak/web';
import React, { FC, useCallback } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { LoginBox, LoginWrapper, TrueIcon } from './Login.styled';

const Login: FC = () => {
  const location = useLocation();
  const currentLocationState = location.state || {
    from: { pathname: '/dashboard' },
  };

  const { keycloak } = useKeycloak();

  const handleLogin = useCallback(() => {
    keycloak?.login();
  }, [keycloak]);

  if (keycloak?.authenticated) {
    return <Navigate to={currentLocationState?.from as string} />;
  }

  return (
    <LoginWrapper>
      <LoginBox>
        <TrueIcon />
        <ButtonRounded
          variant="contained"
          color="primary"
          onClick={handleLogin}
        >
          Login
        </ButtonRounded>
      </LoginBox>
    </LoginWrapper>
  );
};

export { Login };
