import { LoggingDto } from '@bom-nextgen-keycloak/models';
import {
  HeadCell,
  OrderTable,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  EnhancedTableHead,
  IconButtonDelete,
  NotFound,
  Pagination,
  StyledTextEllipsis,
  TableContainer,
  TableRowSkeleton,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  getComparator,
  stableSort,
} from '@bom-nextgen-keycloak/web/shared/util';
import InfoIcon from '@mui/icons-material/Info';
import ReportIcon from '@mui/icons-material/Report';
import Paper from '@mui/material/Paper';
import { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { FC, useState } from 'react';
import { format } from 'date-fns-tz';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import PageviewIcon from '@mui/icons-material/Pageview';

type PermissionTableProps = {
  data: LoggingDto[];
  loading?: boolean;
  selectedPage: number;
  totalRecord: number;
  handleChange: (event: SelectChangeEvent<unknown>) => void;
};

const headCells: HeadCell<keyof LoggingDto>[] = [
  {
    id: 'status',
    sortKey: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Severity',
  },
  {
    id: 'createdAt',
    sortKey: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Time',
  },
  {
    id: 'username',
    sortKey: 'username',
    numeric: false,
    disablePadding: false,
    label: 'Username',
  },
  {
    id: 'email',
    sortKey: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'type',
    sortKey: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'summary',
    sortKey: 'summary',
    numeric: false,
    disablePadding: false,
    label: 'Summary',
  },
  {
    id: 'id',
    sortKey: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

const PermissionEventTable: FC<PermissionTableProps> = ({
  data,
  loading,
  selectedPage,
  handleChange,
  totalRecord = 0,
}) => {
  const [order, setOrder] = useState<OrderTable>('desc');
  const [orderBy, setOrderBy] = useState<keyof LoggingDto>('createdAt');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof LoggingDto
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  return (
    <Paper>
      <TableContainer>
        <Table
          aria-label="permission table"
          aria-labelledby="tableTitle"
          stickyHeader
        >
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            <TableRowSkeleton loading={loading} cellLength={headCells.length}>
              {data.length ? (
                stableSort<LoggingDto>(data, getComparator(order, orderBy)).map(
                  (row, index) => (
                    <TableRow key={index}>
                      <TableCell title={capitalizeFirstLetter(row.status)}>
                        {row.status === 'success' ? (
                          <InfoIcon color="primary" />
                        ) : (
                          <ReportIcon color="error" />
                        )}
                      </TableCell>
                      <TableCell>
                        {format(
                          new Date(row.createdAt),
                          'MM/dd/yyyy HH:mm:ss',
                          { timeZone: 'Asia/Bangkok' }
                        ) || '-'}
                      </TableCell>
                      <TableCell title={row.username}>
                        {row.username || '-'}
                      </TableCell>
                      <TableCell title={row.email}>
                        {row.email || '-'}
                      </TableCell>
                      <TableCell>
                        <p style={{ textTransform: 'capitalize' }}>
                          {row.type || '-'}
                        </p>
                      </TableCell>
                      <TableCell title={row.summary}>
                        <StyledTextEllipsis>
                          {row.summary || '-'}
                        </StyledTextEllipsis>
                      </TableCell>
                      <TableCell title={row.summary}>
                        <Link to={`/events/permission/${row.id}`}>
                          <Tooltip title="View event details">
                            <IconButtonDelete aria-label="edit">
                              <PageviewIcon fontSize="small" />
                            </IconButtonDelete>
                          </Tooltip>
                        </Link>
                      </TableCell>
                    </TableRow>
                  )
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <NotFound />
                  </TableCell>
                </TableRow>
              )}
            </TableRowSkeleton>
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        selectedPage={selectedPage}
        totalRecord={totalRecord}
        handleChange={handleChange}
        options={[
          { label: '1 - 200', value: 0 },
          { label: '201 - 400', value: 200 },
          { label: '401 - 600', value: 400 },
          { label: '601 - 800', value: 600 },
          { label: '801 - 1000', value: 800 },
          { label: '1001 - 1200', value: 1000 },
          { label: '1201 - 1400', value: 1200 },
          { label: '1401 - 1600', value: 1400 },
          { label: '1601 - 1800', value: 1600 },
          { label: '1801 - 2000', value: 1800 },
          { label: '2001 - 2200', value: 2000 },
          { label: '2201 - 2400', value: 2200 },
          { label: '2401 - 2600', value: 2400 },
          { label: '2601 - 2800', value: 2600 },
          { label: '2801 - 3000', value: 2800 },
          { label: '3001 - 3200', value: 3000 },
          { label: '3201 - 3400', value: 3200 },
          { label: '3401 - 3600', value: 3400 },
          { label: '3601 - 3800', value: 3600 },
          { label: '3801 - 4000', value: 3800 },
        ]}
      />
    </Paper>
  );
};

export { PermissionEventTable };
