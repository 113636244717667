import { GroupDto } from '@bom-nextgen-keycloak/models';
import {
  CloseSquare,
  CustomTreeItem,
  MinusSquare,
  PlusSquare,
} from '@bom-nextgen-keycloak/web/shared/ui';
import TreeView from '@mui/lab/TreeView';
import { FC, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

type TreeViewUserGroupProps = {
  groupDetail: GroupDto;
  memberIds: string[];
  isDisabled?: boolean;
  onNodeSelected: (node: GroupDto | null) => void;
};

const findGroupByNodeId = (data: GroupDto | undefined, nodeId: string): any => {
  if (data?.id === nodeId) {
    return data;
  }
  if (data?.subGroups && data?.subGroups.length) {
    for (const child of data?.subGroups) {
      const res = findGroupByNodeId(child, nodeId);
      if (res) {
        return res;
      }
    }
  }
};

function flatten(groupDto: GroupDto[]) {
  const flat: GroupDto[] = [];
  groupDto.forEach((item) => {
    flat.push(item);
    if (Array.isArray(item.subGroups) && item.subGroups.length > 0) {
      flat.push(...flatten(item.subGroups));
      delete item.subGroups;
    }
    delete item.subGroups;
  });
  return flat;
}

const TreeViewUserGroup: FC<TreeViewUserGroupProps> = ({
  groupDetail,
  onNodeSelected,
  memberIds,
  isDisabled = false,
}) => {
  const [expanded, setExpanded] = useState<string[]>([]);

  const expandedIds = useMemo(() => {
    const newArrayGroup = JSON.parse(JSON.stringify(groupDetail?.subGroups));
    return flatten(newArrayGroup).map((val) => val.id || '');
  }, [groupDetail?.subGroups]);

  useEffect(() => {
    setExpanded([groupDetail.id || '', ...expandedIds]);
  }, [expandedIds, groupDetail]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
    onNodeSelected(null);
  };

  const handleSelect = (event: React.SyntheticEvent, nodeIds: string) => {
    const findGroupById = findGroupByNodeId(groupDetail, nodeIds);

    if (findGroupById) {
      onNodeSelected(findGroupById);
    } else {
      onNodeSelected(null);
    }
  };

  const renderTree = (nodes: GroupDto) => {
    return (
      <CustomTreeItem
        key={nodes.id}
        nodeId={nodes.id || uuidv4()}
        label={nodes.name}
        disabled={isDisabled}
        classes={{
          label: memberIds.includes(nodes?.id || '') ? 'activeLabel' : 'label',
        }}
      >
        {Array.isArray(nodes.subGroups)
          ? nodes.subGroups.map((node) => renderTree(node))
          : null}
      </CustomTreeItem>
    );
  };

  return (
    <TreeView
      expanded={expanded}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
    >
      {renderTree(groupDetail)}
    </TreeView>
  );
};

export { TreeViewUserGroup };
