import { CircularProgress, TextField } from '@mui/material';
import Autocomplete, {
  AutocompleteInputChangeReason,
} from '@mui/material/Autocomplete';
import { FieldHookConfig, useField } from 'formik';
import { FC, Fragment } from 'react';

export type AutoCompleteOption = {
  label: string;
  value: any;
};

type AutocompleteFieldProps = {
  label: string;
  options: AutoCompleteOption[];
  multiple?: boolean;
  loading?: boolean;
  limitTags?: number;
  getOptionSelected?: (option: any, value: any) => boolean;
  onChangeAutoComplete: (data: AutoCompleteOption) => void;

  onInputChange?: (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
};

const AutoCompleteField: FC<
  AutocompleteFieldProps & FieldHookConfig<string>
> = (props) => {
  const [field, meta] = useField(props);

  const handleChange = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    value: AutoCompleteOption
  ) => {
    props.onChangeAutoComplete(value);
  };

  return (
    <Autocomplete
      {...field}
      id={props.name}
      value={props.value}
      options={props.options}
      multiple={props.multiple}
      limitTags={props.limitTags}
      getOptionLabel={(option) => option.label}
      onChange={handleChange}
      isOptionEqualToValue={props.getOptionSelected}
      onInputChange={props.onInputChange}
      loading={props.loading}
      renderInput={(params) => (
        <TextField
          {...params}
          id={props.name}
          label={props.label}
          data-testid="form.text-autocomplete-field"
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          placeholder={props.placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {props.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export { AutoCompleteField };
