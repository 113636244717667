import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';

export const StyledAppBar = styled(AppBar)`
  margin-top: 1rem;
`;

export const StyledLoading = styled('div')`
  display: flex;
  justify-content: center;
`;
