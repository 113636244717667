import { ResourceDto } from '@bom-nextgen-keycloak/models';
import {
  EventPage,
  FeaturePage,
  PermissionEventPage,
  ScopeApplication,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { getValueAttributeKeycloak } from '@bom-nextgen-keycloak/web/shared/util';
import { matchPermission, ResourceType } from '../reducer/permission.reducer';

const getComponentEvent = (resources: ResourceDto[]): ResourceType[] => {
  return resources
    .filter(
      (val) =>
        val.type === 'component' &&
        getValueAttributeKeycloak(val.attributes, 'feature').toLowerCase() ===
          FeaturePage.EVENT.toLowerCase()
    )
    .map((val) => ({
      ...val,
      scopes: val.scopes?.map((scope) => scope.name) || [],
    }));
};

export const mapEventPermission = (
  resources: ResourceDto[]
): PermissionEventPage => {
  const eventComponentList: ResourceType[] = getComponentEvent(resources);

  return {
    canViewLogin: matchPermission(
      eventComponentList,
      EventPage.EVENT_LOGIN,
      ScopeApplication.VIEW
    ),
    canViewPermission: matchPermission(
      eventComponentList,
      EventPage.EVENT_PERMISSION,
      ScopeApplication.VIEW
    ),
  };
};
