import axios, { AxiosRequestConfig } from 'axios';
import { keycloak } from './keycloak';
import { v4 as uuidv4 } from 'uuid';

const pathAdminEndpoint =
  (window as any).NX_API_ENDPOINT_BASE_URL ||
  process.env.NX_API_ENDPOINT_BASE_URL;

const pathGateWayEndpoint =
  (window as any).NX_API_ENDPOINT_GATEWAY_URL ||
  process.env.NX_API_ENDPOINT_GATEWAY_URL;

export const axiosInstance = axios.create({
  baseURL: pathAdminEndpoint + '/api/v1',
  timeout: 30000,
});

export const gatewayAxiosInstance = axios.create({
  baseURL: pathGateWayEndpoint + '/api/v1',
  timeout: 30000,
});

axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const accessToken = keycloak.token;
  if (accessToken) {
    config.headers.transactionId = uuidv4();
    config.headers.authorization = `Bearer ${accessToken}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      keycloak?.logout();
    }
    throw error;
  }
);

gatewayAxiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const accessToken = keycloak.token;
    if (accessToken) {
      config.headers.transactionId = uuidv4();
      config.headers.authorization = `Bearer ${accessToken}`;
    }
    return config;
  }
);

gatewayAxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      keycloak?.logout();
    }
    throw error;
  }
);
