import React, { FC } from 'react';
import { CustomSearchIcon, Title, Wrapper } from './NotFound.styled';

export type NotFoundProp = {
  title?: string;
};

const NotFound: FC<NotFoundProp> = ({ title = 'No results found' }) => {
  return (
    <Wrapper>
      <CustomSearchIcon />
      <Title>{title}</Title>
    </Wrapper>
  );
};

export { NotFound };
