import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)`
  & div.MuiDialog-paper {
    width: 30rem;
    max-width: 30rem;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  * {
    margin-bottom: 0.5rem;
  }
`;
