import { AuthenticatedAppContainer } from '@bom-nextgen-keycloak/web/pages/feature-authenticated';
import {
  WrappedEventLoginPage as LoginEvent,
  WrappedEventPermissionDetailPage,
  WrappedEventPermissionPage as PermissionEvent,
} from '@bom-nextgen-keycloak/web/pages/feature-events';
import {
  WrappedGroupAttributePage as GroupAttribute,
  WrappedGroupDetailPage as GroupDetailFrom,
  WrappedGroupMemeberPage as GroupMemeber,
  WrappedGroupRoleMappingPage as GroupRoleMapping,
} from '@bom-nextgen-keycloak/web/pages/feature-group-detail';
import { Login } from '@bom-nextgen-keycloak/web/pages/feature-login';
import { PageNotFound } from '@bom-nextgen-keycloak/web/pages/feature-page-not-found';
import {
  WrappedResourceAttributePage as ResourceAttribute,
  WrappedResourceDetailFormPage as ResourceDetailForm,
} from '@bom-nextgen-keycloak/web/pages/feature-resource-detail';
import {
  WrappedRoleAttributePage as RoleAttribute,
  WrappedRoleDetailFormPage as RoleDetailForm,
  WrappedUserInRolePage as UserInRole,
} from '@bom-nextgen-keycloak/web/pages/feature-role-detail';
import { Unauthorized } from '@bom-nextgen-keycloak/web/pages/feature-unauthorized';
import {
  UserAttribute,
  UserDetailForm,
  WrappedCredentialPage,
  WrappedGroupPage as UserGroup,
  WrappedIdentityProviderPage,
  WrappedRoleMappingPage,
  WrappedSessionPage,
} from '@bom-nextgen-keycloak/web/pages/feature-user-detail';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import { ProtectedRouter } from '@bom-nextgen-keycloak/web/shared/ui';
import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import {
  Company,
  Event,
  Group,
  GroupDetail,
  Home,
  Permission,
  PermissionDetail,
  Policy,
  PolicyDetail,
  privateRoute,
  Resource,
  ResourceDetail,
  Role,
  RoleDetail,
  Scope,
  ScopeDetail,
  User,
  UserDetail,
} from './routes';

const Router: FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<AuthenticatedAppContainer privateRoute={privateRoute} />}
      >
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/dashboard" element={<Home />} />
        <Route
          path="/companies"
          element={
            <ProtectedRouter feature={FeaturePage.COMPANY}>
              <Company />
            </ProtectedRouter>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRouter feature={FeaturePage.USER}>
              <User />
            </ProtectedRouter>
          }
        />
        <Route path="/users/:id" element={<UserDetail />}>
          <Route index element={<UserDetailForm />} />
          <Route path="attributes" element={<UserAttribute />} />
          <Route path="credentials" element={<WrappedCredentialPage />} />
          <Route path="groups" element={<UserGroup />} />
          <Route path="role-mappings" element={<WrappedRoleMappingPage />} />
          <Route path="sessions" element={<WrappedSessionPage />} />
          <Route
            path="identity-provider-links"
            element={<WrappedIdentityProviderPage />}
          />
        </Route>
        <Route
          path="/groups"
          element={
            <ProtectedRouter feature={FeaturePage.GROUP}>
              <Group />
            </ProtectedRouter>
          }
        />
        <Route path="/groups/:id" element={<GroupDetail />}>
          <Route index element={<GroupDetailFrom />} />
          <Route path="attributes" element={<GroupAttribute />} />
          <Route path="members" element={<GroupMemeber />} />
          <Route path="role-mappings" element={<GroupRoleMapping />} />
        </Route>
        <Route
          path="/roles"
          element={
            <ProtectedRouter feature={FeaturePage.ROLE}>
              <Role />
            </ProtectedRouter>
          }
        />
        <Route path="/roles/:id" element={<RoleDetail />}>
          <Route index element={<RoleDetailForm />} />
          <Route path="attributes" element={<RoleAttribute />} />
          <Route path="users" element={<UserInRole />} />
        </Route>
        <Route
          path="/scopes"
          element={
            <ProtectedRouter feature={FeaturePage.SCOPE}>
              <Scope />
            </ProtectedRouter>
          }
        />
        <Route path="/scopes/:id" element={<ScopeDetail />} />
        <Route
          path="/resources"
          element={
            <ProtectedRouter feature={FeaturePage.RESOURCE}>
              <Resource />
            </ProtectedRouter>
          }
        />
        <Route path="/resources/:id" element={<ResourceDetail />}>
          <Route index element={<ResourceDetailForm />} />
          <Route path="attributes" element={<ResourceAttribute />} />
        </Route>
        <Route
          path="/policies"
          element={
            <ProtectedRouter feature={FeaturePage.POLICY}>
              <Policy />
            </ProtectedRouter>
          }
        />
        <Route path="/policies/:id/:type" element={<PolicyDetail />} />

        <Route
          path="/permissions"
          element={
            <ProtectedRouter feature={FeaturePage.PERMISSION}>
              <Permission />
            </ProtectedRouter>
          }
        />
        <Route path="/permissions/:id/:type" element={<PermissionDetail />} />

        <Route
          path="/events"
          element={
            <ProtectedRouter feature={FeaturePage.EVENT}>
              <Event />
            </ProtectedRouter>
          }
        >
          <Route path="permission" element={<PermissionEvent />} />
          <Route path="login" element={<LoginEvent />} />
          <Route index element={<Navigate to="/events/permission" />} />
        </Route>

        <Route
          path="/events/permission/:id"
          element={<WrappedEventPermissionDetailPage />}
        />

        <Route path="*" element={<PageNotFound />} />
      </Route>
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
};

export default Router;
