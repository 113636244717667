import {
  GroupDto,
  GroupListMemberQueryDto,
  GroupRegisterDto,
  GroupSearchQuery,
  GroupUpdateDto,
  IUserList,
  RoleRepresentationDto,
  GroupClientRoleMappings,
} from '@bom-nextgen-keycloak/models';
import { axiosInstance } from '@bom-nextgen-keycloak/web/shared/util';
import { AxiosResponse } from 'axios';

export type CreateChildGroupParams = {
  id: string;
  payload: GroupRegisterDto;
};

export type UpdateGroupParams = {
  id: string;
  payload: GroupUpdateDto;
};

export type GroupRoleMappingParams = {
  groupId: string;
  clientId: string;
  payload: GroupClientRoleMappings;
};

export const fetchGroupById = (groupId: string, params?: GroupSearchQuery) =>
  axiosInstance
    .get(`/groups/${groupId}`, { params: { ...params } })
    .then(({ data }: AxiosResponse<GroupDto>) => data);

export const createGroup = (payloadGroupRegisterDto: GroupRegisterDto) =>
  axiosInstance.post<AxiosResponse<GroupDto>>(
    '/groups',
    payloadGroupRegisterDto
  );

export const createChildGroup = ({ id, payload }: CreateChildGroupParams) =>
  axiosInstance.post<AxiosResponse<GroupDto>>(
    `/groups/create-child/${id}`,
    payload
  );

export const deleteGroup = (groupId: string) =>
  axiosInstance.delete(`/groups/${groupId}`);

export const updateGroup = ({ id, payload }: UpdateGroupParams) =>
  axiosInstance.put(`/groups/${id}`, payload);

export const fetchgroupListMembers = (
  groupId: string,
  params: GroupListMemberQueryDto
) =>
  axiosInstance
    .get(`/groups/${groupId}/list-members`, { params: { ...params } })
    .then(({ data }: AxiosResponse<IUserList>) => data);

export const fetchGroupRoleMapping = (groupId: string, clientId: string) =>
  axiosInstance
    .get(`/groups/${groupId}/clients/${clientId}/role-mappings`)
    .then(({ data }: AxiosResponse<RoleRepresentationDto[]>) => data);

export const fetchGroupRoleAvailable = (groupId: string, clientId: string) =>
  axiosInstance
    .get(`/groups/${groupId}/clients/${clientId}/role-available`)
    .then(({ data }: AxiosResponse<RoleRepresentationDto[]>) => data);

export const fetchGroupRoleComposite = (groupId: string, clientId: string) =>
  axiosInstance
    .get(`/groups/${groupId}/clients/${clientId}/role-composite`)
    .then(({ data }: AxiosResponse<RoleRepresentationDto[]>) => data);

export const addGroupRoleMapping = (params: GroupRoleMappingParams) =>
  axiosInstance.post<AxiosResponse<void>>(
    `/groups/${params.groupId}/clients/${params.clientId}/role-mappings`,
    params.payload
  );

export const updateGroupRoleMapping = (params: GroupRoleMappingParams) =>
  axiosInstance.put<AxiosResponse<void>>(
    `/groups/${params.groupId}/clients/${params.clientId}/role-mappings`,
    params.payload
  );
