import { useAlertMessage, useResource } from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  validateEmail,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  ButtonRounded,
  StyledDialogContent,
  StyledDialogTitle,
  TextField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  EMAIL_REGEX,
  ENGLISH_REGEX,
} from '@bom-nextgen-keycloak/web/shared/util';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { BoxActionValidate, StyledDialog } from './UserEmailDialog.styled';

type UserEmailDialogProps = {
  open: boolean;
  onClose: (email: string) => void;
  onValidEmailAndGroup: (userId: string) => void;
};

const validationEmailSchema = yup.object({
  email: yup
    .string()
    .email('Invalid email address')
    .matches(EMAIL_REGEX, 'Invalid email address')
    .required('Email is required'),
});

type FormEmail = {
  email: string;
};

const initialValues: FormEmail = {
  email: '',
};

const UserEmailDialog: FC<UserEmailDialogProps> = ({
  onClose,
  onValidEmailAndGroup,
  open,
}) => {
  const { setAlertMessage } = useAlertMessage();
  const resource = useResource();

  const validateEmailMutation = useMutation(validateEmail, {
    onSuccess: (data, payload) => {
      const { isValidEmail, isValidGroup, userId } = data;
      const { email } = payload;
      const alertMessage = `User with email ${email} and ID ${userId} already exists`;

      if (isValidEmail && !isValidGroup) {
        onValidEmailAndGroup(userId);
      } else if (isValidGroup && isValidEmail) {
        setAlertMessage({
          message: `${alertMessage} and is a member of this product`,
          typeStatusMessage: 'error',
        });
      } else if (isValidEmail) {
        setAlertMessage({
          message: alertMessage,
          typeStatusMessage: 'error',
        });
      } else {
        onClose(email);
      }
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot validate email and/or group';
      setAlertMessage({
        message:
          error.response?.status === 422 ? 'Invalid email address' : message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  return (
    <StyledDialog
      onClose={() => onClose('')}
      aria-labelledby="dialog-title"
      open={open}
    >
      <StyledDialogTitle id="dialog-title">
        Check email availability
      </StyledDialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={(
          values: FormEmail,
          { setSubmitting }: FormikHelpers<FormEmail>
        ) => {
          validateEmailMutation.mutate({
            groupId: resource.groupId,
            email: values.email.toLowerCase(),
          });
          setSubmitting(false);
        }}
        validationSchema={validationEmailSchema}
        enableReinitialize
      >
        {({ isSubmitting, isValid, dirty, setFieldValue }) => (
          <Form>
            <StyledDialogContent>
              <TextField
                name="email"
                label="Email"
                placeholder="Enter valid email address"
                type="email"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let value = e.target.value;
                  value = value.replace(ENGLISH_REGEX, '');
                  setFieldValue('email', value);
                }}
                required
              />
            </StyledDialogContent>
            <BoxActionValidate>
              <ButtonRounded
                sx={{ width: 150 }}
                type="submit"
                color="primary"
                variant="contained"
                loading={validateEmailMutation.isLoading}
                disabled={!(isValid && dirty) || isSubmitting}
                loadingIndicator="Checking..."
              >
                Submit
              </ButtonRounded>
            </BoxActionValidate>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
};

export { UserEmailDialog };
