import { selectPermissionEvent } from '@bom-nextgen-keycloak/web/core';
import { useTabsWithRouter } from '@bom-nextgen-keycloak/web/shared/ui';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import { EventWrapper } from './Event.styled';

const Event: FC = () => {
  const { canViewLogin, canViewPermission } = useSelector(
    selectPermissionEvent
  );

  const tabValue = useTabsWithRouter(
    [`/events/permission`, `/events/login`],
    `/events/permission`
  );

  return (
    <EventWrapper>
      <AppBar position="static">
        <Tabs
          value={tabValue}
          aria-label="simple tabs exampl-e"
          textColor="inherit"
          variant="fullWidth"
          indicatorColor="secondary"
        >
          {canViewPermission && (
            <Tab
              label="Permission Events"
              component={Link}
              value={`/events/permission`}
              to={`/events/permission`}
            />
          )}

          {canViewLogin && (
            <Tab
              label="Login Events"
              component={Link}
              value={`/events/login`}
              to={`/events/login`}
            />
          )}
        </Tabs>
      </AppBar>

      <Box sx={{ mt: 3, width: '100%' }}>
        <Outlet />
      </Box>
    </EventWrapper>
  );
};

export { Event };
