import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

export type BreadcrumbsLinkProps = {
  name: string;
  pathName: string;
  detailName?: string;
};

const BreadcrumbsLink: FC<BreadcrumbsLinkProps> = ({
  name,
  pathName,
  detailName = 'Detail',
}) => {
  return (
    <Breadcrumbs>
      <Link color="inherit" component={RouterLink} to={pathName}>
        {name}
      </Link>
      <Typography color="textPrimary">{detailName}</Typography>
    </Breadcrumbs>
  );
};

export { BreadcrumbsLink };
