import { selectPermissionGroupDetail } from '@bom-nextgen-keycloak/web/core';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  Attributes,
  PageLoading,
  ProtectedRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useGroupDetail } from '../../hooks/useGroupDetail';
import { IndustryForm } from '../IndustryForm/IndustryForm';
import { SubscriptionsForm } from '../SubscriptionsForm/SubscriptionsForm';

const GroupAttribute: FC = () => {
  const params = useParams();
  const groupId = params?.id || '';
  const {
    groupDetail,
    isLoading,
    isLoadingSubmit,
    handleSaveAttribute,
    isSubscription,
  } = useGroupDetail(groupId);
  const { isEditAttributesTab } = useSelector(selectPermissionGroupDetail);
  const renderAttributes = () => {
    if (isSubscription) {
      return (
        <SubscriptionsForm
          loading={isLoadingSubmit}
          subscriptionName={groupDetail?.name || ''}
          attributes={groupDetail?.attributes || {}}
          onSubmit={handleSaveAttribute}
        />
      );
    } else if (groupDetail?.level === 2) {
      return (
        <IndustryForm
          loading={isLoadingSubmit}
          attributes={groupDetail?.attributes || {}}
          onSubmit={handleSaveAttribute}
        />
      );
    } else {
      return (
        <Attributes
          loading={isLoadingSubmit}
          attributesDetail={groupDetail?.attributes || {}}
          onSave={handleSaveAttribute}
          isDisabled={!isEditAttributesTab}
        />
      );
    }
  };

  return <PageLoading isLoading={isLoading}>{renderAttributes()}</PageLoading>;
};

const WrappedGroupAttributePage: FC = () => {
  const { canViewPage, canViewAttributesTab } = useSelector(
    selectPermissionGroupDetail
  );
  return (
    <ProtectedRouter
      isEditPage
      feature={FeaturePage.GROUP}
      canView={canViewPage && canViewAttributesTab}
      navigatePath="/groups"
    >
      <GroupAttribute />
    </ProtectedRouter>
  );
};

export { WrappedGroupAttributePage };
