import { useMatch } from 'react-router-dom';

export const useTabsWithRouter = (
  routes: string | string[],
  defaultRoute: string
): string => {
  const match =
    typeof routes === 'string'
      ? useMatch(routes)
      : routes.map((val) => useMatch(val)).find((val) => val !== null);

  return match?.pathname ?? defaultRoute;
};
