import { UserUpdateDto } from '@bom-nextgen-keycloak/models';
import { useAlertMessage, useResource } from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchUserDetail,
  QUERY_KEY,
  updateUser,
  UpdateUserParams,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';

export const useUserDetail = (userId: string) => {
  const { setAlertMessage } = useAlertMessage();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const resource = useResource();

  const { isLoading, data: userDetail } = useQuery(
    [QUERY_KEY.USER_DETAIL, userId],
    () => fetchUserDetail(userId, resource.groupId),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message = error.response?.data.message || 'Cannot get userDetail';
        setAlertMessage({
          message,
          typeStatusMessage: 'error',
          statusCode: error.response?.status,
        });
        navigate('/users');
      },
    }
  );

  const updateUserMutation = useMutation(updateUser, {
    onSuccess: (data) => {
      setAlertMessage({
        message: 'User has been updated',
        typeStatusMessage: 'success',
      });
      queryClient.invalidateQueries([QUERY_KEY.USER_DETAIL, userId]);
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot update user';
      setAlertMessage({
        message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const handleUpdateUser = (payloadUser: UserUpdateDto | undefined) => {
    if (payloadUser) {
      const payload: UpdateUserParams = {
        id: userId,
        payload: { ...payloadUser, attributes: userDetail?.attributes },
      };
      updateUserMutation.mutate(payload);
    }
  };

  const handleSaveAttribute = (attributes: any) => {
    if (userDetail) {
      const payloadUser: UserUpdateDto = {
        ...userDetail,
        groupId: resource.groupId,
        username: userDetail.username || '',
        email: userDetail.email || '',
        enabled: userDetail.enabled || true,
        enabledSSO: userDetail.enabledSSO || true,
        firstName: userDetail.firstName || '',
        lastName: userDetail.lastName || '',
        attributes: { ...attributes },
      };
      const payload: UpdateUserParams = {
        id: userId,
        payload: payloadUser,
      };
      updateUserMutation.mutate(payload);
    }
  };

  return {
    isLoading,
    isLoadingSubmit: updateUserMutation.isLoading,
    userDetail,
    handleUpdateUser,
    handleSaveAttribute,
  };
};
