import { UserAuth } from '@bom-nextgen-keycloak/models';
import React, { createContext, FC, useState } from 'react';

type UserContextProps = {
  user: UserAuth | null;
  setUser: (user: UserAuth | null) => void;
};

export const UserContext = createContext<UserContextProps>({
  user: null,
  setUser: () => undefined,
});

export const UserContextProvider: FC = ({ children }) => {
  const [user, setUser] = useState<UserAuth | null>(null);
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
