import {
  RootState,
  selectFeatureByApplication,
} from '@bom-nextgen-keycloak/web/core';
import {
  FeaturePage,
  ScopeApplication,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';

type ProtectedRouterProps = {
  feature: FeaturePage;
  canView?: boolean;
  isEditPage?: boolean;
  navigatePath?: string;
  children: JSX.Element;
};

const compareScopes = (
  scopes: (string | undefined)[] | undefined,
  scope: ScopeApplication
) => {
  if (scopes) {
    return scopes.includes(scope) || false;
  }
  return false;
};

const ProtectedRouter: FC<ProtectedRouterProps> = ({
  children,
  feature,
  navigatePath = '/',
  isEditPage = false,
  canView = false,
}) => {
  const features = useSelector((state: RootState) =>
    selectFeatureByApplication(state, feature)
  );

  const featureScope = features?.scopes?.map((scope) => scope.name);
  const isView = compareScopes(featureScope, ScopeApplication.VIEW);

  if (!isView) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else if (isEditPage && !canView) {
    return <Navigate to={navigatePath} replace />;
  }

  return children;
};

export { ProtectedRouter };
