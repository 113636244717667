import {
  IResourceClientList,
  ResourceClient,
  ResourceClientQuery,
  ResourceClientRegister,
  ResourceClientUpdate,
} from '@bom-nextgen-keycloak/models';
import { axiosInstance } from '@bom-nextgen-keycloak/web/shared/util';
import { AxiosResponse } from 'axios';

export type CreateResourceParams = {
  clientId: string;
  payload: ResourceClientRegister;
};

export type DeleteResourceParams = {
  clientId: string;
  resourceId: string;
};

export type UpdateResourceParams = {
  clientId: string;
  payload: ResourceClientUpdate;
};

export const fetchResources = (clientId: string, params: ResourceClientQuery) =>
  axiosInstance
    .get(`/resources/clients/${clientId}`, { params })
    .then(({ data }: AxiosResponse<IResourceClientList>) => data);

export const createResource = ({ clientId, payload }: CreateResourceParams) =>
  axiosInstance
    .post(`/resources/clients/${clientId}`, payload)
    .then(({ data }: AxiosResponse<ResourceClient>) => data);

export const deleteResource = ({
  clientId,
  resourceId,
}: DeleteResourceParams) =>
  axiosInstance.delete(
    `/resources/clients/${clientId}/resources/${resourceId}`
  );

export const fetchResourceById = (clientId: string, resourceId: string) =>
  axiosInstance
    .get<ResourceClient>(
      `/resources/clients/${clientId}/resources/${resourceId}`
    )
    .then(({ data }: AxiosResponse<ResourceClient>) => data);

export const updateResource = ({ clientId, payload }: UpdateResourceParams) =>
  axiosInstance.put(`/resources/clients/${clientId}`, payload);
