import { GroupDto, UserGroupMemeberDto } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionUserDetail,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchGroupById,
  joinGroupUser,
  listGroupMembers,
  QUERY_KEY,
  removeUserGroup,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { ButtonRounded, NotFound } from '@bom-nextgen-keycloak/web/shared/ui';
import {
  CardHeader,
  CircularProgress,
  List,
  ListItemText,
} from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import { AxiosError } from 'axios';
import { FC, Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { TreeViewUserGroup } from '../../TreeViewUserGroup/TreeViewUserGroup';
import {
  LoaddingContainer,
  StyledCard,
  StyledCardContent,
  StyledListItem,
} from '../Group.styled';

type GroupAvailableProps = {
  userId: string;
  companyId: string;
};

const findNodeByID = (
  group: GroupDto | null | undefined,
  id: string | undefined
) => {
  let result = null;
  if (id === group?.id) {
    return group;
  } else {
    if (group?.subGroups) {
      group?.subGroups.some((node) => (result = findNodeByID(node, id)));
    }
    return result;
  }
};

const GroupAvailable: FC<GroupAvailableProps> = ({ userId, companyId }) => {
  const resource = useResource();
  const { setAlertMessage } = useAlertMessage();
  const queryClient = useQueryClient();
  const [selectedGroupMemeber, setSelectedGroupMemeber] =
    useState<UserGroupMemeberDto>();
  const [selectedNode, setSelectedNode] = useState<GroupDto | null>(null);
  const [groups, setGroups] = useState<GroupDto[]>([]);
  const { isEditGroupTab } = useSelector(selectPermissionUserDetail);

  const { isLoading, data: results = { listmember: [], groupDetail: {} } } =
    useQuery(
      [QUERY_KEY.USER_GROUP_DETAIL, companyId, userId],
      () =>
        Promise.all([
          listGroupMembers(userId, { groupId: resource.groupId }),
          fetchGroupById(resource.groupId),
        ]).then(([listmember, groupDetail]) => ({
          listmember: listmember || [],
          groupDetail: groupDetail || {},
        })),
      {
        onError: (error: AxiosError<ErrorMessage>) => {
          const message =
            error.response?.data.message ||
            'Cannot get group list and group detail';
          setAlertMessage({
            message,
            typeStatusMessage: 'error',
            statusCode: error.response?.status,
          });
        },
      }
    );

  useEffect(() => {
    return () =>
      queryClient.removeQueries([
        QUERY_KEY.USER_GROUP_DETAIL,
        companyId,
        userId,
      ]);
  }, [companyId, queryClient, userId]);

  const joinGroupUserMutation = useMutation(joinGroupUser, {
    onSuccess: (data) => {
      setAlertMessage({
        message: 'User has been added to a group',
        typeStatusMessage: 'success',
      });
      queryClient.invalidateQueries([
        QUERY_KEY.USER_GROUP_DETAIL,
        companyId,
        userId,
      ]);
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot add user to a group';
      setAlertMessage({
        message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const removeUserGroupMutation = useMutation(removeUserGroup, {
    onSuccess: (data) => {
      setAlertMessage({
        message: 'User has been removed from a group',
        typeStatusMessage: 'success',
      });
      queryClient.invalidateQueries([
        QUERY_KEY.USER_GROUP_DETAIL,
        companyId,
        userId,
      ]);
      queryClient.invalidateQueries([
        QUERY_KEY.USER_GROUP_MEMBERS,
        resource.groupId,
        userId,
      ]);
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot remove user from a group';
      setAlertMessage({
        message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const memberIds = results.listmember.map((item) => item.id);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: UserGroupMemeberDto
  ) => {
    setSelectedGroupMemeber(data);
  };

  const handleNodeSelected = (node: GroupDto | null) => {
    const regex = /\/Subscriptions/;
    const findNode = findNodeByID(results?.groupDetail, node?.parentsId);
    const nodeMemeber = findNode?.subGroups?.find((val) =>
      memberIds.includes(val?.id || uuidv4())
    );

    const hasSubscription = nodeMemeber?.path?.search(regex) !== -1;
    if (
      hasSubscription &&
      !nodeMemeber &&
      !memberIds.includes(node?.id || uuidv4())
    ) {
      setUpValueGroup(node);
    } else if (
      !hasSubscription &&
      nodeMemeber?.path?.search(regex) === -1 &&
      !memberIds.includes(node?.id || uuidv4())
    ) {
      setUpValueGroup(node);
    } else {
      setGroups([]);
      setSelectedNode(null);
    }
  };

  const setUpValueGroup = (node: GroupDto | null) => {
    setGroups([]);
    setSelectedNode(node);
    setParentsGroups(node);
  };

  const setParentsGroups = (selectNode: GroupDto | null) => {
    const nodeParentsGroup = findNodeByID(
      results?.groupDetail,
      selectNode?.parentsId
    );
    if (nodeParentsGroup?.parentsId) {
      setGroups((prevArray) => [nodeParentsGroup, ...prevArray]);
      setParentsGroups(nodeParentsGroup);
    }
  };

  const handleLeaveGroup = () => {
    if (selectedGroupMemeber) {
      removeUserGroupMutation.mutate({
        userId,
        groupId: selectedGroupMemeber.id,
        clientId: resource.clientId,
      });
    }
  };

  const handleJoinGroup = () => {
    if (selectedNode && groups.length) {
      const groupIds = groups.map((val) => val?.id || '');
      joinGroupUserMutation.mutate({
        userId,
        groupIds: [...groupIds, selectedNode.id || ''],
      });
    }
  };

  const isDisabledJoin =
    !selectedNode ||
    selectedNode?.level !== 4 ||
    joinGroupUserMutation.isLoading;

  const isDisabledLeave =
    !selectedGroupMemeber ||
    selectedGroupMemeber?.level === 1 ||
    removeUserGroupMutation.isLoading;

  const renderListMember = () => {
    if (results.listmember.length) {
      return results.listmember.map((item) => (
        <StyledListItem
          key={item.id}
          selected={selectedGroupMemeber === item}
          onClick={(event: any) => handleListItemClick(event, item)}
        >
          <ListItemButton>
            <ListItemText primary={item.path} />
          </ListItemButton>
        </StyledListItem>
      ));
    } else {
      return <NotFound />;
    }
  };

  return (
    <Fragment>
      <StyledCard>
        <CardHeader
          action={
            <ButtonRounded
              disabled={isDisabledLeave || !isEditGroupTab}
              aria-label="leave"
              variant="contained"
              color="primary"
              onClick={handleLeaveGroup}
              loading={removeUserGroupMutation.isLoading}
            >
              Leave
            </ButtonRounded>
          }
          title="Group Membership"
        />
        <StyledCardContent>
          {isLoading || removeUserGroupMutation.isLoading ? (
            <LoaddingContainer>
              <CircularProgress />
            </LoaddingContainer>
          ) : (
            <List component="nav" aria-label="main mailbox folders">
              {renderListMember()}
            </List>
          )}
        </StyledCardContent>
      </StyledCard>

      <StyledCard>
        <CardHeader
          action={
            <ButtonRounded
              aria-label="join"
              variant="contained"
              color="primary"
              onClick={handleJoinGroup}
              disabled={isDisabledJoin || !isEditGroupTab}
              loading={joinGroupUserMutation.isLoading}
            >
              Join
            </ButtonRounded>
          }
          title="Available Groups"
        />
        <StyledCardContent>
          {isLoading || joinGroupUserMutation.isLoading ? (
            <LoaddingContainer>
              <CircularProgress />
            </LoaddingContainer>
          ) : (
            <TreeViewUserGroup
              groupDetail={results.groupDetail}
              memberIds={memberIds}
              onNodeSelected={handleNodeSelected}
            />
          )}
        </StyledCardContent>
      </StyledCard>
    </Fragment>
  );
};

export { GroupAvailable };
