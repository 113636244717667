import { AuthorizationDto, UserAuth } from '@bom-nextgen-keycloak/models';
import { gatewayAxiosInstance } from '@bom-nextgen-keycloak/web/shared/util';
import { AxiosResponse } from 'axios';

export const fetchPermission = () =>
  gatewayAxiosInstance
    .get('/authorization/permissions')
    .then(({ data }: AxiosResponse<AuthorizationDto>) => data);

export const fetchProfileUser = () =>
  gatewayAxiosInstance
    .get('/authorization/me')
    .then(({ data }: AxiosResponse<UserAuth>) => data);
