import { ResourceDto } from '@bom-nextgen-keycloak/models';
import {
  FeaturePage,
  PermissionUserDetailPage,
  PermissionUserPage,
  ScopeApplication,
  UserEvent,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { getValueAttributeKeycloak } from '@bom-nextgen-keycloak/web/shared/util';
import { matchPermission, ResourceType } from '../reducer/permission.reducer';

const getComponentUser = (resources: ResourceDto[]): ResourceType[] => {
  return resources
    .filter(
      (val) =>
        val.type === 'component' &&
        getValueAttributeKeycloak(val.attributes, 'feature').toLowerCase() ===
          FeaturePage.USER.toLowerCase()
    )
    .map((val) => ({
      ...val,
      scopes: val.scopes?.map((scope) => scope.name) || [],
    }));
};

export const mapUserPermission = (
  resources: ResourceDto[]
): PermissionUserPage => {
  const userComponentList: ResourceType[] = getComponentUser(resources);

  return {
    canViewAdd: matchPermission(
      userComponentList,
      UserEvent.ADD,
      ScopeApplication.CREATE
    ),
    canViewDelete: matchPermission(
      userComponentList,
      UserEvent.DELETE,
      ScopeApplication.DELETE
    ),
    canViewExport: matchPermission(
      userComponentList,
      UserEvent.EXPORT,
      ScopeApplication.EXPORT
    ),
    canViewToggle: matchPermission(
      userComponentList,
      UserEvent.ENABLED,
      ScopeApplication.TOGGLE
    ),
    canViewLeaveGroup: matchPermission(
      userComponentList,
      UserEvent.USER_REMOVE,
      ScopeApplication.REMOVE
    ),
  };
};

export const mapUserPermissionDetail = (
  resources: ResourceDto[]
): PermissionUserDetailPage => {
  const userComponentList: ResourceType[] = getComponentUser(resources);

  const userDetailComponentList: ResourceType[] = resources
    .filter(
      (val) =>
        val.type === 'subset' &&
        getValueAttributeKeycloak(val.attributes, 'feature').toLowerCase() ===
          FeaturePage.USER.toLowerCase()
    )
    .map((val) => ({
      ...val,
      scopes: val.scopes?.map((scope) => scope.name) || [],
    }));

  return {
    canViewPage: matchPermission(
      userDetailComponentList,
      UserEvent.USER_DETAIL,
      ScopeApplication.VIEW
    ),
    canViewGeneralTab: matchPermission(
      userComponentList,
      UserEvent.USER_GENERAL,
      ScopeApplication.VIEW
    ),
    canViewAttributesTab: matchPermission(
      userComponentList,
      UserEvent.USER_ATTRIBUTE,
      ScopeApplication.VIEW
    ),
    canViewDeveloperToolTab: matchPermission(
      userComponentList,
      UserEvent.USER_DEVELOPER,
      ScopeApplication.VIEW
    ),
    canViewGroupTab: matchPermission(
      userComponentList,
      UserEvent.USER_GROUP,
      ScopeApplication.VIEW
    ),
    canViewPasswordTab: matchPermission(
      userComponentList,
      UserEvent.USER_PASSWORD,
      ScopeApplication.VIEW
    ),
    canViewRoleMappingTab: matchPermission(
      userComponentList,
      UserEvent.USER_ROLE,
      ScopeApplication.VIEW
    ),
    canViewSessionTab: matchPermission(
      userComponentList,
      UserEvent.USER_SESSION,
      ScopeApplication.VIEW
    ),
    canViewIdpTab: matchPermission(
      userComponentList,
      UserEvent.USER_IDP,
      ScopeApplication.VIEW
    ),
    isEditAttributesTab: matchPermission(
      userComponentList,
      UserEvent.USER_ATTRIBUTE,
      ScopeApplication.EDIT
    ),
    isEditGeneralTab: matchPermission(
      userComponentList,
      UserEvent.USER_GENERAL,
      ScopeApplication.EDIT
    ),

    isEditGroupTab: matchPermission(
      userComponentList,
      UserEvent.USER_GROUP,
      ScopeApplication.EDIT
    ),
    isEditPasswordTab: matchPermission(
      userComponentList,
      UserEvent.USER_PASSWORD,
      ScopeApplication.EDIT
    ),
    isEditRoleMappingTab: matchPermission(
      userComponentList,
      UserEvent.USER_ROLE,
      ScopeApplication.EDIT
    ),
    isEditUsername: matchPermission(
      userComponentList,
      UserEvent.EDIT_USERNAME,
      ScopeApplication.EDIT
    ),
  };
};
