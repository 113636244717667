import { IUserRepresentation } from '@bom-nextgen-keycloak/models';
import { selectPermissionUser } from '@bom-nextgen-keycloak/web/core';
import {
  HeadCell,
  OrderTable,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  EnhancedTableHead,
  IconButtonDelete,
  NotFound,
  Pagination,
  StyledTextEllipsis,
  TableContainer,
  TableRowSkeleton,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  getComparator,
  stableSort,
} from '@bom-nextgen-keycloak/web/shared/util';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import Paper from '@mui/material/Paper';
import { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ChipEnabled } from './UserTable.styled';

type UserTableProps = {
  data: IUserRepresentation[];
  loading?: boolean;
  selectedPage: number;
  totalRecord: number;
  canViewEditBtn: boolean;
  isShowDeleteButton?: boolean;
  isShowPagination?: boolean;
  handleChange: (event: SelectChangeEvent<unknown>) => void;
  onClickDelete?: (user: IUserRepresentation) => void;
  onClickLeave?: (user: IUserRepresentation) => void;
};

const headCells: HeadCell<keyof IUserRepresentation>[] = [
  {
    id: 'firstName',
    sortKey: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'username',
    sortKey: 'username',
    numeric: false,
    disablePadding: false,
    label: 'Username',
  },
  {
    id: 'email',
    sortKey: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'createdTimestamp',
    sortKey: 'createdTimestamp',
    numeric: false,
    disablePadding: false,
    label: 'Created on',
  },
  {
    id: 'enabled',
    sortKey: 'enabled',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'enabledSSO',
    sortKey: 'enabledSSO',
    numeric: false,
    disablePadding: false,
    label: 'User enabled (SSO)',
  },
  {
    id: 'actions',
    sortKey: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

const UserTable: FC<UserTableProps> = ({
  data,
  onClickDelete,
  onClickLeave,
  loading,
  selectedPage,
  handleChange,
  totalRecord = 0,
  isShowDeleteButton = true,
  isShowPagination = true,
  canViewEditBtn = false,
}) => {
  const { canViewDelete, canViewLeaveGroup } =
    useSelector(selectPermissionUser);

  const [order, setOrder] = React.useState<OrderTable>('desc');
  const [orderBy, setOrderBy] =
    React.useState<keyof IUserRepresentation>('createdTimestamp');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IUserRepresentation
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isShowleave = isShowDeleteButton && canViewLeaveGroup;
  const isShowDelete = isShowDeleteButton && canViewDelete;

  return (
    <Paper>
      <TableContainer>
        <Table
          aria-label="user table"
          aria-labelledby="tableTitle"
          stickyHeader
        >
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            <TableRowSkeleton loading={loading} cellLength={headCells.length}>
              {data.length ? (
                stableSort<IUserRepresentation>(
                  data,
                  getComparator(order, orderBy)
                ).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell title={row.firstName + ' ' + row.lastName}>
                      <StyledTextEllipsis>
                        {row.firstName} {row.lastName}
                      </StyledTextEllipsis>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {row.username || '-'}
                      </Typography>
                    </TableCell>
                    <TableCell title={row.email}>
                      <StyledTextEllipsis>
                        {row.email || '-'}
                      </StyledTextEllipsis>
                    </TableCell>
                    <TableCell>
                      {format(
                        new Date(row.createdTimestamp || ''),
                        'MM/dd/yyyy'
                      )}
                    </TableCell>
                    <TableCell sx={{ width: 100 }}>
                      <ChipEnabled
                        size="small"
                        enabled={row.enabled ? 'true' : 'false'}
                        label={row.enabled ? 'active' : 'inactive'}
                      />
                    </TableCell>

                    <TableCell sx={{ width: 100 }}>
                      <ChipEnabled
                        size="small"
                        enabled={row.enabledSSO ? 'true' : 'false'}
                        label={row.enabledSSO ? 'enabled' : 'disabled'}
                      />
                    </TableCell>

                    <TableCell>
                      {canViewEditBtn && (
                        <Link to={`/users/${row.id}`}>
                          <Tooltip title="Edit user">
                            <IconButtonDelete aria-label="edit">
                              <EditIcon fontSize="small" />
                            </IconButtonDelete>
                          </Tooltip>
                        </Link>
                      )}

                      {isShowleave && onClickLeave && (
                        <Tooltip title="Remove user">
                          <IconButtonDelete
                            aria-label="delete"
                            onClick={() => onClickLeave(row)}
                          >
                            <PersonRemoveIcon fontSize="small" />
                          </IconButtonDelete>
                        </Tooltip>
                      )}

                      {isShowDelete && onClickDelete && (
                        <Tooltip title="Delete user">
                          <IconButtonDelete
                            aria-label="delete"
                            onClick={() => onClickDelete(row)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButtonDelete>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <NotFound />
                  </TableCell>
                </TableRow>
              )}
            </TableRowSkeleton>
          </TableBody>
        </Table>
      </TableContainer>
      {isShowPagination && (
        <Pagination
          selectedPage={selectedPage}
          totalRecord={totalRecord}
          handleChange={handleChange}
          options={[
            { label: '1 - 200', value: 0 },
            { label: '201 - 400', value: 200 },
            { label: '401 - 600', value: 400 },
            { label: '601 - 800', value: 600 },
            { label: '801 - 1000', value: 800 },
            { label: '1001 - 1200', value: 1000 },
            { label: '1201 - 1400', value: 1200 },
            { label: '1401 - 1600', value: 1400 },
            { label: '1601 - 1800', value: 1600 },
            { label: '1801 - 2000', value: 1800 },
            { label: '2001 - 2200', value: 2000 },
            { label: '2201 - 2400', value: 2200 },
            { label: '2401 - 2600', value: 2400 },
            { label: '2601 - 2800', value: 2600 },
            { label: '2801 - 3000', value: 2800 },
          ]}
        />
      )}
    </Paper>
  );
};

export { UserTable };
