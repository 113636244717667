import { ButtonRounded } from '@bom-nextgen-keycloak/web/shared/ui';
import { convertUTCToLocalDate } from '@bom-nextgen-keycloak/web/shared/util';
import { DateRange } from '@mui/lab/DateRangePicker';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FC, Fragment } from 'react';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';

const options = [
  { label: 'Login', value: 'LOGIN' },
  { label: 'Login Error', value: 'LOGIN_ERROR' },
];

type FilterToolbarPros = {
  type: string;
  startDate: Date | null;
  endDate: Date | null;
  onChangeType: (event: SelectChangeEvent) => void;
  onChangeDatePicker: (date: DateRange<Date | null>) => void;
  onClickClear: () => void;
};

const FilterToolbar: FC<FilterToolbarPros> = ({
  startDate,
  endDate,
  type,
  onChangeType,
  onChangeDatePicker,
  onClickClear,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
        mb: 2,
      }}
    >
      <Box sx={{ mr: 2 }}>
        <FormControl sx={{ m: 1, minWidth: 120, mb: 3.5 }}>
          <InputLabel id="select-type-label">Type</InputLabel>
          <Select
            labelId="select-type-label"
            id="select-type"
            value={type}
            label="Type"
            onChange={onChangeType}
          >
            {options.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                <span>{item.label}</span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateRangePicker
            startText="Start date"
            endText="End date"
            value={[
              convertUTCToLocalDate(startDate),
              convertUTCToLocalDate(endDate),
            ]}
            onChange={onChangeDatePicker}
            renderInput={(startProps, endProps) => (
              <Fragment>
                <TextField
                  {...startProps}
                  helperText="Select a date 1 day before"
                />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField
                  {...endProps}
                  helperText="Select a date 1 day after"
                />
              </Fragment>
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ ml: 2, mb: 3.5 }}>
        <ButtonRounded variant="contained" onClick={onClickClear}>
          Clear
        </ButtonRounded>
      </Box>
    </Box>
  );
};

export { FilterToolbar };
