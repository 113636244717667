import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useKeycloak } from '@react-keycloak/web';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
`;

const Title = styled(Typography)`
  font-weight: 500;
`;

const SubTitle = styled(Typography)`
  margin-bottom: 1rem;
`;

const Description = styled(Typography)`
  margin-bottom: 1rem;
  text-align: center;
`;

export function Unauthorized() {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  useEffect(() => {
    if (!keycloak.authenticated) {
      navigate('/login');
    }
  }, [history, keycloak]);

  const handleLogout = useCallback(() => {
    keycloak?.logout();
    localStorage.removeItem('resource');
  }, [keycloak]);

  return (
    <Wrapper>
      <Title variant="h2">Access Restricted</Title>
      <Description variant="body1">
        You don't have privilege to access BOM Support Console.
        <br />
        Contact{' '}
        <a href="mailto:theerawat.pon@truedigital.com">
          theerawat.pon@truedigital.com
        </a>{' '}
        to request access.
      </Description>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button sx={{ mr: 2 }} href="/" component={Link} variant="outlined">
          RETURN HOME
        </Button>
        <Button variant="outlined" onClick={handleLogout}>
          Log Out
        </Button>
      </Box>
    </Wrapper>
  );
}

export default Unauthorized;
