import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { FC } from 'react';
import { StyledDialog } from './DeleteDialog.styled';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { ButtonRounded } from '../ButtonRounded';

export type DeleteDialogProps = {
  title: string;
  titleBtn?: string;
  open: boolean;
  loading: boolean;
  description?: string;
  onClose: () => void;
  onDelete: () => void;
};

const DeleteDialog: FC<DeleteDialogProps> = ({
  title,
  onClose,
  open,
  onDelete,
  loading,
  description = 'Are you sure you want to delete?',
  titleBtn = 'Delete',
}) => {
  const handleClose = (
    event: any,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'backdropClick') {
      return;
    }
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <StyledDialog
      disableEscapeKeyDown
      onClose={handleClose}
      aria-labelledby="dialog-title"
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          dangerouslySetInnerHTML={{ __html: description }}
          id="alert-dialog-description"
        ></DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonRounded onClick={onClose} disabled={loading} color="primary">
          Cancel
        </ButtonRounded>
        <ButtonRounded
          onClick={onDelete}
          color="error"
          autoFocus
          loading={loading}
          data-test-id="btn-delete"
        >
          {titleBtn}
        </ButtonRounded>
      </DialogActions>
    </StyledDialog>
  );
};

export { DeleteDialog };
