import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { FC, Fragment } from 'react';

type PageLoadingProps = {
  isLoading: boolean;
  children: React.ReactNode;
};
const PageLoading: FC<PageLoadingProps> = ({ isLoading, children }) => {
  return (
    <Fragment>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </Fragment>
  );
};

export { PageLoading };
