import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';

export const PaperSearch = styled(Paper)<{ width: number | undefined }>`
  padding: '2px 4px';
  display: flex;
  align-items: center;
  width: ${(props) => (props.width ? props.width + 'px' : '400px')};
`;

export const SearchInputBase = styled(InputBase)`
  flex: 1;
  width: 85%;
  padding-left: 1rem;
`;

export const SearchIconButton = styled(IconButton)`
  padding: 10px;
`;

export const StyledSelect = styled(Select)`
  width: 15%;
  margin-left: 0.5rem;
  height: 100%;
`;

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled('label')`
  margin-bottom: 0.5rem;
`;
