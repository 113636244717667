import { LoggingQuery } from '@bom-nextgen-keycloak/models';
import {
  ButtonRounded,
  DateRangePickerUI,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  convertLocalToUTCDate,
  convertUTCToLocalDate,
} from '@bom-nextgen-keycloak/web/shared/util';
import { DateRange } from '@mui/lab/DateRangePicker';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { FC, useEffect, useState } from 'react';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import * as React from 'react';

export const WrapperAdvancedSearchFilter = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const optionTypes = [
  { label: 'Company', value: 'company' },
  { label: 'Keycloak', value: 'keycloak' },
  { label: 'Subscription', value: 'subscription' },
  { label: 'UserEnabled', value: 'userEnabled' },
];

const optionStatus = [
  { label: 'Error', value: 'error' },
  { label: 'Success', value: 'success' },
];

export type PermissionSearchAdvanced = {
  status?: string | null;
  type?: string | null;
  startDate: Date | null;
  endDate: Date | null;
};

type SearchAdvancedFilterPros = {
  id: string | undefined;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  params: LoggingQuery;
  onClose: () => void;
  onApply: (data: PermissionSearchAdvanced) => void;
};

const SearchAdvancedFilter: FC<SearchAdvancedFilterPros> = ({
  id,
  open,
  anchorEl,
  params,
  onClose,
  onApply,
}) => {
  const [searchAdvanced, setSearchAdvanced] =
    useState<PermissionSearchAdvanced>({
      status: '',
      type: '',
      startDate: null,
      endDate: null,
    });

  useEffect(() => {
    setSearchAdvanced({
      status: params.status,
      type: params.type,
      startDate: params.startDate || null,
      endDate: params.endDate || null,
    });
  }, [params]);

  const handleChangeStatus = (event: SelectChangeEvent<string>) => {
    setSearchAdvanced((previous) => ({
      ...previous,
      status: event.target.value,
      type: event.target.value === 'success' ? '' : previous.type,
    }));
  };

  const handleChangeType = (event: SelectChangeEvent<string>) => {
    setSearchAdvanced((previous) => ({
      ...previous,
      type: event.target.value,
    }));
  };

  const handleChangeDatePicker = (date: DateRange<Date | null>) => {
    const [startDate, endDate] = date;
    if (startDate && endDate) {
      setSearchAdvanced((previous) => ({
        ...previous,
        startDate: convertLocalToUTCDate(startDate),
        endDate: convertLocalToUTCDate(endDate),
      }));
    }
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <WrapperAdvancedSearchFilter>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="select-type-label">Status</InputLabel>
            <Select
              labelId="select-type-label"
              id="select-type"
              value={searchAdvanced.status || ''}
              label="Status"
              onChange={handleChangeStatus}
            >
              {optionStatus.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <span>{item.label}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ ml: 3, minWidth: 120 }}>
            <InputLabel id="select-type-label">Error type</InputLabel>
            <Select
              labelId="select-type-label"
              id="select-type"
              value={searchAdvanced.type || ''}
              label="Error type"
              onChange={handleChangeType}
              disabled={searchAdvanced.status === 'success'}
            >
              {optionTypes.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <span>{item.label}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Box>
            <DateRangePickerUI
              value={[
                convertUTCToLocalDate(searchAdvanced.startDate),
                convertUTCToLocalDate(searchAdvanced.endDate),
              ]}
              startText="Start date"
              endText="End date"
              onChange={handleChangeDatePicker}
            />
          </Box>
        </Box>
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              mr: 2,
            }}
          >
            <ButtonRounded variant="outlined" onClick={onClose}>
              Close
            </ButtonRounded>
          </Box>
          <ButtonRounded
            variant="contained"
            onClick={() => onApply(searchAdvanced)}
          >
            Apply
          </ButtonRounded>
        </Box>
      </WrapperAdvancedSearchFilter>
    </Popover>
  );
};

export { SearchAdvancedFilter };
