import { UserAuth } from '@bom-nextgen-keycloak/models';
import { selectOptionResource } from '@bom-nextgen-keycloak/web/core';
import {
  AppMenuItem,
  ButtonRounded,
} from '@bom-nextgen-keycloak/web/shared/ui';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { FC, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { version } from '../../../../../../../../version';
import { MenuProfile } from '../MenuProfile';
import {
  ContentDrawerToolbar,
  ListMenu,
  StyledAppBar,
  StyledSelect,
  StyledSkeleton,
  StyledSkeletonDropdown,
} from './ResponsiveDrawer.styled';

const drawerWidth = 240;

type AppBarProps = {
  loading: boolean;
  titlePath: string;
  matchUrl: string;
  resourceId?: string;
  userAuth: UserAuth | null;
  appMenuItems: any[];
  onChangeResource: (resourceId: string) => void;
  window?: () => Window;
  onClickLogout: () => void;
};

const ResponsiveDrawer: FC<AppBarProps> = ({
  loading,
  titlePath,
  matchUrl,
  appMenuItems,
  resourceId,
  userAuth,
  onChangeResource,
  onClickLogout,
  window,
}) => {
  const optionResource = useSelector(selectOptionResource);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [id, setResourceId] = useState('');
  const [activePath, setActivePath] = useState('');

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleChangeResource = (event: SelectChangeEvent<unknown>) => {
    const isDetailPath = location.pathname?.match(/\//g)?.length;
    const path = location.pathname?.replace(
      /\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}.*/g,
      ''
    );
    const resourceValue = event.target.value as string;
    if (location.pathname && path && isDetailPath && isDetailPath >= 2) {
      setOpen(true);
      setResourceId(event.target.value as string);
      setActivePath(path);
    } else {
      onChangeResource(resourceValue);
    }
  };

  const handleAgree = () => {
    setOpen(false);
    onChangeResource(id);
    navigate(activePath);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <Fragment>
      <ContentDrawerToolbar>
        <StyledSelect
          labelId="simple-select-label"
          id="simple-select"
          onChange={handleChangeResource}
          value={resourceId}
        >
          {optionResource.map((item) => (
            <MenuItem key={item.resourceId} value={item.resourceId}>
              {item?.displayName}
            </MenuItem>
          ))}
        </StyledSelect>
      </ContentDrawerToolbar>
      <ListMenu>
        {appMenuItems.map((item, index) => (
          <AppMenuItem matchUrl={matchUrl} {...item} key={index} />
        ))}
        <ListItem
          sx={{
            backgroundColor: '#fff',
            bottom: 0,
            color: '#666',
            fontSize: '12px',
            position: 'fixed',
            width: '239px',
          }}
        >
          Version {version}
        </ListItem>
      </ListMenu>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Are you sure you will change the resource ?'}
        </DialogTitle>
        <DialogActions>
          <ButtonRounded onClick={() => setOpen(false)}>Close</ButtonRounded>
          <ButtonRounded onClick={handleAgree} autoFocus>
            Agree
          </ButtonRounded>
        </DialogActions>
      </Dialog>
    </Fragment>
  );

  const menuId = 'primary-search-account-menu';

  const drawerloading = (
    <Fragment>
      <ContentDrawerToolbar>
        <StyledSkeletonDropdown />
      </ContentDrawerToolbar>
      <ListMenu>
        <ListItem>
          <StyledSkeleton />
        </ListItem>
        <ListItem>
          <StyledSkeleton />
        </ListItem>
        <ListItem>
          <StyledSkeleton />
        </ListItem>
        <ListItem>
          <StyledSkeleton />
        </ListItem>
      </ListMenu>
    </Fragment>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <StyledAppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setMobileOpen(true)}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            {titlePath}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              aria-label="show more"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </StyledAppBar>

      <MenuProfile
        menuId={menuId}
        userAuth={userAuth}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        onClickLogout={onClickLogout}
      />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {loading ? drawerloading : drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {loading ? drawerloading : drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export { ResponsiveDrawer };
