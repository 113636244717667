import { RoleUpdate } from '@bom-nextgen-keycloak/models';
import { selectPermissionRoleDetail } from '@bom-nextgen-keycloak/web/core';
import {
  RoleForm,
  validationSchema,
} from '@bom-nextgen-keycloak/web/pages/feature-roles';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  PageLoading,
  ProtectedRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import SaveIcon from '@mui/icons-material/Save';
import { Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useRoleDetail } from '../../hooks/useRoleDetail';
import { ActionWrapper, StyledButton, StyledFormikForm } from './Detail.styled';

type RoleDetailForm = {
  name: string;
  description: string;
};

const Detail: FC = () => {
  const params = useParams();
  const roleId = params?.id || '';
  const { roleDetail, handleUpdate, isLoading, isLoadingSubmit } =
    useRoleDetail(roleId);

  const { isEditGeneralTab } = useSelector(selectPermissionRoleDetail);

  const initialValues: RoleDetailForm = {
    name: roleDetail?.name || '',
    description: roleDetail?.description || '',
  };

  return (
    <PageLoading isLoading={isLoading}>
      <Formik
        initialValues={initialValues}
        onSubmit={(
          payload: RoleDetailForm,
          { setSubmitting }: FormikHelpers<RoleDetailForm>
        ) => {
          const payloadToSubmit: RoleUpdate = {
            ...roleDetail,
            id: roleDetail.id || '',
            name: payload.name,
            description: payload.description,
          };
          handleUpdate(payloadToSubmit);
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ isSubmitting, isValid, dirty, resetForm }) => (
          <StyledFormikForm>
            <RoleForm isEdit />
            <ActionWrapper>
              <StyledButton
                color="primary"
                variant="contained"
                disabled={!dirty || !isEditGeneralTab}
                onClick={() => resetForm()}
              >
                Reset
              </StyledButton>
              <StyledButton
                type="submit"
                color="primary"
                variant="contained"
                loadingPosition="start"
                startIcon={<SaveIcon />}
                loading={isLoadingSubmit}
                disabled={
                  !(isValid && dirty) || isSubmitting || !isEditGeneralTab
                }
                id="btn-save"
              >
                Save
              </StyledButton>
            </ActionWrapper>
          </StyledFormikForm>
        )}
      </Formik>
    </PageLoading>
  );
};

const WrappedRoleDetailFormPage: FC = () => {
  const { canViewPage, canViewGeneralTab } = useSelector(
    selectPermissionRoleDetail
  );
  return (
    <ProtectedRouter
      feature={FeaturePage.ROLE}
      isEditPage
      canView={canViewPage && canViewGeneralTab}
      navigatePath="/roles"
    >
      <Detail />
    </ProtectedRouter>
  );
};

export { WrappedRoleDetailFormPage };
