import { AppBar, Box, List, ListItemText } from '@mui/material';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

export const StyledAppBar = styled(AppBar)`
  box-shadow: none;
`;

export const LeftSection = styled(Box)`
  flex: 1 1 0;
  display: flex;
  align-items: center;
`;

export const ListMenu = styled(List)`
  height: 100%;
`;

export const ListText = styled(ListItemText)``;

export const StyledSelect = styled(Select)`
  text-align: center;
  width: 100%;
`;

export const ContentDrawerToolbar = styled('div')`
  display: flex;
`;

export const StyledSkeleton = styled(Skeleton)`
  width: 100%;
`;

export const StyledSkeletonDropdown = styled(Skeleton)`
  width: 100%;
  margin: 0 1rem;
  height: 4rem;
`;
