import { RoleUpdate } from '@bom-nextgen-keycloak/models';
import { useAlertMessage } from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchRoleById,
  QUERY_KEY,
  updateRoleById,
  UpdateRoleParams,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';

export const useRoleDetail = (roleId: string) => {
  const { setAlertMessage } = useAlertMessage();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isLoading, data: roleDetail = {} } = useQuery(
    [QUERY_KEY.CLIENT_ROLE_DETAIL, roleId],
    () => fetchRoleById(roleId),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get role detail';
        setAlertMessage({
          message,
          typeStatusMessage: 'error',
          statusCode: error.response?.status,
        });
        navigate('/roles');
      },
    }
  );

  const updateRoleByIdMutation = useMutation(updateRoleById, {
    onSuccess: (data) => {
      setAlertMessage({
        message: 'Role has been updated',
        typeStatusMessage: 'success',
      });
      queryClient.invalidateQueries([QUERY_KEY.CLIENT_ROLE_DETAIL, roleId]);
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot update role';
      setAlertMessage({
        message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const handleUpdate = (payload: RoleUpdate) => {
    const payloadUpdateRole: UpdateRoleParams = {
      roleId,
      payload: { ...payload, attributes: roleDetail?.attributes },
    };
    updateRoleByIdMutation.mutate(payloadUpdateRole);
  };

  const handleSaveAttribute = (attributes: any) => {
    if (roleDetail) {
      const payloadClientRole: RoleUpdate = {
        ...roleDetail,
        id: roleDetail.id || '',
        name: roleDetail.name || '',
        attributes: { ...attributes },
      };
      const payload: UpdateRoleParams = {
        roleId: roleId,
        payload: payloadClientRole,
      };
      updateRoleByIdMutation.mutate(payload);
    }
  };

  return {
    isLoading,
    isLoadingSubmit: updateRoleByIdMutation.isLoading,
    roleDetail,
    handleUpdate,
    handleSaveAttribute,
  };
};
