import {
  AlertMessageContextProvider,
  UserContextProvider,
} from '@bom-nextgen-keycloak/web/core';
import { FullPageLoadingSpinner } from '@bom-nextgen-keycloak/web/shared/ui';
import { theme } from '@bom-nextgen-keycloak/web/theme';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { useKeycloak } from '@react-keycloak/web';
import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import Router from './router';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

function ErrorFallback({ error }: any) {
  return (
    <div role="alert">
      <h1>Something went wrong:</h1>
      <pre>{error.message}</pre>
    </div>
  );
}

const App: FC = () => {
  const { initialized } = useKeycloak();
  if (!initialized) {
    return <FullPageLoadingSpinner />;
  }
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <UserContextProvider>
            <AlertMessageContextProvider>
              <QueryClientProvider client={queryClient}>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Router />
                </ErrorBoundary>
              </QueryClientProvider>
            </AlertMessageContextProvider>
          </UserContextProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
