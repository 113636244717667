import { pickBy } from 'lodash-es';

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: any,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function fromEntries<T>(entries: [keyof T, T[keyof T]][]): T {
  return entries.reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value }),
    <T>{}
  );
}

export function toEntries<T>(obj: T): [keyof T, T[keyof T]][] {
  const entries: [string, any][] = Object.entries(obj);
  const entriesWithKeysLookedUp: [keyof T, T[keyof T]][] = entries.map(
    (item) => {
      const keyString = item[0];
      const value = item[1];
      return [<keyof T>keyString, value];
    }
  );
  return entriesWithKeysLookedUp;
}

export function isTruthy(value: any): boolean {
  return value === 0 || !!value;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function removeEmptyAttribute<T extends {}>(obj: T): Partial<T> {
  return pickBy(obj, isTruthy) as Partial<T>;
}

export function getValueAttributeKeycloak(obj: any, key: any): string {
  return obj[key] ? obj[key][0] : '';
}

export const getKeyValue = <T extends object, U extends keyof T>(
  obj: T,
  key: U
) => obj[key];

export const EMAIL_REGEX =
  /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

export const NO_BEGINNING_SPACE_REGEX =
  /^(?![\s])[\w()$&+,:;=?@#|'/<>.^*()%!\u0E00-\u0E7F\s-]+$/;

export const USERNAME_REGEX =
  /^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,18}[a-zA-Z0-9]$/;

export const NO_SPACE_REGEX = /^\S+$/;

export const MESSAGE_NO_BEGINNING_SPACE_REGEX =
  'No blank space allowed at the beginning';

export const ENGLISH_REGEX = /[^A-Za-z0-9-@._]/gi;
