import {
  EventQuery,
  IEventListDto,
  LoggingDto,
  PageDto,
} from '@bom-nextgen-keycloak/models';
import {
  axiosInstance,
  gatewayAxiosInstance,
} from '@bom-nextgen-keycloak/web/shared/util';
import { AxiosResponse } from 'axios';

export const fetchEvent = (params?: EventQuery) =>
  axiosInstance
    .get(`/events`, { params: { ...params } })
    .then(({ data }: AxiosResponse<IEventListDto>) => data);

export const fetchLogging = (params: any) =>
  gatewayAxiosInstance
    .get(`/logging`, { params: { ...params } })
    .then(({ data }: AxiosResponse<PageDto<LoggingDto>>) => data);

export const fetchLoggingById = (id: string) =>
  gatewayAxiosInstance
    .get(`/logging/${id}`)
    .then(({ data }: AxiosResponse<LoggingDto>) => data);
