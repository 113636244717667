import { ResourceDto } from '@bom-nextgen-keycloak/models';
import {
  FeaturePage,
  PermissionPolicyDetailPage,
  PermissionPolicyPage,
  PolicyEvent,
  ScopeApplication,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { getValueAttributeKeycloak } from '@bom-nextgen-keycloak/web/shared/util';
import { matchPermission, ResourceType } from '../reducer/permission.reducer';

const getComponentPolicy = (resources: ResourceDto[]): ResourceType[] => {
  return resources
    .filter(
      (val) =>
        val.type === 'component' &&
        getValueAttributeKeycloak(val.attributes, 'feature').toLowerCase() ===
          FeaturePage.POLICY.toLowerCase()
    )
    .map((val) => ({
      ...val,
      scopes: val.scopes?.map((scope) => scope.name) || [],
    }));
};

export const mapPolicyPermission = (
  resources: ResourceDto[]
): PermissionPolicyPage => {
  const policyComponentList: ResourceType[] = getComponentPolicy(resources);
  return {
    canViewAdd: matchPermission(
      policyComponentList,
      PolicyEvent.ADD,
      ScopeApplication.CREATE
    ),
    canViewDelete: matchPermission(
      policyComponentList,
      PolicyEvent.DELETE,
      ScopeApplication.DELETE
    ),
  };
};

export const mapPolicyPermissionDetail = (
  resources: ResourceDto[]
): PermissionPolicyDetailPage => {
  const policyComponentList: ResourceType[] = getComponentPolicy(resources);
  const policyDetailComponentList: ResourceType[] = resources
    .filter(
      (val) =>
        val.type === 'subset' &&
        getValueAttributeKeycloak(val.attributes, 'feature').toLowerCase() ===
          FeaturePage.POLICY.toLowerCase()
    )
    .map((val) => ({
      ...val,
      scopes: val.scopes?.map((scope) => scope.name) || [],
    }));

  return {
    canViewPage: matchPermission(
      policyDetailComponentList,
      PolicyEvent.DETAIL,
      ScopeApplication.VIEW
    ),
    canViewGeneralTab: matchPermission(
      policyComponentList,
      PolicyEvent.GENERAL,
      ScopeApplication.VIEW
    ),
    isEditGeneralTab: matchPermission(
      policyComponentList,
      PolicyEvent.GENERAL,
      ScopeApplication.EDIT
    ),
  };
};
