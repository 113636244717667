import {
  LoggingQuery,
  LoggingStatusCode,
  TypeService,
} from '@bom-nextgen-keycloak/models';
import {
  selectPermissionEvent,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  FeaturePage,
  fetchLogging,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  ButtonRounded,
  EmitSearchInput,
  ProtectedRouter,
  SearchInput,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  convertLocalToUTCDate,
  useDebounce,
} from '@bom-nextgen-keycloak/web/shared/util';
import Box from '@mui/material/Box';
import { SelectChangeEvent } from '@mui/material/Select';
import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { PermissionEventTable } from './PermissionEventTable';
import {
  PermissionSearchAdvanced,
  SearchAdvancedFilter,
} from './SearchAdvancedFilter';

const PermissionEvent: FC = () => {
  const resource = useResource();
  const { setAlertMessage } = useAlertMessage();
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQueryTerm = useDebounce(searchQuery, 700);
  const [params, setParams] = useState<LoggingQuery>({
    first: 0,
    max: 200,
    search: '',
    clientId: '',
    startDate: null,
    endDate: null,
  });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const {
    isLoading,
    data: permissionEventList = {
      items: [],
      meta: { totalRecord: 0 },
    },
  } = useQuery(
    [
      QUERY_KEY.PERMISSION_EVENT_LIST,
      params,
      debouncedQueryTerm,
      resource.clientNamePublic,
    ],
    () =>
      fetchLogging({
        ...params,
        search: searchQuery,
        clientId: resource.clientNamePublic,
      }),
    {
      keepPreviousData: true,
      staleTime: 5000,
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get list permission event';
        setAlertMessage({
          message,
          typeStatusMessage: 'error',
          statusCode: error.response?.status,
        });
      },
    }
  );

  const handleChangeSearchFilter = (data: PermissionSearchAdvanced) => {
    setParams((previous) => ({
      ...previous,
      type: data.type as TypeService,
      status: data.status as LoggingStatusCode,
      startDate: convertLocalToUTCDate(data.startDate) || null,
      endDate: convertLocalToUTCDate(data.endDate) || null,
      first: 0,
      max: 200,
    }));
    setAnchorEl(null);
  };

  const handleClearFilter = () => {
    setSearchQuery('');
    setParams((previous) => ({
      ...previous,
      startDate: null,
      endDate: null,
      type: null,
      search: '',
      status: null,
      first: 0,
      max: 200,
    }));
  };

  const handleChangePage = (event: SelectChangeEvent<unknown>) => {
    const selectedPage = event.target.value as number;
    setParams((previous) => ({
      ...previous,
      first: selectedPage,
    }));
  };

  const handleChangeSearch = (value: EmitSearchInput) => {
    setSearchQuery(value.searchValue);
    setParams((previous) => ({
      ...previous,
      first: 0,
    }));
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const openAdvancedSearch = Boolean(anchorEl);
  const id = 'advanced-search-popover';

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'right',
          mb: 2,
        }}
      >
        <SearchInput
          width={280}
          value={searchQuery}
          placeholder="Search by username or email"
          onCliekSearch={handleChangeSearch}
          onKeyDown={handleChangeSearch}
          onChange={handleChangeSearch}
        />
        <Box sx={{ ml: 2, mr: 2 }}>
          <ButtonRounded
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            Advanced Search
          </ButtonRounded>
        </Box>
        <SearchAdvancedFilter
          id={id}
          open={openAdvancedSearch}
          anchorEl={anchorEl}
          params={params}
          onClose={() => setAnchorEl(null)}
          onApply={handleChangeSearchFilter}
        />
        <ButtonRounded variant="contained" onClick={handleClearFilter}>
          Clear
        </ButtonRounded>
      </Box>

      <PermissionEventTable
        data={permissionEventList.items}
        loading={isLoading}
        selectedPage={params.first}
        totalRecord={permissionEventList.meta.totalRecord}
        handleChange={handleChangePage}
      />
    </div>
  );
};

const WrappedEventPermissionPage: FC = () => {
  const { canViewPermission } = useSelector(selectPermissionEvent);
  return (
    <ProtectedRouter
      isEditPage
      feature={FeaturePage.EVENT}
      canView={canViewPermission}
      navigatePath="/events"
    >
      <PermissionEvent />
    </ProtectedRouter>
  );
};

export { WrappedEventPermissionPage };
