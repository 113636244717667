import { styled } from '@mui/material/styles';

export const ActionWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ColumnDatePicker = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextBoxDateMarginStyled = styled('div')`
  margin-top: 1.875rem;
  width: 100%;
`;
