import { ResourceDto } from '@bom-nextgen-keycloak/models';
import {
  FeaturePage,
  GroupEvent,
  PermissionGroupDetailPage,
  PermissionGroupPage,
  ScopeApplication,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { getValueAttributeKeycloak } from '@bom-nextgen-keycloak/web/shared/util';
import { matchPermission, ResourceType } from '../reducer/permission.reducer';

const getComponentGroup = (resources: ResourceDto[]): ResourceType[] => {
  return resources
    .filter(
      (val) =>
        val.type === 'component' &&
        getValueAttributeKeycloak(val.attributes, 'feature').toLowerCase() ===
          FeaturePage.GROUP.toLowerCase()
    )
    .map((val) => ({
      ...val,
      scopes: val.scopes?.map((scope) => scope.name) || [],
    }));
};

export const mapGroupPermission = (
  resources: ResourceDto[]
): PermissionGroupPage => {
  const groupComponentList: ResourceType[] = getComponentGroup(resources);

  return {
    canViewAdd: matchPermission(
      groupComponentList,
      GroupEvent.ADD,
      ScopeApplication.CREATE
    ),
    canViewDelete: matchPermission(
      groupComponentList,
      GroupEvent.DELETE,
      ScopeApplication.DELETE
    ),
    canViewExport: matchPermission(
      groupComponentList,
      GroupEvent.EXPORT,
      ScopeApplication.EXPORT
    ),
  };
};

export const mapGroupPermissionDetail = (
  resources: ResourceDto[]
): PermissionGroupDetailPage => {
  const groupComponentList: ResourceType[] = getComponentGroup(resources);

  const groupDetailComponentList: ResourceType[] = resources
    .filter(
      (val) =>
        val.type === 'subset' &&
        getValueAttributeKeycloak(val.attributes, 'feature').toLowerCase() ===
          FeaturePage.GROUP.toLowerCase()
    )
    .map((val) => ({
      ...val,
      scopes: val.scopes?.map((scope) => scope.name) || [],
    }));

  return {
    canViewPage: matchPermission(
      groupDetailComponentList,
      GroupEvent.DETAIL,
      ScopeApplication.VIEW
    ),
    canViewGeneralTab: matchPermission(
      groupComponentList,
      GroupEvent.GENERAL,
      ScopeApplication.VIEW
    ),
    canViewAttributesTab: matchPermission(
      groupComponentList,
      GroupEvent.ATTRIBUTE,
      ScopeApplication.VIEW
    ),
    canViewRoleMappingTab: matchPermission(
      groupComponentList,
      GroupEvent.ROLE,
      ScopeApplication.VIEW
    ),
    canViewMemberTab: matchPermission(
      groupComponentList,
      GroupEvent.MEMBER,
      ScopeApplication.VIEW
    ),
    isEditAttributesTab: matchPermission(
      groupComponentList,
      GroupEvent.ATTRIBUTE,
      ScopeApplication.EDIT
    ),
    isEditGeneralTab: matchPermission(
      groupComponentList,
      GroupEvent.GENERAL,
      ScopeApplication.EDIT
    ),
    isEditMemberTab: matchPermission(
      groupComponentList,
      GroupEvent.MEMBER,
      ScopeApplication.EDIT
    ),
    isEditRoleMappingTab: matchPermission(
      groupComponentList,
      GroupEvent.ROLE,
      ScopeApplication.EDIT
    ),
  };
};
