import TrueIconSVG from '../../../../theme/src/lib/assets/svg/true-analytics-logo.svg';
import { styled } from '@mui/material/styles';

export const LoginWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 70%;
  margin: auto;
  align-items: center;
  justify-content: center;
`;

export const LoginBox = styled('div')`
  display: flex;
  flex-direction: column;
  min-width: 21.875rem;
  height: 21.875rem;
`;

export const TrueIcon = styled('div')`
  height: 6.25rem;
  background-image: url(${TrueIconSVG});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 5.5rem;
`;
