import { RoleRepresentationDto } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionUserDetail,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  addUserRoleMapping,
  ErrorMessage,
  FeaturePage,
  fetchUserRoleAvailable,
  fetchUserRoleComposite,
  fetchUserRoleMapping,
  QUERY_KEY,
  updateUserRoleMapping,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  intersection,
  ProtectedRouter,
  SelectBox,
  SelectBoxItem,
} from '@bom-nextgen-keycloak/web/shared/ui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

const mapRoleToSelectBoxItem = (
  data: RoleRepresentationDto[]
): SelectBoxItem[] => {
  return data.map((val) => ({ id: val.id || '', name: val.name || '' }));
};

const RoleMapping: FC = () => {
  const params = useParams();
  const resource = useResource();
  const { setAlertMessage } = useAlertMessage();
  const queryClient = useQueryClient();
  const [checked, setChecked] = useState<SelectBoxItem[]>([]);
  const { isEditRoleMappingTab } = useSelector(selectPermissionUserDetail);
  const userId = params.id || '';
  const {
    isLoading,
    data: results = { roleMapping: [], roleAvailable: [], roleComposite: [] },
  } = useQuery(
    [QUERY_KEY.USER_ROLE_MAPPING, userId],
    () =>
      Promise.all([
        fetchUserRoleMapping(userId, resource.clientId),
        fetchUserRoleAvailable(userId, resource.clientId),
        fetchUserRoleComposite(userId, resource.clientId),
      ]).then(([roleMapping, roleAvailable, roleComposite]) => ({
        roleMapping: mapRoleToSelectBoxItem(roleMapping) || [],
        roleAvailable: mapRoleToSelectBoxItem(roleAvailable) || [],
        roleComposite: mapRoleToSelectBoxItem(roleComposite) || [],
      })),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get list group and group';
        setAlertMessage({
          message,
          typeStatusMessage: 'error',
          statusCode: error.response?.status,
        });
      },
    }
  );

  const roleAvailableChecked = intersection(checked, results.roleAvailable);
  const roleMappingChecked = intersection(checked, results.roleMapping);

  const addUserRoleMutation = useMutation(addUserRoleMapping, {
    onSuccess: (data) => {
      setAlertMessage({
        message: 'Assigned role(s) to a user',
        typeStatusMessage: 'success',
      });
      queryClient.invalidateQueries([QUERY_KEY.USER_ROLE_MAPPING, userId]);
    },
    onSettled: async () => {
      setChecked([]);
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot add user role mapping';
      setAlertMessage({
        message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const updateUserRoleMutation = useMutation(updateUserRoleMapping, {
    onSuccess: (data) => {
      setAlertMessage({
        message: 'Unassigned role(s) from a user',
        typeStatusMessage: 'success',
      });
      queryClient.invalidateQueries([QUERY_KEY.USER_ROLE_MAPPING, userId]);
    },
    onSettled: async () => {
      setChecked([]);
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot update user role mapping';
      setAlertMessage({
        message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const handleAddRoleMapping = () => {
    if (checked.length) {
      addUserRoleMutation.mutate({
        userId,
        clientId: resource.clientId,
        payload: { roles: checked },
      });
    }
  };

  const handleRemoveRoleMapping = () => {
    if (checked.length) {
      updateUserRoleMutation.mutate({
        userId,
        clientId: resource.clientId,
        payload: { roles: checked },
      });
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>
        <SelectBox
          title="Available roles"
          isloading={
            isLoading ||
            addUserRoleMutation.isLoading ||
            updateUserRoleMutation.isLoading
          }
          items={results.roleAvailable}
          checked={checked}
          handleChecked={(selected: SelectBoxItem[]) => setChecked(selected)}
        />
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            disabled={
              roleAvailableChecked.length === 0 || !isEditRoleMappingTab
            }
            onClick={handleAddRoleMapping}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            disabled={roleMappingChecked.length === 0 || !isEditRoleMappingTab}
            onClick={handleRemoveRoleMapping}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <SelectBox
          title="Assigned roles"
          isloading={
            isLoading ||
            addUserRoleMutation.isLoading ||
            updateUserRoleMutation.isLoading
          }
          items={results.roleMapping}
          checked={checked}
          handleChecked={(selected: SelectBoxItem[]) => setChecked(selected)}
        />
      </Grid>
      <Grid item>
        <SelectBox
          title="Effective roles"
          isloading={
            isLoading ||
            addUserRoleMutation.isLoading ||
            updateUserRoleMutation.isLoading
          }
          isShowOnly={true}
          items={results.roleComposite}
          checked={checked}
        />
      </Grid>
    </Grid>
  );
};

const WrappedRoleMappingPage: FC = () => {
  const { canViewRoleMappingTab, canViewPage } = useSelector(
    selectPermissionUserDetail
  );

  return (
    <ProtectedRouter
      isEditPage
      feature={FeaturePage.USER}
      canView={canViewRoleMappingTab && canViewPage}
      navigatePath="/users"
    >
      <RoleMapping />
    </ProtectedRouter>
  );
};

export { WrappedRoleMappingPage };
