import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  width: 100%;
`;

export const Title = styled('h1')`
  color: #454545;
  font-weight: normal;
  font-size: 1rem;
  line-height: 24px;
`;

export const CustomSearchIcon = styled(SearchIcon)`
  font-size: 3rem;
`;
