import { styled } from '@mui/material/styles';

export const ActionWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    margin-right: 1rem;
  }
`;
