import {
  RoleRepresentationDto,
  RoleUpdate,
} from '@bom-nextgen-keycloak/models';
import { axiosInstance } from '@bom-nextgen-keycloak/web/shared/util';
import { AxiosResponse } from 'axios';

export type UpdateRoleParams = {
  roleId: string;
  payload: RoleUpdate;
};

export const fetchRoleById = (roleId: string) =>
  axiosInstance
    .get(`/roles/${roleId}`)
    .then(({ data }: AxiosResponse<RoleRepresentationDto>) => data);

export const updateRoleById = ({ roleId, payload }: UpdateRoleParams) =>
  axiosInstance.put<AxiosResponse<void>>(`/roles/${roleId}`, payload);
