import { ResourceDto } from '@bom-nextgen-keycloak/models';
import {
  FeaturePage,
  GroupEvent,
  PermissionGroupDetailPage,
  PermissionRoleDetailPage,
  PermissionRolePage,
  RoleEvent,
  ScopeApplication,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { getValueAttributeKeycloak } from '@bom-nextgen-keycloak/web/shared/util';
import { matchPermission, ResourceType } from '../reducer/permission.reducer';

const getComponentRole = (resources: ResourceDto[]): ResourceType[] => {
  return resources
    .filter(
      (val) =>
        val.type === 'component' &&
        getValueAttributeKeycloak(val.attributes, 'feature').toLowerCase() ===
          FeaturePage.ROLE.toLowerCase()
    )
    .map((val) => ({
      ...val,
      scopes: val.scopes?.map((scope) => scope.name) || [],
    }));
};

export const mapRolePermission = (
  resources: ResourceDto[]
): PermissionRolePage => {
  const roleComponentList: ResourceType[] = getComponentRole(resources);

  return {
    canViewAdd: matchPermission(
      roleComponentList,
      RoleEvent.ADD,
      ScopeApplication.CREATE
    ),
    canViewDelete: matchPermission(
      roleComponentList,
      RoleEvent.DELETE,
      ScopeApplication.DELETE
    ),
  };
};

export const mapRolePermissionDetail = (
  resources: ResourceDto[]
): PermissionRoleDetailPage => {
  const roleComponentList: ResourceType[] = getComponentRole(resources);

  const roleDetailComponentList: ResourceType[] = resources
    .filter(
      (val) =>
        val.type === 'subset' &&
        getValueAttributeKeycloak(val.attributes, 'feature').toLowerCase() ===
          FeaturePage.ROLE.toLowerCase()
    )
    .map((val) => ({
      ...val,
      scopes: val.scopes?.map((scope) => scope.name) || [],
    }));

  return {
    canViewPage: matchPermission(
      roleDetailComponentList,
      RoleEvent.DETAIL,
      ScopeApplication.VIEW
    ),
    canViewGeneralTab: matchPermission(
      roleComponentList,
      RoleEvent.GENERAL,
      ScopeApplication.VIEW
    ),
    canViewAttributesTab: matchPermission(
      roleComponentList,
      RoleEvent.ATTRIBUTE,
      ScopeApplication.VIEW
    ),
    canViewUserTab: matchPermission(
      roleComponentList,
      RoleEvent.USER,
      ScopeApplication.VIEW
    ),
    isEditAttributesTab: matchPermission(
      roleComponentList,
      RoleEvent.ATTRIBUTE,
      ScopeApplication.EDIT
    ),
    isEditGeneralTab: matchPermission(
      roleComponentList,
      RoleEvent.GENERAL,
      ScopeApplication.EDIT
    ),
  };
};
