import { ButtonRounded } from '@bom-nextgen-keycloak/web/shared/ui';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { FC } from 'react';
import * as yup from 'yup';

export type DeleteDialogProps = {
  title: string;
  open: boolean;
  loading: boolean;
  username: string;
  description?: string;
  onClose: () => void;
  onDelete: () => void;
};

export const StyledDialog = styled(Dialog)`
  & div.MuiDialog-paper {
    width: 30rem;
    max-width: 30rem;
  }
`;

const UserDeleteDialog: FC<DeleteDialogProps> = ({
  title,
  onClose,
  open,
  onDelete,
  loading,
  username,
}) => {
  const validationSchema = yup.lazy((values) => {
    return yup.object({
      username: yup
        .string()
        .matches(new RegExp(`${username}`, 'i'), 'Username mismatch')
        .required('Username is required'),
    });
  });

  const formik = useFormik({
    initialValues: {
      username: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
    },
  });

  const handleClickDelete = () => {
    formik.resetForm();
    onDelete();
  };

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <StyledDialog
        onClose={handleClose}
        aria-labelledby="dialog-title"
        open={open}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            dangerouslySetInnerHTML={{
              __html: `
              You are about to permanently delete <b>${username}</b>, its relationships, all of its data, and all project associations. <b>This operation cannot be undone.</b>
           `,
            }}
            id="alert-dialog-description"
          ></DialogContentText>
          <Box sx={{ mt: 2 }}>
            <Typography sx={{ mb: 2 }} variant="body1">
              Please type {username} to confirm.
            </Typography>

            <TextField
              id="username"
              name="username"
              type="text"
              required
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.username}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <ButtonRounded onClick={handleClose} color="primary">
            Cancel
          </ButtonRounded>
          <ButtonRounded
            onClick={handleClickDelete}
            color="error"
            autoFocus
            loading={loading}
            disabled={!(formik.isValid && formik.dirty)}
            data-test-id="btn-delete"
          >
            delete
          </ButtonRounded>
        </DialogActions>
      </StyledDialog>
    </form>
  );
};

export { UserDeleteDialog };
