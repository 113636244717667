import ListItem from '@mui/material/ListItem';
import React, { forwardRef } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
export interface AppMenuItemComponentProps {
  className?: string;
  link?: string | null;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const AppMenuItemComponent: React.FC<AppMenuItemComponentProps> = (props) => {
  const { className, onClick, link, children } = props;

  if (!link || typeof link !== 'string') {
    return (
      <ListItem
        button
        className={className}
        children={children}
        onClick={onClick}
      />
    );
  }
  return (
    <ListItem
      button
      className={className}
      children={children}
      component={forwardRef((propsNavLink: NavLinkProps, ref: any) => (
        <NavLink {...propsNavLink} />
      ))}
      to={link}
    />
  );
};

export default AppMenuItemComponent;
