import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FieldHookConfig, useField } from 'formik';
import { FC, useState } from 'react';

const PasswordTextField: FC<TextFieldProps & FieldHookConfig<string>> = (
  props
) => {
  const [showPassword, setShowPassword] = useState(false);
  const [field, meta] = useField(props);

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      data-testid="form.text-field"
      id={props.name}
      inputProps={{
        'data-test-id': `form.text-field.input-${props.name}`,
        ...props.inputProps,
      }}
      fullWidth
      {...field}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      variant="standard"
      {...props}
    />
  );
};

export { PasswordTextField };
