import { IUserSessions } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionUserDetail,
  useAlertMessage,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  FeaturePage,
  fetchUserSession,
  HeadCell,
  OrderTable,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  EnhancedTableHead,
  NotFound,
  ProtectedRouter,
  TableContainer,
  TableRowSkeleton,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  getComparator,
  stableSort,
} from '@bom-nextgen-keycloak/web/shared/util';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

const headCells: HeadCell<keyof IUserSessions>[] = [
  {
    id: 'ipAddress',
    sortKey: 'ipAddress',
    numeric: false,
    disablePadding: false,
    label: 'IP address',
  },
  {
    id: 'start',
    sortKey: 'start',
    numeric: false,
    disablePadding: false,
    label: 'Started on',
  },
  {
    id: 'lastAccess',
    sortKey: 'lastAccess',
    numeric: false,
    disablePadding: false,
    label: 'Last access on',
  },
  {
    id: 'clients',
    sortKey: 'clients',
    numeric: false,
    disablePadding: false,
    label: 'App (Client ID)',
  },
];
const Session: FC = () => {
  const params = useParams();
  const [order, setOrder] = useState<OrderTable>('desc');
  const [orderBy, setOrderBy] = useState<keyof IUserSessions>('lastAccess');
  const { setAlertMessage } = useAlertMessage();
  const userId = params?.id || '';

  const { isLoading, data: userSession = [] } = useQuery(
    [QUERY_KEY.USER_SESSION, userId],
    () => fetchUserSession(userId),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get user session';
        setAlertMessage({
          message,
          typeStatusMessage: 'error',
          statusCode: error.response?.status,
        });
      },
    }
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IUserSessions
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Paper>
      <TableContainer>
        <Table
          aria-label="user session table"
          aria-labelledby="tableTitle"
          stickyHeader
        >
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            <TableRowSkeleton loading={isLoading} cellLength={headCells.length}>
              {userSession.length ? (
                stableSort<IUserSessions>(
                  userSession,
                  getComparator(order, orderBy)
                ).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.ipAddress}</TableCell>
                    <TableCell>
                      {format(new Date(row.start), 'MM/dd/yyyy HH:mm:ss')}
                    </TableCell>
                    <TableCell>
                      {format(new Date(row.lastAccess), 'MM/dd/yyyy HH:mm:ss')}
                    </TableCell>
                    <TableCell>{Object.values(row.clients)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <NotFound />
                  </TableCell>
                </TableRow>
              )}
            </TableRowSkeleton>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const WrappedSessionPage: FC = () => {
  const { canViewSessionTab, canViewPage } = useSelector(
    selectPermissionUserDetail
  );
  return (
    <ProtectedRouter
      isEditPage
      feature={FeaturePage.USER}
      canView={canViewSessionTab && canViewPage}
      navigatePath="/users"
    >
      <Session />
    </ProtectedRouter>
  );
};

export { WrappedSessionPage };
