import { selectPermissionRoleDetail } from '@bom-nextgen-keycloak/web/core';
import { TextField } from '@bom-nextgen-keycloak/web/shared/ui';
import {
  NO_BEGINNING_SPACE_REGEX,
  MESSAGE_NO_BEGINNING_SPACE_REGEX,
} from '@bom-nextgen-keycloak/web/shared/util';
import Box from '@mui/material/Box';
import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

export const validationSchema = yup.object({
  name: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required('Name is required'),
});

const RoleForm: FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const { isEditGeneralTab } = useSelector(selectPermissionRoleDetail);
  return (
    <Fragment>
      <Box sx={{ mb: 3 }}>
        <TextField
          name="name"
          label="Name"
          disabled={isEdit && !isEditGeneralTab}
          required
        />
      </Box>
      <Box sx={{ mb: 3 }}>
        <TextField
          name="description"
          label="Description"
          disabled={isEdit && !isEditGeneralTab}
          multiline
        />
      </Box>
    </Fragment>
  );
};

export { RoleForm };
