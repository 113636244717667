import {
  selectPermissionResourceDetail,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchScope,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  AutoCompleteOption,
  TextField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  NO_BEGINNING_SPACE_REGEX,
  MESSAGE_NO_BEGINNING_SPACE_REGEX,
  useDebounce,
} from '@bom-nextgen-keycloak/web/shared/util';
import { CircularProgress, TextField as MatTextField } from '@mui/material';
import Autocomplete, {
  AutocompleteInputChangeReason,
} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { AxiosError } from 'axios';
import { FieldMetaProps } from 'formik';
import { FC, Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

export const validationSchema = yup.object({
  name: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required('Name is required'),
  displayName: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required('Display name is required'),
});

type ResourceFormProps = {
  scopes?: AutoCompleteOption[];
  isEdit?: boolean;
  setFieldValue: (field: string, value: any) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
  getFieldMeta: <Value>(name: string) => FieldMetaProps<Value>;
};

const ResourceForm: FC<ResourceFormProps> = ({
  setFieldValue,
  setFieldTouched,
  getFieldMeta,
  scopes,
  isEdit = false,
}) => {
  const resource = useResource();
  const { setAlertMessage } = useAlertMessage();
  const [scopeName, setScopeName] = useState('');
  const debouncedQueryTerm = useDebounce(scopeName, 700);
  const { isEditGeneralTab } = useSelector(selectPermissionResourceDetail);

  const { isLoading, data: optionScopes = [] } = useQuery(
    [QUERY_KEY.SCOPE_LIST, resource.clientId, debouncedQueryTerm],
    async () => {
      const scopesList = await fetchScope(resource.clientId, {
        max: 10,
        first: 0,
        name: scopeName,
      });
      return scopesList.items.map((scope) => ({
        label: scope.name,
        value: scope,
      }));
    },
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message = error.response?.data.message || 'Cannot get list scope';
        setAlertMessage({
          message,
          typeStatusMessage: 'error',
          statusCode: error.response?.status,
        });
      },
    }
  );

  const onInputChange = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setScopeName(value);
  };

  return (
    <Fragment>
      <Box sx={{ mb: 2 }}>
        <TextField
          name="name"
          label="Name"
          disabled={isEdit && !isEditGeneralTab}
          required
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <TextField
          name="displayName"
          disabled={isEdit && !isEditGeneralTab}
          label="Display name"
          required
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <TextField
          name="type"
          label="Type"
          disabled={isEdit && !isEditGeneralTab}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <Autocomplete
          id="scopes"
          multiple
          filterSelectedOptions
          options={optionScopes}
          getOptionLabel={(option) => option.label}
          value={scopes}
          limitTags={3}
          onChange={(e, value) => {
            setFieldValue('scopes', value);
          }}
          onInputChange={onInputChange}
          loading={isLoading}
          disabled={isEdit && !isEditGeneralTab}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          renderInput={(params) => (
            <MatTextField
              {...params}
              name="scopes"
              label="Scopes"
              fullWidth
              variant="standard"
              placeholder="Enter scope name"
              InputLabelProps={{
                shrink: true,
              }}
              onBlur={() => {
                setFieldTouched('scopes');
              }}
              error={
                getFieldMeta('scopes').touched && !!getFieldMeta('scopes').error
              }
              helperText={
                getFieldMeta('scopes').touched && getFieldMeta('scopes').error
              }
              disabled={isEdit && !isEditGeneralTab}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
        />
      </Box>
    </Fragment>
  );
};

export { ResourceForm };
