import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)`
  & div.MuiDialog-paper {
    width: 30rem;
    max-width: 30rem;
  }
`;

export const BoxActionValidate = styled(DialogActions)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
