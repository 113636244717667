import {
  GroupListMemberQueryDto,
  IUserRepresentation,
} from '@bom-nextgen-keycloak/models';
import { useAlertMessage, useResource } from '@bom-nextgen-keycloak/web/core';
import {
  deleteUser,
  ErrorMessage,
  fetchgroupListMembers,
  joinGroupUser,
  QUERY_KEY,
  removeUserGroup,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { EmitSearchInput } from '@bom-nextgen-keycloak/web/shared/ui';
import { useDebounce } from '@bom-nextgen-keycloak/web/shared/util';
import { SelectChangeEvent } from '@mui/material/Select';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const useUserPage = () => {
  const resource = useResource();
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openLeaveDialog, setOpenLeaveDialog] = useState(false);
  const [openJoinDialog, setOpenJoinDialog] = useState(false);
  const [openUserEmailDialog, setOpenUserEmailDialog] = useState(false);
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [params, setParams] = useState<GroupListMemberQueryDto>({
    id: resource.groupId,
    first: 0,
    max: 200,
  });
  const [user, setUser] = useState<IUserRepresentation | null>(null);
  const [userId, setUserId] = useState('');

  const [email, setEmail] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const queryClient = useQueryClient();
  const { setAlertMessage } = useAlertMessage();
  const debouncedQueryTerm = useDebounce(searchQuery, 700);

  const { isLoading, data: userList } = useQuery(
    [QUERY_KEY.GROUP_MEMBERS, resource.groupId, params, debouncedQueryTerm],
    () =>
      fetchgroupListMembers(resource.groupId, {
        ...params,
        search: searchQuery,
        id: resource.groupId,
      }),
    {
      keepPreviousData: true,
      staleTime: 5000,
      onError: (error: AxiosError<ErrorMessage>) => {
        const message = error.response?.data.message || 'Cannot get group list';
        setAlertMessage({ message, typeStatusMessage: 'error' });
      },
    }
  );

  const joinGroupUserMutation = useMutation(joinGroupUser, {
    onSuccess: (data) => {
      setOpenJoinDialog(false);
      setAlertMessage({
        message: 'User has been added to a product',
        typeStatusMessage: 'success',
      });
      setParams((previous) => ({ ...previous, first: 0 }));
      setSearchQuery('');
      queryClient.invalidateQueries([
        QUERY_KEY.GROUP_MEMBERS,
        resource.groupId,
        params,
        debouncedQueryTerm,
      ]);
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot add user to a product';
      setAlertMessage({
        message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const removeUserGroupMutation = useMutation(deleteUser, {
    onSuccess: (data) => {
      setAlertMessage({
        message: 'User has been deleted',
        typeStatusMessage: 'success',
      });
      setOpenDeleteDialog(false);
      setParams((previous) => ({ ...previous, first: 0 }));
      setSearchQuery('');
      queryClient.invalidateQueries([
        QUERY_KEY.GROUP_MEMBERS,
        resource.groupId,
        params,
        debouncedQueryTerm,
      ]);
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot remove user from a group';
      setAlertMessage({
        message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const leaveUserMutation = useMutation(removeUserGroup, {
    onSuccess: (data) => {
      setAlertMessage({
        message: 'User has been leaved',
        typeStatusMessage: 'success',
      });
      setOpenLeaveDialog(false);
      setParams((previous) => ({ ...previous, first: 0 }));
      setSearchQuery('');
      queryClient.invalidateQueries([
        QUERY_KEY.GROUP_MEMBERS,
        resource.groupId,
        params,
        debouncedQueryTerm,
      ]);
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message ?? '';
      setAlertMessage({
        message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const handleClickDelete = (selected: IUserRepresentation) => {
    if (selected) {
      setOpenDeleteDialog(true);
      setUser(selected);
    }
  };

  const handleClickLeave = (selected: IUserRepresentation) => {
    if (selected) {
      setOpenLeaveDialog(true);
      setUser(selected);
    }
  };

  const handleValidEmailAndGroup = (id: string | undefined) => {
    if (id) {
      setOpenJoinDialog(true);
      setUserId(id);
    }
  };

  const handleChangeSearch = (value: EmitSearchInput) => {
    setSearchQuery(value.searchValue);
    setParams((previous) => ({
      ...previous,
      // FIXME: Find a better solution (search from the first page)
      // first: 0,
    }));
  };

  const handleDeleteUser = useCallback(() => {
    if (user) {
      removeUserGroupMutation.mutate({
        userId: user.id,
        groupId: resource.groupId,
      });
    }
  }, [removeUserGroupMutation, resource.groupId, user]);

  const handleLeaveUser = useCallback(() => {
    if (user) {
      leaveUserMutation.mutate({
        userId: user.id,
        groupId: resource.groupId,
        clientId: resource.clientId,
      });
    }
  }, [leaveUserMutation, resource.groupId, user]);

  const handleCloseEmailDialog = (emailName: string) => {
    if (emailName) {
      setEmail(emailName);
      setOpen(true);
    }
    setOpenUserEmailDialog(false);
  };

  const handleonJoin = useCallback(() => {
    if (userId) {
      joinGroupUserMutation.mutate({
        userId: userId,
        groupIds: [resource.groupId],
      });
      setOpenUserEmailDialog(false);
      clearState();
    }
  }, [joinGroupUserMutation, resource.groupId, userId]);

  const clearState = () => {
    setEmail('');
    setUser(null);
    setOpenJoinDialog(false);
    setOpen(false);
    setOpenDeleteDialog(false);
    setOpenLeaveDialog(false);
  };

  const handleSave = useCallback(() => {
    setOpen((previous) => !previous);
    setAlertMessage({
      message: 'User has been created',
      typeStatusMessage: 'success',
    });
    setParams((previous) => ({ ...previous, first: 0 }));
    setSearchQuery('');
    queryClient.invalidateQueries([
      QUERY_KEY.GROUP_MEMBERS,
      resource.groupId,
      params,
      debouncedQueryTerm,
    ]);
  }, [
    debouncedQueryTerm,
    params,
    queryClient,
    resource.groupId,
    setAlertMessage,
  ]);

  const handleChangePage = (event: SelectChangeEvent<unknown>) => {
    const selectedPage = event.target.value as number;
    setParams((previous) => ({
      ...previous,
      first: selectedPage,
    }));
  };

  return {
    user,
    resource,
    userList,
    isLoading,
    params,
    email,
    open,
    openDeleteDialog,
    openLeaveDialog,
    openJoinDialog,
    openUserEmailDialog,
    setOpenUserEmailDialog,
    openExportDialog,
    setOpenExportDialog,
    joinGroupUserMutation,
    removeUserGroupMutation,
    leaveUserMutation,
    handleLeaveUser,
    handleSave,
    handleChangePage,
    handleonJoin,
    handleCloseEmailDialog,
    handleDeleteUser,
    handleChangeSearch,
    handleValidEmailAndGroup,
    handleClickDelete,
    handleClickLeave,
    clearState,
  };
};
