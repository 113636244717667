import {
  IResourceClient,
  ResourceClientQuery,
} from '@bom-nextgen-keycloak/models';
import {
  selectPermissionResource,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  deleteResource,
  ErrorMessage,
  fetchResources,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  DeleteDialog,
  EmitSearchInput,
  SearchInput,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { SelectChangeEvent } from '@mui/material/Select';
import { AxiosError } from 'axios';
import { FC, Fragment, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { ResourceFormDialog } from './components/ResourceFormDialog/ResourceFormDialog';
import { ResourceTable } from './components/ResourceTable';
import {
  ActionWrapper,
  ButtonAddResource,
  WrapperSearchFilter,
} from './Resource.styled';

const Resource: FC = () => {
  const queryClient = useQueryClient();
  const resource = useResource();
  const { setAlertMessage } = useAlertMessage();
  const [params, setParams] = useState<ResourceClientQuery>({
    first: 0,
    max: 100,
    name: '',
    type: '',
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSaveResourceDialog, setOpenSaveResourceDialog] = useState(false);
  const [resourceClient, setResourceClient] = useState<IResourceClient>();
  const { canViewAdd } = useSelector(selectPermissionResource);

  const { isLoading, data: resourceList = { items: [], totalRecord: 0 } } =
    useQuery(
      [QUERY_KEY.RESOURCE_LIST, resource.clientId, params],
      () => fetchResources(resource.clientId, params),
      {
        onError: (error: AxiosError<ErrorMessage>) => {
          const message =
            error.response?.data.message || 'Cannot get client role list';
          setAlertMessage({
            message,
            typeStatusMessage: 'error',
            statusCode: error.response?.status,
          });
        },
      }
    );

  const removeResourceMutation = useMutation(deleteResource, {
    onSuccess: () => {
      setAlertMessage({
        message: 'Resource has been deleted',
        typeStatusMessage: 'success',
      });
      setOpenDeleteDialog(false);
      setParams((previous) => ({ ...previous, first: 0, name: '', type: '' }));
      queryClient.invalidateQueries([
        QUERY_KEY.RESOURCE_LIST,
        resource.clientId,
        params,
      ]);
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot remove resource';
      setAlertMessage({
        message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const handleShowDialogDelete = (selectResourceClient: IResourceClient) => {
    if (selectResourceClient) {
      setOpenDeleteDialog(true);
      setResourceClient(selectResourceClient);
    }
  };

  const handleChangePage = (event: SelectChangeEvent<unknown>) => {
    const selectedPage = event.target.value as number;
    setParams((previous) => ({
      ...previous,
      first: selectedPage,
    }));
  };

  const handleChangeSearchName = (value: EmitSearchInput) => {
    setParams((previous) => ({
      ...previous,
      first: 0,
      name: value.searchValue,
    }));
  };

  const handleChangeSearchType = (value: EmitSearchInput) => {
    setParams((previous) => ({
      ...previous,
      first: 0,
      type: value.searchValue,
    }));
  };

  const handleSave = () => {
    setParams((previous) => ({
      ...previous,
      first: 0,
      name: '',
      type: '',
    }));
    setAlertMessage({
      message: 'Resource has been created',
      typeStatusMessage: 'success',
    });
    setOpenSaveResourceDialog(false);
    queryClient.invalidateQueries([
      QUERY_KEY.RESOURCE_LIST,
      resource.clientId,
      params,
    ]);
  };

  const handleDeleteResource = () => {
    if (resourceClient) {
      removeResourceMutation.mutate({
        clientId: resource.clientId,
        resourceId: resourceClient._id,
      });
    }
  };

  return (
    <Fragment>
      <ActionWrapper>
        <WrapperSearchFilter>
          <SearchInput
            titleLabel="Name"
            placeholder="Search resources by name"
            width={250}
            onCliekSearch={handleChangeSearchName}
            onKeyDown={handleChangeSearchName}
            onChange={handleChangeSearchName}
          />
          <SearchInput
            titleLabel="Type"
            placeholder="Search resources by type"
            width={250}
            onCliekSearch={handleChangeSearchType}
            onKeyDown={handleChangeSearchType}
            onChange={handleChangeSearchType}
          />
        </WrapperSearchFilter>
        {canViewAdd && (
          <ButtonAddResource
            variant="contained"
            color="primary"
            data-test-id="btn-add-resource"
            onClick={() => setOpenSaveResourceDialog(true)}
          >
            Add Resource
          </ButtonAddResource>
        )}
      </ActionWrapper>
      <ResourceTable
        data={resourceList?.items || []}
        totalRecord={resourceList?.totalRecord || 0}
        loading={isLoading}
        selectedPage={params.first}
        onClickDelate={handleShowDialogDelete}
        handleChange={handleChangePage}
      />

      <ResourceFormDialog
        open={openSaveResourceDialog}
        onClose={() => setOpenSaveResourceDialog(false)}
        onSave={handleSave}
      />

      <DeleteDialog
        title="Delete resource?"
        description={`You are about to permanently delete <b>${resourceClient?.name}</b>, its relationships, and all of its data. <b>This operation cannot be undone.</b>
        `}
        open={openDeleteDialog}
        loading={removeResourceMutation.isLoading}
        onClose={() => setOpenDeleteDialog(false)}
        onDelete={handleDeleteResource}
      />
    </Fragment>
  );
};

export { Resource };
