import {
  IScopeList,
  OptionsDto,
  ScopeCreateDto,
  ScopeDto,
  ScopeQuery,
  ScopeUpdateDto,
} from '@bom-nextgen-keycloak/models';
import { axiosInstance } from '@bom-nextgen-keycloak/web/shared/util';
import { AxiosResponse } from 'axios';

export type ScopeParams = {
  clientId: string;
  payload: ScopeCreateDto;
};

export type DeleteScopeParams = {
  clientId: string;
  scopeId: string;
};

export type UpdateScopeParams = {
  clientId: string;
  payload: ScopeUpdateDto;
};

export const fetchScope = (clientId: string, params: ScopeQuery) =>
  axiosInstance
    .get(`/clients/${clientId}/scopes`, { params: { ...params } })
    .then(({ data }: AxiosResponse<IScopeList>) => data);

export const createScope = ({ clientId, payload }: ScopeParams) =>
  axiosInstance
    .post<AxiosResponse<ScopeDto>>(`/clients/${clientId}/scopes`, payload)
    .then((data) => data);

export const deleteScope = ({ clientId, scopeId }: DeleteScopeParams) =>
  axiosInstance.delete<AxiosResponse<void>>(
    `/clients/${clientId}/scopes/${scopeId}`
  );

export const fetchScopeById = (clientId: string, scopeId: string) =>
  axiosInstance
    .get(`/clients/${clientId}/scopes/${scopeId}`)
    .then(({ data }: AxiosResponse<ScopeDto>) => data);

export const updateScopeById = ({ clientId, payload }: UpdateScopeParams) =>
  axiosInstance.put<AxiosResponse<void>>(
    `/clients/${clientId}/scopes`,
    payload
  );

export const fetchOptionScopeByResource = (
  clientId: string,
  resourceId: string
) =>
  axiosInstance
    .get(`/clients/${clientId}/resources/${resourceId}/options`)
    .then(({ data }: AxiosResponse<OptionsDto[]>) => data);
