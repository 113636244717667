import { selectPermissionGroupDetail } from '@bom-nextgen-keycloak/web/core';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  BreadcrumbsLink,
  ProtectedRouter,
  useTabsWithRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet, useParams } from 'react-router-dom';
import { StyledAppBar } from './GroupDetail.styled';
import { useGroupDetail } from './hooks/useGroupDetail';

const GroupDetail: FC<{ groupId: string }> = ({ groupId }) => {
  const { groupDetail, isSubscription } = useGroupDetail(groupId);
  const {
    canViewAttributesTab,
    canViewGeneralTab,
    canViewMemberTab,
    canViewRoleMappingTab,
  } = useSelector(selectPermissionGroupDetail);
  const tabValue = useTabsWithRouter(
    [
      `/groups/${groupId}`,
      `/groups/${groupId}/attributes`,
      `/groups/${groupId}/members`,
      `/groups/${groupId}/role-mappings`,
    ],
    `/groups/${groupId}`
  );

  return (
    <Fragment>
      <BreadcrumbsLink
        name="Groups"
        pathName="/groups"
        detailName={groupDetail.name}
      />
      <StyledAppBar position="static">
        <Tabs
          value={tabValue}
          aria-label="simple tabs example"
          textColor="inherit"
          variant="fullWidth"
          indicatorColor="secondary"
        >
          {canViewGeneralTab && (
            <Tab
              label="Details"
              component={Link}
              value={`/groups/${groupId}`}
              to={`/groups/${groupId}`}
              icon={<SettingsIcon />}
            />
          )}
          {canViewAttributesTab && (
            <Tab
              label="Attributes"
              component={Link}
              value={`/groups/${groupId}/attributes`}
              to={`/groups/${groupId}/attributes`}
              icon={<AssignmentIndIcon />}
            />
          )}
          {canViewMemberTab && (
            <Tab
              label="Members in Group"
              component={Link}
              value={`/groups/${groupId}/members`}
              to={`/groups/${groupId}/members`}
              icon={<PeopleIcon />}
            />
          )}

          {!isSubscription &&
            groupDetail.level === 4 &&
            canViewRoleMappingTab && (
              <Tab
                label="Role Mappings"
                component={Link}
                value={`/groups/${groupId}/role-mappings`}
                to={`/groups/${groupId}/role-mappings`}
                icon={<AssignmentReturnIcon />}
              />
            )}
        </Tabs>
      </StyledAppBar>

      <Box sx={{ mt: 3 }}>
        <Outlet />
      </Box>
    </Fragment>
  );
};

const WrappedGroupDetailPage: FC = () => {
  const params = useParams();
  const { canViewPage } = useSelector(selectPermissionGroupDetail);
  return (
    <ProtectedRouter
      isEditPage
      feature={FeaturePage.GROUP}
      canView={canViewPage}
      navigatePath="/groups"
    >
      <GroupDetail groupId={params?.id || ''} />
    </ProtectedRouter>
  );
};

export { WrappedGroupDetailPage };
