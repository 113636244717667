export enum QUERY_KEY {
  USER = 'USER',
  USER_DETAIL = 'USER_DETAIL',
  PERMISSION = 'PERMISSION',
  USER_PROFILE = 'USER_PROFILE',
  PERMISSION_AND_USER = 'PERMISSION_AND_USER',
  GROUP_DETAIL = 'GROUP_DETAIL',
  GROUP = 'GROUP',
  GROUP_MEMBERS = 'GROUP_MEMBERS',
  USER_MEMBERS = 'USER_MEMBERS',
  USER_GROUP_DETAIL = 'USER_GROUP_DETAIL',
  USER_GROUP_MEMBERS = 'USER_GROUP_MEMBERS',
  USER_GROUP_COMPANY = 'USER_GROUP_COMPANY',
  CLIENT_ROLE_LIST = 'CLIENT_ROLE_LIST',
  CLIENT_ROLE_DETAIL = 'CLIENT_ROLE_DETAIL',
  CLIENT_USER_IN_ROLE = 'CLIENT_USER_IN_ROLE',
  CLIENT_LIST = 'CLIENT_LIST',
  GROUP_ROLE_MAPPING = 'GROUP_ROLE_MAPPING',
  USER_ROLE_MAPPING = 'USER_ROLE_MAPPING',
  USER_SESSION = 'USER_SESSION',
  SCOPE_LIST = 'SCOPE_LIST',
  SCOPE_DETAIL = 'SCOPE_DETAIL',
  RESOURCE_LIST = 'RESOURCE_LIST',
  RESOURCE_DETAIL = 'RESOURCE_DETAIL',
  POLICY_LIST = 'POLICY_LIST',
  POLICY_DETAIL = 'POLICY_DETAIL',
  POLICY_PROVIDER = 'POLICY_PROVIDER',
  PERMISSION_LIST = 'PERMISSION_LIST',
  PERMISSION_DETAIL = 'PERMISSION_DETAIL',
  PERMISSION_OPTIONS_SCOPE = 'PERMISSION_OPTIONS_SCOPE',
  COMPANY_LIST = 'COMANY_LIST',
  EVENT_LIST = 'EVENT_LIST',
  PERMISSION_EVENT_LIST = 'PERMISSION_EVENT_LIST',
  EXPORT_USER_LIST = 'EXPORT_USER_LIST',
  USER_IDENTITY_PROVIDER_LIST = 'USER_IDENTITY_PROVIDER_LIST',
  USER_CREDENTIALS = 'USER_CREDENTIALS',
  LOGGING_DETAIL = 'LOGGING_DETAIL',
}
