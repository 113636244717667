import { ResourceDto } from '@bom-nextgen-keycloak/models';
import {
  FeaturePage,
  PermissionDetailPage,
  PermissionEvent,
  PermissionPage,
  ScopeApplication,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { getValueAttributeKeycloak } from '@bom-nextgen-keycloak/web/shared/util';
import { matchPermission, ResourceType } from '../reducer/permission.reducer';

const getComponentPermission = (resources: ResourceDto[]): ResourceType[] => {
  return resources
    .filter(
      (val) =>
        val.type === 'component' &&
        getValueAttributeKeycloak(val.attributes, 'feature').toLowerCase() ===
          FeaturePage.PERMISSION.toLowerCase()
    )
    .map((val) => ({
      ...val,
      scopes: val.scopes?.map((scope) => scope.name) || [],
    }));
};

export const mapPermission = (resources: ResourceDto[]): PermissionPage => {
  const permissionComponentList: ResourceType[] =
    getComponentPermission(resources);
  return {
    canViewAdd: matchPermission(
      permissionComponentList,
      PermissionEvent.ADD,
      ScopeApplication.CREATE
    ),
    canViewDelete: matchPermission(
      permissionComponentList,
      PermissionEvent.DELETE,
      ScopeApplication.DELETE
    ),
  };
};

export const mapPermissionDetail = (
  resources: ResourceDto[]
): PermissionDetailPage => {
  const permissionComponentList: ResourceType[] =
    getComponentPermission(resources);
  const permissionDetailComponentList: ResourceType[] = resources
    .filter(
      (val) =>
        val.type === 'subset' &&
        getValueAttributeKeycloak(val.attributes, 'feature').toLowerCase() ===
          FeaturePage.PERMISSION.toLowerCase()
    )
    .map((val) => ({
      ...val,
      scopes: val.scopes?.map((scope) => scope.name) || [],
    }));

  return {
    canViewPage: matchPermission(
      permissionDetailComponentList,
      PermissionEvent.DETAIL,
      ScopeApplication.VIEW
    ),
    canViewGeneralTab: matchPermission(
      permissionComponentList,
      PermissionEvent.GENERAL,
      ScopeApplication.VIEW
    ),
    isEditGeneralTab: matchPermission(
      permissionComponentList,
      PermissionEvent.GENERAL,
      ScopeApplication.EDIT
    ),
  };
};
