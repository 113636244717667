import { CompanySearchQuery, ICompanyDto } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionUserDetail,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchCompanyList,
  joinGroupUser,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { ButtonRounded, NotFound } from '@bom-nextgen-keycloak/web/shared/ui';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import {
  LoaddingContainer,
  StyledCard,
  StyledCardContent,
  StyledListItem,
} from '../Group.styled';

const GroupCompany: FC<{ userId: string }> = ({ userId }) => {
  const queryClient = useQueryClient();
  const resource = useResource();
  const { setAlertMessage } = useAlertMessage();
  const params: CompanySearchQuery = { max: 10, first: 0, isAll: true };
  const [selectedCompany, setSelectedCompany] = useState<ICompanyDto>();
  const { isEditGroupTab } = useSelector(selectPermissionUserDetail);

  const { isLoading, data: companyList = { items: [], totalRecord: 0 } } =
    useQuery(
      [QUERY_KEY.USER_GROUP_COMPANY, resource.groupId, userId],
      () => {
        return fetchCompanyList(resource.groupId, params).then((data) => ({
          ...data,
          items: data.items.map((val) => ({
            ...val,
            industry: val.attributes.industry ? val.attributes.industry[0] : '',
          })),
        }));
      },
      {
        onError: (error: AxiosError<ErrorMessage>) => {
          const message =
            error.response?.data.message || 'Cannot get company list';
          setAlertMessage({
            message,
            typeStatusMessage: 'error',
            statusCode: error.response?.status,
          });
        },
      }
    );

  const joinGroupUserMutation = useMutation(joinGroupUser, {
    onSuccess: (data) => {
      setAlertMessage({
        message: 'User has been added to the company',
        typeStatusMessage: 'success',
      });
      queryClient.invalidateQueries([
        QUERY_KEY.USER_GROUP_MEMBERS,
        resource.groupId,
        userId,
      ]);
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Unable to add user to the company';
      setAlertMessage({
        message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: ICompanyDto
  ) => {
    setSelectedCompany(data);
  };

  const handleJoinGroup = () => {
    if (selectedCompany) {
      joinGroupUserMutation.mutate({
        userId,
        groupIds: [selectedCompany.id],
      });
    }
  };

  const renderListCompany = () => {
    if (companyList.items.length) {
      return companyList.items.map((item) => (
        <StyledListItem
          key={item.id}
          selected={selectedCompany === item}
          onClick={(event: any) => handleListItemClick(event, item)}
        >
          <ListItemButton>
            <ListItemText primary={item.name} />
          </ListItemButton>
        </StyledListItem>
      ));
    } else {
      return <NotFound />;
    }
  };

  return (
    <StyledCard>
      <CardHeader
        action={
          <ButtonRounded
            aria-label="join"
            variant="contained"
            color="primary"
            onClick={handleJoinGroup}
            loading={joinGroupUserMutation.isLoading}
            disabled={!isEditGroupTab}
          >
            Join
          </ButtonRounded>
        }
        title="Group companies"
      />
      <StyledCardContent>
        {isLoading || joinGroupUserMutation.isLoading ? (
          <LoaddingContainer>
            <CircularProgress />
          </LoaddingContainer>
        ) : (
          <List component="nav" aria-label="main mailbox folders">
            {renderListCompany()}
          </List>
        )}
      </StyledCardContent>
    </StyledCard>
  );
};

export { GroupCompany };
