import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)`
  & div.MuiDialog-paper {
    width: 40rem;
    max-width: 40rem;
  }
`;

export const LoaddingContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
`;
