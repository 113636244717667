import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { FC } from 'react';

export type ButtonRoundedProps = {
  loading?: boolean;
};

const ButtonRounded: FC<LoadingButtonProps> = ({
  children,
  loading = false,
  ...rest
}) => {
  return (
    <LoadingButton loading={loading} {...rest}>
      {children}
    </LoadingButton>
  );
};

export { ButtonRounded };
