import { Link } from 'react-router-dom';
import {
  Wrapper,
  Title,
  SubTitle,
  Row,
  Image,
  Col,
} from './PageNotFound.styled';

const PageNotFound = () => {
  return (
    <Wrapper>
      <Row>
        <Image src="/theme/assets/image-asset.jpeg" />
        <Col>
          <Title>AWWW...DON’T CRY.</Title>
          <SubTitle>
            It's just a 404 Error!
            <br />
            What you’re looking for may have been misplaced in Long Term Memory.
          </SubTitle>
          <Link to="/dashboard">Go to Home page</Link>
        </Col>
      </Row>
    </Wrapper>
  );
};

export { PageNotFound };
