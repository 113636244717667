import { ResourceDto } from '@bom-nextgen-keycloak/models';
import {
  FeaturePage,
  PermissionScopeDetailPage,
  PermissionScopePage,
  RoleEvent,
  ScopeApplication,
  ScopeEvent,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { getValueAttributeKeycloak } from '@bom-nextgen-keycloak/web/shared/util';
import { matchPermission, ResourceType } from '../reducer/permission.reducer';

const getComponentScope = (resources: ResourceDto[]): ResourceType[] => {
  return resources
    .filter(
      (val) =>
        val.type === 'component' &&
        getValueAttributeKeycloak(val.attributes, 'feature').toLowerCase() ===
          FeaturePage.SCOPE.toLowerCase()
    )
    .map((val) => ({
      ...val,
      scopes: val.scopes?.map((scope) => scope.name) || [],
    }));
};

export const mapScopePermission = (
  resources: ResourceDto[]
): PermissionScopePage => {
  const scopeComponentList: ResourceType[] = getComponentScope(resources);
  return {
    canViewAdd: matchPermission(
      scopeComponentList,
      ScopeEvent.ADD,
      ScopeApplication.CREATE
    ),
    canViewDelete: matchPermission(
      scopeComponentList,
      ScopeEvent.DELETE,
      ScopeApplication.DELETE
    ),
  };
};

export const mapScopePermissionDetail = (
  resources: ResourceDto[]
): PermissionScopeDetailPage => {
  const scopeComponentList: ResourceType[] = getComponentScope(resources);

  const scopeDetailComponentList: ResourceType[] = resources
    .filter(
      (val) =>
        val.type === 'subset' &&
        getValueAttributeKeycloak(val.attributes, 'feature').toLowerCase() ===
          FeaturePage.SCOPE.toLowerCase()
    )
    .map((val) => ({
      ...val,
      scopes: val.scopes?.map((scope) => scope.name) || [],
    }));
  return {
    canViewPage: matchPermission(
      scopeDetailComponentList,
      ScopeEvent.DETAIL,
      ScopeApplication.VIEW
    ),
    canViewGeneralTab: matchPermission(
      scopeComponentList,
      ScopeEvent.GENERAL,
      ScopeApplication.VIEW
    ),
    isEditGeneralTab: matchPermission(
      scopeComponentList,
      ScopeEvent.GENERAL,
      ScopeApplication.EDIT
    ),
  };
};
