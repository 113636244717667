import {
  selectPermissionUser,
  selectPermissionUserDetail,
} from '@bom-nextgen-keycloak/web/core';
import { ExportFormDialog } from '@bom-nextgen-keycloak/web/pages/feature-group';
import {
  ButtonRounded,
  DeleteDialog,
  SearchInput,
} from '@bom-nextgen-keycloak/web/shared/ui';
import Box from '@mui/material/Box';
import React, { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { JoinGroupDialog } from './components/JoinGroupDialog';
import { UserDeleteDialog } from './components/UserDeleteDialog';
import { UserEmailDialog } from './components/UserEmailDialog';
import { UserFormDialog } from './components/UserFormDialog';
import { UserTable } from './components/UserTable';
import { useUserPage } from './hooks';
import { ActionWrapper } from './User.styled';

const User: FC = () => {
  const userpage = useUserPage();
  const { canViewExport, canViewAdd } = useSelector(selectPermissionUser);
  const { canViewPage } = useSelector(selectPermissionUserDetail);
  return (
    <Fragment>
      <ActionWrapper>
        <SearchInput
          placeholder="Search users"
          onCliekSearch={userpage.handleChangeSearch}
          onKeyDown={userpage.handleChangeSearch}
          onChange={userpage.handleChangeSearch}
        />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {canViewExport && (
            <ButtonRounded
              sx={{ mr: 2 }}
              variant="contained"
              color="primary"
              onClick={() => userpage.setOpenExportDialog(true)}
            >
              Export
            </ButtonRounded>
          )}

          {canViewAdd && (
            <ButtonRounded
              variant="contained"
              color="primary"
              onClick={() => userpage.setOpenUserEmailDialog(true)}
            >
              Add user
            </ButtonRounded>
          )}
        </Box>
      </ActionWrapper>
      <UserTable
        data={userpage.userList?.items || []}
        loading={userpage.isLoading}
        canViewEditBtn={canViewPage}
        selectedPage={userpage.params.first}
        totalRecord={userpage.userList?.totalRecord || 0}
        handleChange={userpage.handleChangePage}
        onClickDelete={userpage.handleClickDelete}
        onClickLeave={userpage.handleClickLeave}
      />
      <UserEmailDialog
        open={userpage.openUserEmailDialog}
        onClose={userpage.handleCloseEmailDialog}
        onValidEmailAndGroup={userpage.handleValidEmailAndGroup}
      />
      <JoinGroupDialog
        loading={userpage.joinGroupUserMutation.isLoading}
        open={userpage.openJoinDialog}
        onJoin={userpage.handleonJoin}
        onClose={userpage.clearState}
      />
      <UserFormDialog
        open={userpage.open}
        email={userpage.email}
        onClose={userpage.clearState}
        onSaveUser={userpage.handleSave}
      />

      {userpage.openExportDialog && (
        <ExportFormDialog
          open={userpage.openExportDialog}
          onClose={() => userpage.setOpenExportDialog(false)}
          groupId={userpage.resource.groupId || ''}
          titleForm={userpage.resource.resourceName || ''}
        />
      )}

      <UserDeleteDialog
        title="Delete user?"
        open={userpage.openDeleteDialog}
        username={userpage.user?.username || ''}
        loading={userpage.removeUserGroupMutation.isLoading}
        onClose={userpage.clearState}
        onDelete={userpage.handleDeleteUser}
      />

      <DeleteDialog
        title="Remove user?"
        description={`
        You are about to remove <b>${userpage.user?.username}</b> from <b>${userpage.resource.resourceName}</b> (SSO user account will not get permanently deleted).`}
        titleBtn="Remove"
        open={userpage.openLeaveDialog}
        loading={userpage.leaveUserMutation.isLoading}
        onClose={userpage.clearState}
        onDelete={userpage.handleLeaveUser}
      />
    </Fragment>
  );
};

export { User };
