import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0f62fe',
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          margin: 5,
        },
      },
    },
  },
});

export type CustomizedTheme = typeof theme;
