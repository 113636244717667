import { GroupListMemberQueryDto } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionGroupDetail,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import { UserTable } from '@bom-nextgen-keycloak/web/pages/feature-user';
import {
  ErrorMessage,
  FeaturePage,
  fetchgroupListMembers,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { ProtectedRouter } from '@bom-nextgen-keycloak/web/shared/ui';
import { SelectChangeEvent } from '@mui/material/Select';
import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

const GroupMemeber: FC = () => {
  const routerParams = useParams();
  const groupId = routerParams?.id || '';
  const resource = useResource();
  const { isEditMemberTab } = useSelector(selectPermissionGroupDetail);
  const [params, setParams] = useState<GroupListMemberQueryDto>({
    id: groupId,
    first: 0,
    max: 200,
  });
  const { setAlertMessage } = useAlertMessage();
  const { isLoading, data: userList = { items: [], totalRecord: 10 } } =
    useQuery(
      [QUERY_KEY.GROUP_MEMBERS, resource.groupId, groupId, params],
      () => fetchgroupListMembers(resource.groupId, params),
      {
        onError: (error: AxiosError<ErrorMessage>) => {
          const message =
            error.response?.data.message || 'Cannot get group list member';
          setAlertMessage({
            message,
            typeStatusMessage: 'error',
            statusCode: error.response?.status,
          });
        },
      }
    );

  const handleChangePage = (event: SelectChangeEvent<unknown>) => {
    const selectedPage = event.target.value as number;
    setParams((previous) => ({
      ...previous,
      first: selectedPage,
    }));
  };

  return (
    <UserTable
      data={userList.items}
      totalRecord={userList.totalRecord}
      canViewEditBtn={isEditMemberTab}
      loading={isLoading}
      selectedPage={params.first}
      handleChange={handleChangePage}
      isShowDeleteButton={false}
    />
  );
};

const WrappedGroupMemeberPage: FC = () => {
  const { canViewPage, canViewMemberTab } = useSelector(
    selectPermissionGroupDetail
  );
  return (
    <ProtectedRouter
      isEditPage
      feature={FeaturePage.GROUP}
      canView={canViewPage && canViewMemberTab}
      navigatePath="/groups"
    >
      <GroupMemeber />
    </ProtectedRouter>
  );
};

export { WrappedGroupMemeberPage };
