import { GroupDto } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionGroup,
  selectPermissionGroupDetail,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  deleteGroup,
  ErrorMessage,
  fetchGroupById,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  ButtonRounded,
  DeleteDialog,
  EmitSearchInput,
  SearchInput,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { useDebounce } from '@bom-nextgen-keycloak/web/shared/util';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { CircularProgress } from '@mui/material';
import { AxiosError } from 'axios';
import { Fragment, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ExportFormDialog } from './components/ExportForm';
import { GroupFormDialog } from './components/GroupForm';
import { TreeViewGroup } from './components/TreeViewGroup/TreeViewGroup';
import {
  ActionWrapper,
  GroupButton,
  LoaddingContainer,
  StyledButton,
} from './Group.styled';

const Group = () => {
  const [open, setOpen] = useState(false);
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedNode, setSelectedNode] = useState<GroupDto | null>({});
  const resource = useResource();
  const { setAlertMessage } = useAlertMessage();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { canViewExport, canViewAdd, canViewDelete } = useSelector(
    selectPermissionGroup
  );
  const { canViewPage } = useSelector(selectPermissionGroupDetail);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQueryTerm = useDebounce(searchQuery, 700);

  const { isLoading, data: groupDetail = {} } = useQuery(
    [QUERY_KEY.GROUP_DETAIL, resource.groupId, debouncedQueryTerm],
    () => fetchGroupById(resource.groupId, { search: searchQuery }),
    {
      onSettled: () => {
        setSelectedNode(null);
      },
      onError: (error: AxiosError<ErrorMessage>) => {
        const message = error.response?.data.message || 'Cannot get Group';
        setAlertMessage({
          message,
          typeStatusMessage: 'error',
          statusCode: error.response?.status,
        });
      },
    }
  );

  const deleteGroupMutation = useMutation(deleteGroup, {
    onSuccess: (data) => {
      setOpenDeleteDialog(false);
      setAlertMessage({
        message: 'Group has been deleted',
        typeStatusMessage: 'success',
      });
      queryClient.invalidateQueries([
        QUERY_KEY.GROUP_DETAIL,
        resource.groupId,
        debouncedQueryTerm,
      ]);
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot delete group';
      setAlertMessage({
        message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const handleChangeSearch = (value: EmitSearchInput) => {
    setSearchQuery(value.searchValue);
  };

  const handleNodeSelected = (node: GroupDto | null) => {
    setSelectedNode(node);
  };

  const handleSaveGroup = () => {
    setOpen(false);
    setSearchQuery('');
    queryClient.invalidateQueries([
      QUERY_KEY.GROUP_DETAIL,
      resource.groupId,
      debouncedQueryTerm,
    ]);
    setAlertMessage({
      message: 'Group has been created',
      typeStatusMessage: 'success',
    });
  };

  const handleDeleteUser = () => {
    if (selectedNode && selectedNode.id) {
      deleteGroupMutation.mutate(selectedNode.id);
    }
  };

  const handleClickEdit = () => {
    if (selectedNode && selectedNode.id) {
      navigate(`/groups/${selectedNode.id}`);
    }
  };

  const groupName = selectedNode?.subGroups?.length
    ? selectedNode?.subGroups?.map((val) => val.name?.toLocaleLowerCase())
    : [];

  const isHaveGroupsName = groupName?.includes('groups');
  const isHaveSubscriptionsName = groupName?.includes('subscriptions');
  const isShowNoOption = isHaveGroupsName && isHaveSubscriptionsName;

  return (
    <div>
      <ActionWrapper>
        <SearchInput
          placeholder="Search group names"
          onCliekSearch={handleChangeSearch}
          onKeyDown={handleChangeSearch}
          onChange={handleChangeSearch}
        />
        <GroupButton data-cy="group-list-item">
          {canViewExport && (
            <ButtonRounded
              sx={{ mr: 2 }}
              variant="contained"
              color="primary"
              disabled={!selectedNode?.id}
              onClick={() => setOpenExportDialog(true)}
              data-test-id="btn-edit-export"
            >
              Export
            </ButtonRounded>
          )}

          {canViewAdd && (
            <StyledButton
              disabled={
                !selectedNode?.id || selectedNode?.level === 4 || isShowNoOption
              }
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
              startIcon={<AddIcon />}
              data-test-id="btn-edit-add"
            >
              Add
            </StyledButton>
          )}

          {canViewPage && (
            <StyledButton
              disabled={!selectedNode?.id || selectedNode?.level === 1}
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              onClick={handleClickEdit}
              data-test-id="btn-edit-group"
            >
              Edit
            </StyledButton>
          )}

          {canViewDelete && (
            <StyledButton
              disabled={!selectedNode?.id || selectedNode?.level === 1}
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={() => setOpenDeleteDialog(true)}
              data-test-id="btn-edit-delete"
            >
              Delete
            </StyledButton>
          )}
        </GroupButton>
      </ActionWrapper>
      {isLoading && !Object.keys(groupDetail).length ? (
        <LoaddingContainer>
          <CircularProgress />
        </LoaddingContainer>
      ) : (
        <TreeViewGroup
          groupDetail={groupDetail}
          onNodeSelected={handleNodeSelected}
        />
      )}

      {selectedNode && (
        <Fragment>
          <GroupFormDialog
            open={open}
            onSaveGroup={handleSaveGroup}
            onClose={() => setOpen(false)}
            selectedNode={selectedNode}
          />
        </Fragment>
      )}

      {selectedNode && openExportDialog && (
        <ExportFormDialog
          open={openExportDialog}
          onClose={() => setOpenExportDialog(false)}
          groupId={selectedNode.id || ''}
          titleForm={selectedNode.name || ''}
        />
      )}

      <DeleteDialog
        title="Delete group?"
        description={`
        You are about to permanently delete <b>${selectedNode?.name}</b> and all of its sub-groups. <b>This operation cannot be undone.</b>
        `}
        open={openDeleteDialog}
        loading={deleteGroupMutation.isLoading}
        onClose={() => setOpenDeleteDialog(false)}
        onDelete={handleDeleteUser}
      />
    </div>
  );
};

export { Group };
