import { ButtonRounded } from '@bom-nextgen-keycloak/web/shared/ui';
import { styled } from '@mui/material/styles';

export const ActionWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
`;

export const WrapperSearchFilter = styled('div')`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 1rem;
  }
`;

export const ButtonAddResource = styled(ButtonRounded)`
  height: 3rem;
`;
