import { useResource } from '@bom-nextgen-keycloak/web/core';
import { ButtonRounded } from '@bom-nextgen-keycloak/web/shared/ui';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FC } from 'react';
import { StyledDialog } from './JoinGroupDialog.styled';

type JoinGroupDialogProps = {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onJoin: () => void;
};

const JoinGroupDialog: FC<JoinGroupDialogProps> = ({
  onClose,
  open,
  onJoin,
  loading,
}) => {
  const resource = useResource();
  return (
    <StyledDialog onClose={onClose} aria-labelledby="dialog-title" open={open}>
      <DialogTitle>Join Group</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This user already exists in another product(s), would you like to add
          him/her to <b>{resource.displayName}</b>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonRounded onClick={onClose} color="primary">
          NO
        </ButtonRounded>
        <ButtonRounded
          onClick={onJoin}
          color="primary"
          autoFocus
          loading={loading}
          variant="contained"
        >
          YES
        </ButtonRounded>
      </DialogActions>
    </StyledDialog>
  );
};

export { JoinGroupDialog };
