import { selectPermissionResourceDetail } from '@bom-nextgen-keycloak/web/core';
import {
  ResourceForm,
  validationSchema,
} from '@bom-nextgen-keycloak/web/pages/feature-resources';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  AutoCompleteOption,
  ButtonRounded,
  PageLoading,
  ProtectedRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useResourceDetail } from '../../hooks/useResourceDetail';
import { ActionWrapper } from './Detail.styled';

type ResourceDetailForm = {
  name: string;
  displayName: string;
  type: string;
  scopes: AutoCompleteOption[];
};

const Detail: FC = () => {
  const params = useParams();
  const resourceId = params.id || '';
  const { resourceDetail, handleUpdate, isLoading, isLoadingSubmit } =
    useResourceDetail(resourceId);
  const { isEditGeneralTab } = useSelector(selectPermissionResourceDetail);

  const initialValues: ResourceDetailForm = {
    name: resourceDetail?.name || '',
    displayName: resourceDetail?.displayName || '',
    type: resourceDetail?.type || '',
    scopes:
      resourceDetail?.scopes?.map((val) => ({
        label: val?.name || '',
        value: val,
      })) || [],
  };

  return (
    <PageLoading isLoading={isLoading}>
      <Formik
        initialValues={initialValues}
        onSubmit={(
          payload: ResourceDetailForm,
          { setSubmitting }: FormikHelpers<ResourceDetailForm>
        ) => {
          if (resourceDetail) {
            handleUpdate({
              ...resourceDetail,
              _id: resourceDetail?._id || '',
              name: payload.name,
              displayName: payload.displayName,
              type: payload.type,
              scopes: payload.scopes.map((scope) => ({
                id: scope.value.id,
                name: scope.value.name,
              })),
            });
            setSubmitting(false);
          }
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          isSubmitting,
          isValid,
          dirty,
          resetForm,
          values,
          setFieldValue,
          getFieldMeta,
          setFieldTouched,
        }) => (
          <Form>
            <ResourceForm
              isEdit
              scopes={values.scopes}
              setFieldTouched={setFieldTouched}
              getFieldMeta={getFieldMeta}
              setFieldValue={setFieldValue}
            />

            <ActionWrapper>
              <Box sx={{ mr: 2 }}>
                <ButtonRounded
                  color="primary"
                  variant="contained"
                  disabled={!dirty || !isEditGeneralTab}
                  onClick={() => resetForm()}
                >
                  Reset
                </ButtonRounded>
              </Box>
              <ButtonRounded
                type="submit"
                color="primary"
                variant="contained"
                loadingPosition="start"
                startIcon={<SaveIcon />}
                loading={isLoadingSubmit}
                disabled={
                  !(isValid && dirty) || isSubmitting || !isEditGeneralTab
                }
                data-test-id="btn-save-resource-edit"
              >
                Save
              </ButtonRounded>
            </ActionWrapper>
          </Form>
        )}
      </Formik>
    </PageLoading>
  );
};

const WrappedResourceDetailFormPage: FC = () => {
  const { canViewPage, canViewGeneralTab } = useSelector(
    selectPermissionResourceDetail
  );
  return (
    <ProtectedRouter
      feature={FeaturePage.RESOURCE}
      isEditPage
      canView={canViewPage && canViewGeneralTab}
      navigatePath="/resources"
    >
      <Detail />
    </ProtectedRouter>
  );
};

export { WrappedResourceDetailFormPage };
