import { Close } from '@mui/icons-material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
`;

export const CloseIconButton = styled(IconButton)`
  top: 2rem;
  right: 1.8rem;
  position: absolute;
`;

export const CloseIcon = styled(Close)`
  font-size: 2.1rem;
`;

export const StyledDialogContent = styled(DialogContent)`
  & > * {
    margin-bottom: 1.3rem;
  }
`;
