export const convertUTCToLocalDate = (inputDate: Date | null) => {
  if (!inputDate) {
    return inputDate;
  }

  const date = new Date(
    inputDate.getUTCFullYear(),
    inputDate.getUTCMonth(),
    inputDate.getUTCDate()
  );

  return date;
};

export const convertLocalToUTCDate = (inputDate: Date | null) => {
  if (!inputDate) {
    return inputDate;
  }
  return new Date(
    Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate())
  );
};
