import {
  FederatedIdentities,
  IFederatedIdentities,
} from '@bom-nextgen-keycloak/models';
import {
  selectPermissionUser,
  selectPermissionUserDetail,
  useAlertMessage,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  FeaturePage,
  fetchUserIdentityProvider,
  HeadCell,
  OrderTable,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  EnhancedTableHead,
  NotFound,
  ProtectedRouter,
  StyledTextEllipsis,
  TableContainer,
  TableRowSkeleton,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  getComparator,
  stableSort,
} from '@bom-nextgen-keycloak/web/shared/util';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

const headCells: HeadCell<keyof FederatedIdentities>[] = [
  {
    id: 'identityProvider',
    sortKey: 'identityProvider',
    numeric: false,
    disablePadding: false,
    label: 'Identity Provider Alias',
  },
  {
    id: 'userId',
    sortKey: 'userId',
    numeric: false,
    disablePadding: false,
    label: 'Provider User ID',
  },
  {
    id: 'userName',
    sortKey: 'userName',
    numeric: false,
    disablePadding: false,
    label: 'Provider Username',
  },
];
const IdentityProvider: FC = () => {
  const params = useParams();
  const [order, setOrder] = useState<OrderTable>('desc');
  const [orderBy, setOrderBy] =
    useState<keyof IFederatedIdentities>('identityProvider');
  const { setAlertMessage } = useAlertMessage();
  const userId = params?.id || '';
  const { isLoading, data: userList = [] } = useQuery(
    [QUERY_KEY.USER_IDENTITY_PROVIDER_LIST, userId],
    () => fetchUserIdentityProvider(userId),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message ||
          'Cannot get federated identity providers';
        setAlertMessage({
          message,
          typeStatusMessage: 'error',
          statusCode: error.response?.status,
        });
      },
    }
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IFederatedIdentities
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Paper>
      <TableContainer>
        <Table
          aria-label="user identity provider table"
          aria-labelledby="tableTitle"
          stickyHeader
        >
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            <TableRowSkeleton loading={isLoading} cellLength={headCells.length}>
              {userList.length ? (
                stableSort<IFederatedIdentities>(
                  userList,
                  getComparator(order, orderBy)
                ).map((row) => (
                  <TableRow key={`${row.userId}-${row.identityProvider}`}>
                    <TableCell title={row.identityProvider}>
                      <StyledTextEllipsis>
                        {row.identityProvider}
                      </StyledTextEllipsis>
                    </TableCell>
                    <TableCell title={row.userId}>
                      <StyledTextEllipsis>{row.userId}</StyledTextEllipsis>
                    </TableCell>
                    <TableCell title={row.userName}>
                      <StyledTextEllipsis>{row.userName}</StyledTextEllipsis>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <NotFound />
                  </TableCell>
                </TableRow>
              )}
            </TableRowSkeleton>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const WrappedIdentityProviderPage: FC = () => {
  const { canViewIdpTab, canViewPage } = useSelector(
    selectPermissionUserDetail
  );
  return (
    <ProtectedRouter
      isEditPage
      feature={FeaturePage.USER}
      canView={canViewIdpTab && canViewPage}
      navigatePath="/users"
    >
      <IdentityProvider />
    </ProtectedRouter>
  );
};

export { WrappedIdentityProviderPage };
