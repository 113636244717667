import { selectPermissionUserDetail } from '@bom-nextgen-keycloak/web/core';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  Attributes,
  PageLoading,
  ProtectedRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useUserDetail } from '../../hooks';

const UserAttribute: FC = () => {
  const params = useParams();
  const userId = params.id || '';
  const { isEditAttributesTab, canViewAttributesTab, canViewPage } =
    useSelector(selectPermissionUserDetail);

  const { userDetail, isLoading, isLoadingSubmit, handleSaveAttribute } =
    useUserDetail(userId);

  return (
    <ProtectedRouter
      isEditPage
      feature={FeaturePage.USER}
      canView={canViewAttributesTab && canViewPage}
      navigatePath="/users"
    >
      <PageLoading isLoading={isLoading}>
        <Attributes
          loading={isLoadingSubmit}
          isDisabled={!isEditAttributesTab}
          attributesDetail={userDetail?.attributes || {}}
          onSave={handleSaveAttribute}
        />
      </PageLoading>
    </ProtectedRouter>
  );
};

export { UserAttribute };
