import {
  IPolicyRepresentationList,
  PolicyCreateDto,
  PolicyProviderDto,
  PolicyQuery,
  PolicyRepresentationDto,
  PolicyUpdateDto,
} from '@bom-nextgen-keycloak/models';
import { axiosInstance } from '@bom-nextgen-keycloak/web/shared/util';
import { AxiosResponse } from 'axios';

export type DeletePolicyParams = {
  clientId: string;
  policyId: string;
};

export type CreatePolicyParams = {
  clientId: string;
  payload: PolicyCreateDto;
};

export type UpdatePolicyRoleParams = {
  clientId: string;
  payload: PolicyUpdateDto;
};

export const fetchPolicy = (clientId: string, params?: PolicyQuery) =>
  axiosInstance
    .get(`/clients/${clientId}/policies`, { params: { ...params } })
    .then(({ data }: AxiosResponse<IPolicyRepresentationList>) => data);

export const createPolicy = ({ clientId, payload }: CreatePolicyParams) =>
  axiosInstance
    .post<PolicyRepresentationDto>(`/clients/${clientId}/policies`, payload)
    .then(({ data }: AxiosResponse<PolicyRepresentationDto>) => data);

export const deletePolicy = ({ clientId, policyId }: DeletePolicyParams) =>
  axiosInstance.delete(`/clients/${clientId}/policies/${policyId}`);

export const fetchPolicyById = (
  clientId: string,
  policyId: string,
  type: string
) =>
  axiosInstance
    .get(`/clients/${clientId}/policies/${policyId}/types/${type}`)
    .then(({ data }: AxiosResponse<PolicyRepresentationDto>) => data);

export const fetchPolicyProviders = (clientId: string) =>
  axiosInstance
    .get(`/clients/${clientId}/policies/providers`)
    .then(({ data }: AxiosResponse<PolicyProviderDto[]>) => data);

export const updatePolicyRole = ({
  clientId,
  payload,
}: UpdatePolicyRoleParams) =>
  axiosInstance.put<AxiosResponse<void>>(
    `/clients/${clientId}/policies`,
    payload
  );
