import { selectPermissionGroupDetail } from '@bom-nextgen-keycloak/web/core';
import { ButtonRounded, TextField } from '@bom-nextgen-keycloak/web/shared/ui';
import {
  MESSAGE_NO_BEGINNING_SPACE_REGEX,
  NO_BEGINNING_SPACE_REGEX,
} from '@bom-nextgen-keycloak/web/shared/util';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import 'date-fns';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { ActionWrapper } from './IndustryForm.styled';

const validationSchema = yup.object({
  industry: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required('Industry is required'),
});

type IndustryAttributes = {
  industry: string;
};

type IndustryFormProps = {
  loading: boolean;
  attributes: Record<any, any>;
  onSubmit: (attributes: any) => void;
};

const IndustryForm: FC<IndustryFormProps> = ({
  attributes,
  onSubmit,
  loading,
}) => {
  const initialValues = {
    industry: attributes?.['industry'] || '',
  };

  const { isEditAttributesTab } = useSelector(selectPermissionGroupDetail);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(
        payload,
        { setSubmitting }: FormikHelpers<IndustryAttributes>
      ) => {
        onSubmit({
          industry: [payload.industry],
        });
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          <Box sx={{ mb: 3 }}>
            <TextField
              name="industry"
              label="Industry"
              disabled={!isEditAttributesTab}
              required
            />
          </Box>
          <ActionWrapper>
            <ButtonRounded
              type="submit"
              color="primary"
              variant="contained"
              loadingPosition="start"
              startIcon={<SaveIcon />}
              loading={loading}
              disabled={!(isValid && dirty) || isSubmitting}
            >
              Save
            </ButtonRounded>
          </ActionWrapper>
        </Form>
      )}
    </Formik>
  );
};

export { IndustryForm };
