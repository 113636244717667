import { useState, useEffect } from 'react';

export const useDebounce = (value: string | undefined, delay: number) => {
  const [debounceValue, setDebounceValue] = useState(value);

  useEffect(() => {
    const setTimeoutId = setTimeout(() => setDebounceValue(value), delay);

    return () => {
      clearTimeout(setTimeoutId);
    };
  }, [value, delay]);

  return debounceValue;
};
