import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';

export const ContainerPagination = styled('div')`
  color: rgba(0, 0, 0, 0.87);
  overflow: auto;
  font-size: 0.875rem;

  &:last-child {
    padding: 0;
  }
`;

export const ContainerPaginationToolBar = styled('div')`
  display: flex;
  position: relative;
  align-items: center;
  min-height: 52px;
  padding-right: 1rem;
  overflow: hidden;
`;

export const PaginationSpacer = styled('div')`
  flex: 1 1 100%;
`;

export const StyleSelect = styled(Select)`
  margin: 0 1rem;
  max-height: 39px;
`;
