import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';

export const GroupContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
`;

export const StyledCard = styled(Card)`
  width: 30rem;
`;

export const StyledCardContent = styled(CardContent)`
  height: 25rem;
  overflow-y: auto;
`;

export const LoaddingContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledListItem = styled(ListItem)`
  &.Mui-selected {
    background-color: rgb(8 103 222 / 8%);

    &:hover {
      background-color: rgb(8 103 222 / 8%);
    }
  }
`;
