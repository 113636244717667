import { ResourceDto } from '@bom-nextgen-keycloak/models';
import {
  FeaturePage,
  PermissionResourceDetailPage,
  PermissionResourcePage,
  ResourceEvent,
  ScopeApplication,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { getValueAttributeKeycloak } from '@bom-nextgen-keycloak/web/shared/util';
import { matchPermission, ResourceType } from '../reducer/permission.reducer';

const getComponentResource = (resources: ResourceDto[]): ResourceType[] => {
  return resources
    .filter(
      (val) =>
        val.type === 'component' &&
        getValueAttributeKeycloak(val.attributes, 'feature').toLowerCase() ===
          FeaturePage.RESOURCE.toLowerCase()
    )
    .map((val) => ({
      ...val,
      scopes: val.scopes?.map((scope) => scope.name) || [],
    }));
};

export const mapRescourcePermission = (
  resources: ResourceDto[]
): PermissionResourcePage => {
  const resourceComponentList: ResourceType[] = getComponentResource(resources);
  return {
    canViewAdd: matchPermission(
      resourceComponentList,
      ResourceEvent.ADD,
      ScopeApplication.CREATE
    ),
    canViewDelete: matchPermission(
      resourceComponentList,
      ResourceEvent.DELETE,
      ScopeApplication.DELETE
    ),
  };
};

export const mapRescourcePermissionDetail = (
  resources: ResourceDto[]
): PermissionResourceDetailPage => {
  const resourceComponentList: ResourceType[] = getComponentResource(resources);

  const resourceDetailComponentList: ResourceType[] = resources
    .filter(
      (val) =>
        val.type === 'subset' &&
        getValueAttributeKeycloak(val.attributes, 'feature').toLowerCase() ===
          FeaturePage.RESOURCE.toLowerCase()
    )
    .map((val) => ({
      ...val,
      scopes: val.scopes?.map((scope) => scope.name) || [],
    }));
  return {
    canViewPage: matchPermission(
      resourceDetailComponentList,
      ResourceEvent.DETAIL,
      ScopeApplication.VIEW
    ),
    canViewGeneralTab: matchPermission(
      resourceComponentList,
      ResourceEvent.GENERAL,
      ScopeApplication.VIEW
    ),
    canViewAttributesTab: matchPermission(
      resourceComponentList,
      ResourceEvent.ATTRIBUTE,
      ScopeApplication.VIEW
    ),
    isEditGeneralTab: matchPermission(
      resourceComponentList,
      ResourceEvent.GENERAL,
      ScopeApplication.EDIT
    ),
    isEditAttributesTab: matchPermission(
      resourceComponentList,
      ResourceEvent.ATTRIBUTE,
      ScopeApplication.EDIT
    ),
  };
};
