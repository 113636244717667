import { selectPermissionRoleDetail } from '@bom-nextgen-keycloak/web/core';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  BreadcrumbsLink,
  ProtectedRouter,
  useTabsWithRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet, useParams } from 'react-router-dom';
import { useRoleDetail } from './hooks/useRoleDetail';
import { StyledAppBar } from './RoleDetail.styled';

const RoleDetail: FC<{ roleId: string }> = ({ roleId }) => {
  const { roleDetail } = useRoleDetail(roleId);
  const { canViewGeneralTab, canViewAttributesTab, canViewUserTab } =
    useSelector(selectPermissionRoleDetail);
  const tabValue = useTabsWithRouter(
    [
      `/roles/${roleId}`,
      `/roles/${roleId}/attributes`,
      `/roles/${roleId}/users`,
    ],
    `/roles/${roleId}`
  );

  return (
    <div>
      <BreadcrumbsLink
        name="Roles"
        pathName="/roles"
        detailName={roleDetail.name}
      />
      <StyledAppBar position="static">
        <Tabs
          value={tabValue}
          aria-label="simple tabs example"
          textColor="inherit"
          variant="fullWidth"
          indicatorColor="secondary"
        >
          {canViewGeneralTab && (
            <Tab
              label="Details"
              component={Link}
              value={`/roles/${roleId}`}
              to={`/roles/${roleId}`}
              icon={<SettingsIcon />}
            />
          )}
          {canViewAttributesTab && (
            <Tab
              label="Attributes"
              component={Link}
              value={`/roles/${roleId}/attributes`}
              to={`/roles/${roleId}/attributes`}
              icon={<AssignmentIndIcon />}
            />
          )}
          {canViewUserTab && (
            <Tab
              label="Users in Role"
              component={Link}
              value={`/roles/${roleId}/users`}
              to={`/roles/${roleId}/users`}
              icon={<PeopleIcon />}
            />
          )}
        </Tabs>
      </StyledAppBar>
      <Box sx={{ mt: 3 }}>
        <Outlet />
      </Box>
    </div>
  );
};

const WrappedRoleDetailPage: FC = () => {
  const params = useParams();
  const { canViewPage } = useSelector(selectPermissionRoleDetail);
  return (
    <ProtectedRouter
      feature={FeaturePage.ROLE}
      isEditPage
      canView={canViewPage}
      navigatePath="/roles"
    >
      <RoleDetail roleId={params.id || ''} />
    </ProtectedRouter>
  );
};

export { WrappedRoleDetailPage };
