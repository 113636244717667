import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  ActionWrapper,
  AttributesMain,
  StyledActionButtonCell,
  StyledButton,
  StyledTableContainer,
  StyledTextField,
} from './Attributes.styled';

type AttributesProps = {
  loading: boolean;
  isDisabled?: boolean;
  attributesDetail: Record<string, string>;
  onSave: (attributes: Record<string, string>) => void;
};

enum Status {
  Add = 1,
  Delete = 2,
}

type AttributesType = {
  id: string;
  key: string;
  value: any;
  status: Status;
};

const mapObjectToArray = (attributesDetail: Record<string, any>) => {
  if (Object.keys(attributesDetail).length) {
    const newAttributes = Object.entries(attributesDetail).map(
      ([key, value], index) => ({
        id: uuidv4(),
        key,
        value,
        status: Status.Delete,
      })
    );
    return [
      ...newAttributes,
      { key: '', value: '', id: uuidv4(), status: Status.Add },
    ];
  } else {
    return [{ key: '', value: '', id: uuidv4(), status: Status.Add }];
  }
};

const mapArrayToObject = (array: AttributesType[]) => {
  const output: any = {};
  array.forEach((val) => {
    if (val.key) {
      output[val.key] = val.value instanceof Array ? val.value : [val.value];
    }
  });
  return output;
};

const Attributes: FC<AttributesProps> = ({
  attributesDetail,
  onSave,
  loading,
  isDisabled = false,
}) => {
  const [isChange, setChangeAttributes] = useState(false);
  const [attributes, setAttributes] = useState<AttributesType[]>([
    ...mapObjectToArray(attributesDetail),
  ]);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    row: AttributesType
  ) => {
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const newRows = attributes.map((val) =>
      val.id === id ? { ...val, [name]: value } : val
    );
    setAttributes(newRows);
  };

  const handleClickAdd = (data: AttributesType) => {
    const newRows = attributes.map((row) =>
      row.id === data.id ? { ...row, status: Status.Delete } : row
    );
    setAttributes([
      ...newRows,
      { key: '', value: '', id: uuidv4(), status: Status.Add },
    ]);
    setChangeAttributes(true);
  };

  const handleClickDelete = (id: string) => {
    const newRows = attributes.filter((row) => row.id !== id);
    setAttributes(newRows);
    setChangeAttributes(true);
  };

  return (
    <AttributesMain>
      <Paper>
        <StyledTableContainer>
          <Table aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell>Value</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attributes.map((row) => (
                <TableRow key={row.id}>
                  <TableCell scope="row">
                    <StyledTextField
                      value={row.key}
                      name="key"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(e, row)
                      }
                      disabled={
                        (row.key && row.status == Status.Delete) || isDisabled
                      }
                      id={`standard-key` + row.key}
                    />
                  </TableCell>
                  <TableCell scope="row">
                    <StyledTextField
                      value={row.value}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(e, row)
                      }
                      name="value"
                      disabled={isDisabled}
                      id={`standard-value` + row.value}
                    />
                  </TableCell>
                  <StyledActionButtonCell align="right" scope="row">
                    {row.status == Status.Add ? (
                      <IconButton
                        aria-label="add"
                        disabled={!row.key || !row.value || isDisabled}
                        onClick={() => handleClickAdd(row)}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="delete"
                        disabled={isDisabled}
                        onClick={() => handleClickDelete(row.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </StyledActionButtonCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Paper>
      <ActionWrapper>
        <StyledButton
          type="submit"
          color="primary"
          variant="contained"
          loadingPosition="start"
          loading={loading}
          disabled={!isChange || isDisabled}
          startIcon={<SaveIcon />}
          onClick={() => onSave(mapArrayToObject(attributes))}
        >
          Save
        </StyledButton>
      </ActionWrapper>
    </AttributesMain>
  );
};

export { Attributes };
