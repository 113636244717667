import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FC } from 'react';
import { LoaddingContainer } from './SelectBox.styled';

export type SelectBoxItem = {
  id: string;
  name: string;
};

type SelectBoxProps = {
  title: React.ReactNode;
  items: readonly SelectBoxItem[];
  checked: readonly SelectBoxItem[];
  isloading: boolean;
  isShowOnly?: boolean;
  handleChecked?: (selected: SelectBoxItem[]) => void;
};

export function not(
  item: readonly SelectBoxItem[],
  itemTwo: readonly SelectBoxItem[]
) {
  return item.filter((value) => itemTwo.indexOf(value) === -1);
}

export function intersection(
  item: readonly SelectBoxItem[],
  itemTwo: readonly SelectBoxItem[]
) {
  return item.filter((value) => itemTwo.indexOf(value) !== -1);
}

export function union(
  item: readonly SelectBoxItem[],
  itemTwo: readonly SelectBoxItem[]
) {
  return [...item, ...not(itemTwo, item)];
}

const SelectBox: FC<SelectBoxProps> = ({
  title,
  items = [],
  isloading = false,
  checked = [],
  handleChecked,
  isShowOnly = false,
}) => {
  const numberOfChecked = (selectItem: readonly SelectBoxItem[]) =>
    intersection(checked, selectItem).length;

  const handleToggle = (value: SelectBoxItem) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (handleChecked) {
      handleChecked(newChecked);
    }
  };

  const handleToggleAll = (selected: readonly SelectBoxItem[]) => () => {
    if (handleChecked && numberOfChecked(selected) === selected.length) {
      handleChecked(not(checked, selected));
    } else if (handleChecked) {
      handleChecked(union(checked, selected));
    }
  };

  return (
    <Card
      sx={{
        height: 300,
      }}
    >
      {isShowOnly ? (
        <CardHeader
          disableTypography
          sx={{ px: 2, py: 1, height: 58 }}
          title={title}
        />
      ) : (
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              disabled={items.length === 0 || isloading}
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              inputProps={{
                'aria-label': 'all items selected',
              }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
      )}

      <Divider />
      {isloading ? (
        <LoaddingContainer>
          <CircularProgress />
        </LoaddingContainer>
      ) : (
        <List
          sx={{
            width: 200,
            height: 230,
            bgcolor: 'background.paper',
            overflow: 'auto',
          }}
          dense
          component="div"
          role="list"
        >
          {items.map((value) => {
            const labelId = `transfer-list-all-item-${value.id}-label`;
            if (!isShowOnly) {
              return (
                <ListItemButton
                  key={value.id}
                  role="listitem"
                  onClick={handleToggle(value)}
                >
                  {!isShowOnly && (
                    <ListItemIcon>
                      <Checkbox
                        checked={checked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </ListItemIcon>
                  )}
                  <ListItemText id={labelId} primary={value.name} />
                </ListItemButton>
              );
            } else {
              return (
                <ListItem
                  key={value.id}
                  role="listitem"
                  onClick={handleToggle(value)}
                >
                  <ListItemText id={labelId} primary={value.name} />
                </ListItem>
              );
            }
          })}
          <ListItem />
        </List>
      )}
    </Card>
  );
};

export { SelectBox };
