import {
  mapResourceApplication,
  selectOptionResource,
  setFeature,
  setPermissionPage,
  setResource,
  setSelectedResource,
  useAlertMessage,
  useResource,
  useUser,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  fetchPermission,
  fetchProfileUser,
  QUERY_KEY,
  RouteType,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  DialogAlertMessage,
  FullPageLoadingSpinner,
} from '@bom-nextgen-keycloak/web/shared/ui';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { useKeycloak } from '@react-keycloak/web';
import { AxiosError } from 'axios';
import React, { FC, Suspense, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { matchRoutes, Outlet, RouteMatch, RouteObject } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { MainWrapper, Wrapper } from './AuthenticatedAppContainer.styled';
import { ResponsiveDrawer } from './components';
import { getAppMenuItems } from './services/menu.service';

const AuthenticatedAppContainer: FC<{ privateRoute: RouteType[] }> = ({
  privateRoute,
}) => {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const { errorAlertMessage, setAlertMessage } = useAlertMessage();

  const resource = useResource();
  const dispatch = useDispatch();
  const { setUser, user } = useUser();
  const optionResource = useSelector(selectOptionResource);

  const [appMenuItems, setMenuItems] = useState<any[]>([]);
  const routerPath = privateRoute.length ? privateRoute : [];
  const matchedRoute: RouteMatch[] | null = matchRoutes(
    routerPath,
    location.pathname
  );

  const { isLoading, isSuccess } = useQuery(
    [QUERY_KEY.PERMISSION_AND_USER],
    () => Promise.all([fetchPermission(), fetchProfileUser()]),
    {
      enabled: keycloak?.authenticated,
      onSuccess: ([permission, userAuth]) => {
        if (permission.resources.length && userAuth) {
          const productPermissions = permission.resources.filter(
            (item) => item.type === 'product'
          );
          const featurePermissions = permission.resources.filter(
            (item) => item.type === 'feature'
          );
          const featureResourcesApplications =
            mapResourceApplication(featurePermissions);
          setUser(userAuth);
          dispatch(setResource(productPermissions));
          dispatch(setFeature(featurePermissions));
          dispatch(setPermissionPage(permission.resources));
          setMenuItems(getAppMenuItems(featureResourcesApplications));
        }
      },
      onError: (error: AxiosError<ErrorMessage>) => {
        if (error.response?.status === 403) {
          navigate('/unauthorized');
        } else {
          keycloak?.logout();
        }
        console.error('Cannot get the permission');
      },
    }
  );

  useEffect(() => {
    if (!keycloak.authenticated) {
      navigate('/login');
    }
  }, [history, keycloak]);

  const handleLogout = useCallback(() => {
    keycloak?.logout();
    localStorage.removeItem('resource');
  }, [keycloak]);

  const handleCloseDialog = () => {
    setAlertMessage({
      message: '',
      typeStatusMessage: errorAlertMessage.typeStatusMessage,
      statusCode: errorAlertMessage.statusCode,
    });
  };

  const handleChangeResource = (resourceId: string) => {
    const findResource = optionResource.find(
      (val) => val.resourceId === resourceId
    );

    if (findResource) {
      localStorage.setItem('resource', JSON.stringify(findResource));
      dispatch(setSelectedResource(findResource));
    }
  };

  return (
    <Wrapper>
      <ResponsiveDrawer
        loading={isLoading}
        userAuth={user}
        appMenuItems={appMenuItems}
        resourceId={resource?.resourceId}
        titlePath={
          matchedRoute
            ? (matchedRoute[0].route as RouteObject & { title: string }).title
            : ''
        }
        matchUrl={matchedRoute ? matchedRoute[0].pathname : ''}
        onClickLogout={handleLogout}
        onChangeResource={handleChangeResource}
      />
      <MainWrapper>
        <Toolbar />
        <Container maxWidth="lg">
          {isSuccess && (
            <Suspense fallback={<FullPageLoadingSpinner />}>
              <Outlet />
            </Suspense>
          )}
        </Container>
      </MainWrapper>

      {errorAlertMessage?.statusCode !== 401 && (
        <DialogAlertMessage
          message={errorAlertMessage.message}
          typeStatus={errorAlertMessage.typeStatusMessage}
          onCloseDialog={handleCloseDialog}
        />
      )}
    </Wrapper>
  );
};

export { AuthenticatedAppContainer };
