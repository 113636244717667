import Tab, { TabProps } from '@mui/material/Tab';
import React from 'react';
import { Link } from 'react-router-dom';

const TabLink: React.FC<TabProps & { to?: string }> = ({
  to,
  value,
  icon,
  label,
  ...props
}) => {
  return (
    <Tab
      component={Link}
      label={label}
      to={to ?? value}
      value={value}
      icon={icon}
    />
  );
};

export { TabLink };
