import { LoggingDto } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionEvent,
  useAlertMessage,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  FeaturePage,
  fetchLoggingById,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  BreadcrumbsLink,
  NotFound,
  PageLoading,
  ProtectedRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { AxiosError } from 'axios';
import { format } from 'date-fns-tz';
import { FC, Fragment } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

const SectionSummary: FC<Partial<LoggingDto>> = (props) => {
  return (
    <Fragment>
      <Typography sx={{ mb: 3 }} variant="h5">
        Summary
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="summary table">
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: 150 }} component="th" scope="row">
                Event ID
              </TableCell>
              <TableCell>{props?.id || ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: 150 }} component="th" scope="row">
                Time
              </TableCell>
              <TableCell>
                {props?.createdAt
                  ? format(new Date(props.createdAt), 'MM/dd/yyyy HH:mm:ss', {
                      timeZone: 'Asia/Bangkok',
                    })
                  : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: 150 }} component="th" scope="row">
                Session state
              </TableCell>
              <TableCell>{props?.sessionState || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: 150 }} component="th" scope="row">
                Status
              </TableCell>
              <TableCell>
                <span style={{ textTransform: 'capitalize' }}>
                  {props?.status || '-'}
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: 150 }} component="th" scope="row">
                Type
              </TableCell>
              <TableCell>{props?.type || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: 150 }} component="th" scope="row">
                Description
              </TableCell>
              <TableCell title={props?.summary}>
                {props?.summary || '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

const SectionUser: FC<Partial<LoggingDto>> = (props) => {
  return (
    <Fragment>
      <Typography sx={{ mb: 3 }} variant="h5">
        User
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="summary table">
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: 150 }} component="th" scope="row">
                User ID
              </TableCell>
              <TableCell>{props?.userId || ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: 150 }} component="th" scope="row">
                Username
              </TableCell>
              <TableCell>{props?.username || ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: 150 }} component="th" scope="row">
                Email
              </TableCell>
              <TableCell>{props?.email || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: 150 }} component="th" scope="row">
                Name
              </TableCell>
              <TableCell>
                <span style={{ textTransform: 'capitalize' }}>
                  {props?.name || '-'}
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

const SectionGroup: FC<Partial<LoggingDto>> = (props) => {
  return (
    <Fragment>
      <Typography sx={{ mb: 3 }} variant="h5">
        Groups
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="summary table">
          <TableHead>
            <TableRow>
              <TableCell component="th">Level</TableCell>
              <TableCell component="th">Name</TableCell>
              <TableCell component="th">Hierarchy</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props?.groups?.length ? (
              props?.groups?.map((group) => (
                <TableRow
                  key={group.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {group.level}
                  </TableCell>
                  <TableCell>{group.groupName}</TableCell>
                  <TableCell>{group.groupPath}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <NotFound />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

const PermissionDetail: FC<{ loggingId: string }> = ({ loggingId }) => {
  const { setAlertMessage } = useAlertMessage();
  const navigate = useNavigate();

  const { isLoading, data: loggingDetail } = useQuery(
    [QUERY_KEY.LOGGING_DETAIL, loggingId],
    () => fetchLoggingById(loggingId),
    {
      onError: (error: AxiosError<ErrorMessage>) => {
        const message =
          error.response?.data.message || 'Cannot get list permission event';
        setAlertMessage({
          message,
          typeStatusMessage: 'error',
          statusCode: error.response?.status,
        });
        navigate('/events');
      },
    }
  );

  return (
    <Box>
      <PageLoading isLoading={isLoading}>
        <BreadcrumbsLink
          name="Permission Events"
          detailName={loggingDetail?.username || 'Detail'}
          pathName="/events"
        />
        <Box sx={{ mt: 3 }}>
          <SectionSummary {...loggingDetail} />
        </Box>
        <Box sx={{ mt: 3 }}>
          <SectionUser {...loggingDetail} />
        </Box>
        <Box sx={{ mt: 3 }}>
          <SectionGroup {...loggingDetail} />
        </Box>
      </PageLoading>
    </Box>
  );
};

const WrappedEventPermissionDetailPage: FC = () => {
  const params = useParams();
  const { canViewPermission } = useSelector(selectPermissionEvent);
  return (
    <ProtectedRouter
      isEditPage
      feature={FeaturePage.EVENT}
      canView={canViewPermission}
      navigatePath="/events"
    >
      <PermissionDetail loggingId={params.id || ''} />
    </ProtectedRouter>
  );
};

export { WrappedEventPermissionDetailPage };
