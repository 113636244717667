import { ClientRoleQueryDto } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionRole,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  deleteClientRole,
  ErrorMessage,
  fetchClientRole,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  ButtonRounded,
  DeleteDialog,
  EmitSearchInput,
  SearchInput,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { useDebounce } from '@bom-nextgen-keycloak/web/shared/util';
import { SelectChangeEvent } from '@mui/material/Select';
import { AxiosError } from 'axios';
import { FC, Fragment, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { RoleFormDialog } from './components/RoleFormDialog';
import { RoleTable } from './components/RoleTable';
import { ActionWrapper } from './Role.styled';

const Role: FC = () => {
  const resource = useResource();
  const { setAlertMessage } = useAlertMessage();
  const queryClient = useQueryClient();
  const [params, setParams] = useState<ClientRoleQueryDto>({
    search: '',
    first: 0,
    max: 100,
  });
  const [roleName, setRoleName] = useState('');
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQueryTerm = useDebounce(searchQuery, 700);
  const { canViewAdd } = useSelector(selectPermissionRole);

  const { isLoading, data: clientRoleList = { items: [], totalRecord: 0 } } =
    useQuery(
      [
        QUERY_KEY.CLIENT_ROLE_LIST,
        resource.clientId,
        params,
        debouncedQueryTerm,
      ],
      () =>
        fetchClientRole(resource.clientId, { ...params, search: searchQuery }),
      {
        onError: (error: AxiosError<ErrorMessage>) => {
          const message =
            error.response?.data.message || 'Cannot get client role list';
          setAlertMessage({
            message,
            typeStatusMessage: 'error',
            statusCode: error.response?.status,
          });
        },
      }
    );

  const removeClientRoleMutation = useMutation(deleteClientRole, {
    onSuccess: (data) => {
      setAlertMessage({
        message: 'Role has been deleted',
        typeStatusMessage: 'success',
      });
      setParams((previous) => ({ ...previous, first: 0 }));
      setSearchQuery('');
      queryClient.invalidateQueries([
        QUERY_KEY.CLIENT_ROLE_LIST,
        resource.clientId,
        params,
        debouncedQueryTerm,
      ]);
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message =
        error.response?.data.message || 'Cannot delete client role';
      setAlertMessage({
        message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const handleChangeSearch = (value: EmitSearchInput) => {
    setSearchQuery(value.searchValue);
    setParams((previous) => ({ ...previous, first: 0 }));
  };

  const handleSave = () => {
    setOpen((previous) => !previous);
    setParams((previous) => ({ ...previous, first: 0 }));
    setSearchQuery('');
    setAlertMessage({
      message: 'Role has been created',
      typeStatusMessage: 'success',
    });
    queryClient.invalidateQueries([
      QUERY_KEY.CLIENT_ROLE_LIST,
      resource.clientId,
      params,
      debouncedQueryTerm,
    ]);
  };

  const handleShowDialogDelete = (role: string) => {
    setOpenDeleteDialog((previous) => !previous);
    setRoleName(role);
  };

  const handleClickDelete = () => {
    setOpenDeleteDialog((previous) => !previous);
    removeClientRoleMutation.mutate({ clientId: resource.clientId, roleName });
  };

  const handleCloseDeleteDialog = () => {
    setRoleName('');
    setOpenDeleteDialog((previous) => !previous);
  };

  const handleChangePage = (event: SelectChangeEvent<unknown>) => {
    const selectedPage = event.target.value as number;
    setParams((previous) => ({
      ...previous,
      first: selectedPage,
    }));
  };

  return (
    <Fragment>
      <ActionWrapper>
        <SearchInput
          placeholder="Search role names"
          onCliekSearch={handleChangeSearch}
          onKeyDown={handleChangeSearch}
          onChange={handleChangeSearch}
        />
        {canViewAdd && (
          <ButtonRounded
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            Add Role
          </ButtonRounded>
        )}
      </ActionWrapper>
      <RoleTable
        data={clientRoleList?.items || []}
        totalRecord={clientRoleList?.totalRecord || 0}
        loading={isLoading}
        selectedPage={params.first}
        handleChange={handleChangePage}
        onClickDelate={handleShowDialogDelete}
      />

      <RoleFormDialog
        open={open}
        onSave={handleSave}
        onClose={() => setOpen(false)}
      />

      <DeleteDialog
        title="Delete role?"
        description={`You are about to permanently delete <b>${roleName}</b>, its relationships, and all of its data. <b>This operation cannot be undone.</b>`}
        open={openDeleteDialog}
        loading={removeClientRoleMutation.isLoading}
        onClose={handleCloseDeleteDialog}
        onDelete={handleClickDelete}
      />
    </Fragment>
  );
};

export { Role };
