import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import Select, { SelectProps } from '@mui/material/Select';
import { FieldHookConfig, useField } from 'formik';
import React, { FC } from 'react';

const DropdownField: FC<SelectProps & FieldHookConfig<string>> = ({
  children,
  required,
  label,
  ...rest
}) => {
  const [field, meta] = useField(rest);

  return (
    <FormControl fullWidth variant="standard" error={!!meta.error}>
      <InputLabel shrink required={required}>
        {label}
      </InputLabel>
      <Select {...field} {...rest}>
        {children}
      </Select>
      {meta.touched && meta.error ? (
        <FormHelperText>{meta.error}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export { DropdownField };
