import {
  selectPermissionUser,
  selectPermissionUserDetail,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  PageLoading,
  ProtectedRouter,
  SwitchToggleField,
  TextField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  EMAIL_REGEX,
  ENGLISH_REGEX,
  MESSAGE_NO_BEGINNING_SPACE_REGEX,
  NO_BEGINNING_SPACE_REGEX,
  NO_SPACE_REGEX,
} from '@bom-nextgen-keycloak/web/shared/util';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import { format } from 'date-fns';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import * as yup from 'yup';
import { useUserDetail } from '../../hooks';
import { ActionWrapper, StyledButton } from './UserDetailForm.styled';

const validationSchema = yup.object({
  username: yup
    .string()
    .matches(NO_SPACE_REGEX, 'Not allowed space')
    .required('Username is required'),
  firstName: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required('First name is required'),
  lastName: yup
    .string()
    .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
    .required('Last name is required'),
  email: yup
    .string()
    .email()
    .matches(EMAIL_REGEX, 'Invalid email address')
    .required('Email is required'),
});

type UserDetailFormType = {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  groupId: string;
  enabled: boolean;
  enabledSSO: boolean;
  createdTimestamp: string;
};

const UserDetailForm: FC = () => {
  const params = useParams();
  const userId = params.id || '';
  const resource = useResource();
  const { isEditGeneralTab, isEditUsername, canViewGeneralTab, canViewPage } =
    useSelector(selectPermissionUserDetail);
  const { canViewToggle } = useSelector(selectPermissionUser);
  const { userDetail, isLoading, isLoadingSubmit, handleUpdateUser } =
    useUserDetail(userId);

  // TODO: To implement as a helper function
  const isAdmin =
    userDetail?.attributes?.admin &&
    userDetail.attributes.admin.length === 1 &&
    userDetail.attributes.admin[0] === 'true'
      ? true
      : false;

  const initialValues: UserDetailFormType = {
    username: userDetail?.username || '',
    firstName: userDetail?.firstName || '',
    lastName: userDetail?.lastName || '',
    email: userDetail?.email || '',
    enabled: userDetail?.enabled || false,
    groupId: resource.groupId,
    enabledSSO: userDetail?.enabledSSO || false,
    createdTimestamp: userDetail?.createdTimestamp
      ? format(new Date(userDetail?.createdTimestamp || ''), 'MM/dd/yyyy')
      : '',
  };

  return (
    <ProtectedRouter
      isEditPage
      feature={FeaturePage.USER}
      canView={canViewGeneralTab && canViewPage}
      navigatePath="/users"
    >
      <PageLoading isLoading={isLoading}>
        <Formik
          initialValues={initialValues}
          onSubmit={(
            payload: UserDetailFormType,
            { setSubmitting }: FormikHelpers<UserDetailFormType>
          ) => {
            handleUpdateUser(payload);
            setSubmitting(false);
          }}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isSubmitting,
            isValid,
            values,
            dirty,
            resetForm,
            setFieldValue,
          }) => (
            <Form>
              <Box sx={{ mb: 3 }}>
                <TextField
                  name="username"
                  label="Username"
                  disabled={!isEditUsername || isAdmin}
                />
              </Box>

              <Box sx={{ mb: 3 }}>
                <TextField
                  name="createdTimestamp"
                  label="Created on"
                  disabled
                />
              </Box>

              <Box sx={{ mb: 3 }}>
                <TextField
                  name="email"
                  label="Email"
                  type="email"
                  required
                  disabled={!isEditGeneralTab}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    let value = e.target.value;
                    value = value.replace(ENGLISH_REGEX, '');
                    setFieldValue('email', value);
                  }}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <TextField
                  name="firstName"
                  disabled={!isEditGeneralTab}
                  label="First name"
                  required
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <TextField
                  name="lastName"
                  disabled={!isEditGeneralTab}
                  label="Last name"
                  required
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <SwitchToggleField
                  label="Status"
                  name="enabled"
                  disabled={!isEditGeneralTab}
                  checked={values.enabled}
                />
              </Box>
              {canViewToggle && (
                <Box sx={{ mb: 3 }}>
                  <SwitchToggleField
                    label="User enabled (SSO)"
                    name="enabledSSO"
                    disabled={!isEditGeneralTab}
                    checked={values.enabledSSO}
                  />
                </Box>
              )}

              <ActionWrapper>
                <StyledButton
                  color="primary"
                  variant="contained"
                  disabled={!dirty || !isEditGeneralTab}
                  onClick={() => resetForm()}
                >
                  Reset
                </StyledButton>
                <StyledButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  loading={isLoadingSubmit}
                  disabled={
                    !(isValid && dirty) || isSubmitting || !isEditGeneralTab
                  }
                >
                  Save
                </StyledButton>
              </ActionWrapper>
            </Form>
          )}
        </Formik>
      </PageLoading>
    </ProtectedRouter>
  );
};

export { UserDetailForm };
