/**
 * Convert all properties in Object to string
 *
 *
 * @param o
 */
export function makeStringProps(o: { [key: string]: any }): {
  [key: string]: string;
} {
  Object.keys(o).forEach((k) => {
    if (typeof o[k] === 'object') {
      return makeStringProps(o[k]);
    }
    return (o[k] = '' + o[k]);
  });
  return o;
}
