import { GroupDto, GroupRegisterDto } from '@bom-nextgen-keycloak/models';
import { useAlertMessage } from '@bom-nextgen-keycloak/web/core';
import {
  createChildGroup,
  ErrorMessage,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  ButtonRounded,
  DropdownField,
  StyledDialogTitle,
  TextField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import {
  MESSAGE_NO_BEGINNING_SPACE_REGEX,
  NO_BEGINNING_SPACE_REGEX,
} from '@bom-nextgen-keycloak/web/shared/util';
import SaveIcon from '@mui/icons-material/Save';
import { Box, MenuItem } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC, Fragment } from 'react';
import { useMutation } from 'react-query';

import * as yup from 'yup';
import { StyledDialog, StyledDialogContent } from './GroupForm.styled';

type GroupFormDialogProps = {
  open: boolean;
  selectedNode: GroupDto;
  onClose: () => void;
  onSaveGroup: () => void;
};

type GroupForm = {
  name: string;
  industry: string;
  level: number;
};

const validationSchema = yup.lazy((values) => {
  if (values.level === 1) {
    return yup.object({
      name: yup
        .string()
        .required('Group name is required')
        .matches(
          /^[aA-zZ0-9-_:() ]+$/,
          'Not allowed special characters for this field'
        ),
      industry: yup.string().required('Industry is required'),
    });
  } else if (values.level !== 2) {
    return yup.object({
      name: yup
        .string()
        .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
        .required('Group name is required'),
    });
  } else {
    return yup.object({
      name: yup
        .string()
        .matches(NO_BEGINNING_SPACE_REGEX, MESSAGE_NO_BEGINNING_SPACE_REGEX)
        .required('Group directory name is required'),
    });
  }
});

const initialValues: GroupForm = {
  name: '',
  industry: '',
  level: 0,
};

const GroupFormDialog: FC<GroupFormDialogProps> = ({
  onClose,
  onSaveGroup,
  open,
  selectedNode,
}) => {
  const { setAlertMessage } = useAlertMessage();

  const groupName = selectedNode.subGroups?.length
    ? selectedNode.subGroups?.map((val) => val.name?.toLocaleLowerCase())
    : [];

  const isHaveGroupsName = groupName?.includes('groups');
  const isHaveSubscriptionsName = groupName?.includes('subscriptions');
  const isShowNoOption = isHaveGroupsName && isHaveSubscriptionsName;

  const createGroupMutation = useMutation(createChildGroup, {
    onSuccess: (data) => {
      onSaveGroup();
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot create group';
      setAlertMessage({
        message:
          error.response?.status === 409
            ? 'Group name already exists. try another name'
            : message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  const renderForm = () => {
    if (selectedNode?.level === 1) {
      return (
        <Fragment>
          <Box sx={{ mb: 3 }}>
            <TextField name="name" label="Group name" required />
          </Box>
          <Box sx={{ mb: 3 }}>
            <TextField name="industry" label="Industry" required />
          </Box>
        </Fragment>
      );
    } else if (selectedNode?.level === 2) {
      return (
        <DropdownField
          name="name"
          label="Group directory name"
          required
          disabled={isShowNoOption}
        >
          {!isHaveGroupsName && <MenuItem value="Groups">Groups</MenuItem>}
          {!isHaveSubscriptionsName && (
            <MenuItem value="Subscriptions">Subscriptions</MenuItem>
          )}
        </DropdownField>
      );
    } else {
      return <TextField name="name" label="Group name" required />;
    }
  };

  return (
    <StyledDialog onClose={onClose} aria-labelledby="dialog-title" open={open}>
      <StyledDialogTitle id="dialog-title">Add group</StyledDialogTitle>
      <Formik
        initialValues={{ ...initialValues, level: selectedNode.level || 0 }}
        onSubmit={(
          values: GroupForm,
          { setSubmitting }: FormikHelpers<GroupForm>
        ) => {
          const payload: GroupRegisterDto = {
            name: values.name,
            attributes: values.industry ? { industry: [values.industry] } : {},
          };
          createGroupMutation.mutate({
            id: selectedNode.id || '',
            payload,
          });
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <StyledDialogContent>{renderForm()}</StyledDialogContent>
            <DialogActions>
              <ButtonRounded onClick={onClose} color="primary">
                Cancel
              </ButtonRounded>
              <ButtonRounded
                type="submit"
                color="primary"
                variant="contained"
                loadingPosition="start"
                startIcon={<SaveIcon />}
                loading={createGroupMutation.isLoading}
                disabled={!(isValid && dirty) || isSubmitting}
                id="btn-save"
              >
                Save
              </ButtonRounded>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
};

export { GroupFormDialog };
