import { NO_BEGINNING_SPACE_REGEX } from '@bom-nextgen-keycloak/web/shared/util';
import SearchIcon from '@mui/icons-material/Search';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import { FC, useEffect, useState } from 'react';
import {
  Container,
  PaperSearch,
  SearchIconButton,
  SearchInputBase,
  StyledLabel,
  StyledSelect,
} from './SearchInput.styled';

export type OptionSelect = {
  label: string;
  value: any;
};

export type EmitSearchInput = {
  searchValue: string;
  selectValue: any;
  name?: string;
};

export type SearchInputProps = {
  placeholder?: string;
  inputProps?: InputBaseComponentProps;
  width?: number;
  isShowSelectFilter?: boolean;
  options?: OptionSelect[];
  titleLabel?: string;
  name?: string;
  value?: string;
  onChange?: (value: EmitSearchInput) => void | undefined;
  onCliekSearch?: (value: EmitSearchInput) => void | undefined;
  onKeyDown?: (value: EmitSearchInput) => void | undefined;
};

const SearchInput: FC<SearchInputProps> = ({
  placeholder = 'Search',
  inputProps,
  width,
  onChange,
  onCliekSearch,
  onKeyDown,
  isShowSelectFilter = false,
  options = [],
  titleLabel = '',
  name = '',
  value = '',
}) => {
  const [valueSearch, setValue] = useState('');
  const [selectValue, setSelectValue] = useState('');

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13 && onKeyDown) {
      onKeyDown({
        searchValue: e?.target?.value?.trim() || '',
        selectValue,
        name: e.target.name,
      });
    }
  };

  const handleChnage = (event: any) => {
    if (onChange) {
      onChange({
        searchValue: event.target.value?.trim() || '',
        selectValue,
        name: event.target.name,
      });
    }

    setValue(event.target.value);
  };

  const handleChangeFilter = (event: SelectChangeEvent<unknown>) => {
    setSelectValue(event.target.value as string);
  };

  return (
    <Container>
      {titleLabel && <StyledLabel>{titleLabel}</StyledLabel>}
      <PaperSearch width={width}>
        {isShowSelectFilter && (
          <StyledSelect
            labelId="filter-search-select"
            id="filter-search-select"
            disableUnderline
            onChange={handleChangeFilter}
          >
            {options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </StyledSelect>
        )}
        <SearchInputBase
          name={name}
          onChange={handleChnage}
          placeholder={placeholder}
          inputProps={{ ...inputProps, inputMode: 'search' }}
          onKeyDown={handleKeyDown}
          value={valueSearch}
        />
        <SearchIconButton
          onClick={() =>
            onCliekSearch && onCliekSearch({ searchValue: value, selectValue })
          }
          type="button"
          aria-label="search"
        >
          <SearchIcon />
        </SearchIconButton>
      </PaperSearch>
    </Container>
  );
};

export { SearchInput };
