import { selectPermissionRoleDetail } from '@bom-nextgen-keycloak/web/core';
import { FeaturePage } from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  Attributes,
  PageLoading,
  ProtectedRouter,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useRoleDetail } from '../../hooks/useRoleDetail';

const RoleAttribute: FC = () => {
  const params = useParams();
  const roleId = params?.id || '';
  const { roleDetail, isLoading, isLoadingSubmit, handleSaveAttribute } =
    useRoleDetail(roleId);
  const { isEditAttributesTab } = useSelector(selectPermissionRoleDetail);

  return (
    <PageLoading isLoading={isLoading}>
      <Attributes
        loading={isLoadingSubmit}
        attributesDetail={roleDetail?.attributes || {}}
        onSave={handleSaveAttribute}
        isDisabled={!isEditAttributesTab}
      />
    </PageLoading>
  );
};

const WrappedRoleAttributePage: FC = () => {
  const { canViewPage, canViewAttributesTab } = useSelector(
    selectPermissionRoleDetail
  );
  return (
    <ProtectedRouter
      feature={FeaturePage.ROLE}
      isEditPage
      canView={canViewPage && canViewAttributesTab}
      navigatePath="/roles"
    >
      <RoleAttribute />
    </ProtectedRouter>
  );
};

export { WrappedRoleAttributePage };
