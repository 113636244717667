import { UserAuth } from '@bom-nextgen-keycloak/models';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FC } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
type MenuProfileProps = {
  userAuth: UserAuth | null;
  anchorEl: null | HTMLElement;
  menuId: string;
  onClose: () => void;
  onClickLogout: () => void;
};

const MenuProfile: FC<MenuProfileProps> = ({
  menuId,
  userAuth,
  anchorEl,
  onClose,
  onClickLogout,
}) => {
  const isMenuOpen = Boolean(anchorEl);
  return (
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={onClose}
    >
      <MenuItem>
        <IconButton aria-label="settings" color="inherit">
          <PersonIcon />
        </IconButton>
        {userAuth?.email || ''}
      </MenuItem>

      <MenuItem href={userAuth?.accountURL} target="_blank" component={Link}>
        <IconButton aria-label="settings" color="inherit">
          <SettingsIcon />
        </IconButton>
        Manage your Account
      </MenuItem>
      <MenuItem onClick={onClickLogout}>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <ExitToAppIcon />
        </IconButton>
        <p>Log out</p>
      </MenuItem>
    </Menu>
  );
};

export { MenuProfile };
