import { EventQuery, EventType } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionEvent,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  FeaturePage,
  fetchEvent,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { ProtectedRouter } from '@bom-nextgen-keycloak/web/shared/ui';
import { convertLocalToUTCDate } from '@bom-nextgen-keycloak/web/shared/util';
import { DateRange } from '@mui/lab/DateRangePicker';
import { SelectChangeEvent } from '@mui/material/Select';
import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { FilterToolbar } from './FilterToolbar';
import { LoginEventTable } from './LoginEventTable';

const LoginEvent: FC = () => {
  const resource = useResource();
  const { setAlertMessage } = useAlertMessage();
  const [params, setParams] = useState<EventQuery>({
    first: 0,
    max: 200,
    type: 'LOGIN',
  });

  const { isLoading, data: loginEventList = { items: [], totalRecord: 0 } } =
    useQuery(
      [QUERY_KEY.EVENT_LIST, params, resource.clientNamePublic],
      () =>
        fetchEvent({ ...params, clientId: resource.clientNamePublic }).then(
          (value) => ({
            ...value,
            items: value.items.map((item) => ({
              ...item,
              username: item.details?.username || '',
            })),
          })
        ),
      {
        onError: (error: AxiosError<ErrorMessage>) => {
          const message =
            error.response?.data.message || 'Cannot get list login event';
          setAlertMessage({
            message,
            typeStatusMessage: 'error',
            statusCode: error.response?.status,
          });
        },
      }
    );

  const handleChangeType = (event: SelectChangeEvent) => {
    setParams((previous) => ({
      ...previous,
      type: event.target.value as EventType,
      first: 0,
      max: 200,
    }));
  };

  const handleChangeDatePicker = (date: DateRange<Date | null>) => {
    const [startDate, endDate] = date;
    if (startDate && endDate) {
      setParams((previous) => ({
        ...previous,
        startDate: convertLocalToUTCDate(startDate),
        endDate: convertLocalToUTCDate(endDate),
        first: 0,
        max: 200,
      }));
    }
  };

  const handleClearFilter = () => {
    setParams((previous) => ({
      ...previous,
      startDate: null,
      endDate: null,
      type: 'LOGIN',
      first: 0,
      max: 200,
    }));
  };

  const handleChangePage = (event: SelectChangeEvent<unknown>) => {
    const selectedPage = event.target.value as number;
    setParams((previous) => ({
      ...previous,
      first: selectedPage,
    }));
  };

  return (
    <div>
      <FilterToolbar
        startDate={params?.startDate || null}
        endDate={params?.endDate || null}
        type={params.type || ''}
        onChangeType={handleChangeType}
        onChangeDatePicker={handleChangeDatePicker}
        onClickClear={handleClearFilter}
      />
      <LoginEventTable
        data={loginEventList.items}
        loading={isLoading}
        selectedPage={params.first}
        totalRecord={loginEventList.totalRecord}
        handleChange={handleChangePage}
      />
    </div>
  );
};

const WrappedEventLoginPage: FC = () => {
  const { canViewLogin } = useSelector(selectPermissionEvent);
  return (
    <ProtectedRouter
      isEditPage
      feature={FeaturePage.EVENT}
      canView={canViewLogin}
      navigatePath="/events"
    >
      <LoginEvent />
    </ProtectedRouter>
  );
};

export { WrappedEventLoginPage };
