import { ButtonRounded } from '../ButtonRounded';
import { styled } from '@mui/material/styles';
import Input from '@mui/material/Input';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

export const AttributesMain = styled('div')`
  width: 100%;
`;

export const BoxAction = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

export const StyledTextField = styled(Input)`
  width: 100%;
`;

export const StyledTableContainer = styled(TableContainer)`
  max-height: 400px;
`;

export const StyledActionButtonCell = styled(TableCell)`
  width: 15rem;
`;

export const ActionWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  & > * {
    margin-right: 1rem;
  }
`;

export const StyledButton = styled(ButtonRounded)`
  width: 8rem;
`;
