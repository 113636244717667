import { StyledBox } from './TabPanel.styled';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  width?: string;
  isHidden?: boolean;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, width, value, isHidden, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ width: width || 'auto' }}
      {...other}
    >
      {value === index && <StyledBox>{children}</StyledBox>}
    </div>
  );
};

export { TabPanel };
