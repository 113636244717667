import { UserResetPasswordDto } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionUserDetail,
  useAlertMessage,
} from '@bom-nextgen-keycloak/web/core';
import {
  ErrorMessage,
  QUERY_KEY,
  resetPasswordUser,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import {
  ButtonRounded,
  PasswordTextField,
  SwitchToggleField,
} from '@bom-nextgen-keycloak/web/shared/ui';
import { NO_SPACE_REGEX } from '@bom-nextgen-keycloak/web/shared/util';
import Box from '@mui/material/Box';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { ActionWrapper } from '../Credential.styled';

const validationSchema = yup.object({
  newPassword: yup
    .string()
    .matches(NO_SPACE_REGEX, 'No blank space allowed in password')
    .min(6, 'Password should be of minimum 6 characters length')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .matches(NO_SPACE_REGEX, 'No blank space allowed in password confirmation')
    .required('Password confirmation is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

type CredentialForm = {
  newPassword: string;
  confirmPassword: string;
  temporary: boolean;
};

const initialValues: CredentialForm = {
  newPassword: '',
  confirmPassword: '',
  temporary: true,
};

const ResetPasswordForm: FC<{ userId: string; isEmptyCredential: boolean }> = ({
  userId,
  isEmptyCredential,
}) => {
  const { setAlertMessage } = useAlertMessage();
  const { isEditPasswordTab } = useSelector(selectPermissionUserDetail);
  const queryClient = useQueryClient();
  const resetPasswordUserMutation = useMutation(resetPasswordUser, {
    onError: (error: AxiosError<ErrorMessage>) => {
      const message = error.response?.data.message || 'Cannot create user';
      setAlertMessage({
        message,
        typeStatusMessage: 'error',
        statusCode: error.response?.status,
      });
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(
        payload: CredentialForm,
        { setSubmitting, resetForm }: FormikHelpers<CredentialForm>
      ) => {
        const payloadReset: UserResetPasswordDto = {
          value: payload.newPassword,
          temporary: payload.temporary,
          type: 'password',
        };

        resetPasswordUserMutation.mutate(
          { id: userId, payload: payloadReset },
          {
            onSuccess: () => {
              resetForm();
              setSubmitting(false);
              setAlertMessage({
                message: 'Password has been updated',
                typeStatusMessage: 'success',
              });
              queryClient.invalidateQueries([
                QUERY_KEY.USER_CREDENTIALS,
                userId,
              ]);
            },
            onError: () => {
              setSubmitting(false);
            },
          }
        );
      }}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, isValid, values, dirty }) => (
        <Form>
          <Box sx={{ mb: 3 }}>
            <PasswordTextField
              name="newPassword"
              disabled={!isEditPasswordTab}
              label="Password"
            />
          </Box>

          <Box sx={{ mb: 3 }}>
            <PasswordTextField
              disabled={!isEditPasswordTab}
              name="confirmPassword"
              label="Password confirmation"
            />
          </Box>

          <Box sx={{ mb: 3 }}>
            <SwitchToggleField
              label="Temporary"
              name="temporary"
              disabled={!isEditPasswordTab}
              checked={values.temporary}
            />
          </Box>
          <ActionWrapper>
            <ButtonRounded
              type="submit"
              color="primary"
              variant="contained"
              loadingPosition="start"
              disabled={
                !(isValid && dirty) || isSubmitting || !isEditPasswordTab
              }
            >
              {isEmptyCredential ? 'Set Password' : 'Reset Password'}
            </ButtonRounded>
          </ActionWrapper>
        </Form>
      )}
    </Formik>
  );
};

export { ResetPasswordForm };
