import {
  ClientDto,
  ClientQuery,
  ClientRoleMemberQueryDto,
  ClientRoleQueryDto,
  ClientRoleRegister,
  IRoleList,
  IUserList,
  RoleRepresentationDto,
  RoleUpdate,
} from '@bom-nextgen-keycloak/models';
import { axiosInstance } from '@bom-nextgen-keycloak/web/shared/util';
import { AxiosResponse } from 'axios';

export type CreateClientRoleParams = {
  clientId: string;
  payload: ClientRoleRegister;
};

export type UpdateClientRoleParams = {
  clientId: string;
  roleName: string;
  payload: RoleUpdate;
};

export type DeleteClientRoleParams = {
  clientId: string;
  roleName: string;
};

export type GetClientRoleByRoleNameParams = {
  clientId: string;
  roleName: string;
  params: ClientRoleMemberQueryDto;
};

export const fetchClientRole = (clientId: string, params: ClientRoleQueryDto) =>
  axiosInstance
    .get(`/clients/${clientId}/roles`, { params: { ...params } })
    .then(({ data }: AxiosResponse<IRoleList>) => data);

export const fetchClientById = (clientId: string) =>
  axiosInstance
    .get(`/clients/${clientId}`)
    .then(({ data }: AxiosResponse<ClientDto>) => data);

export const fetchClientRoleByRoleName = ({
  clientId,
  roleName,
}: GetClientRoleByRoleNameParams) =>
  axiosInstance
    .get(`/clients/${clientId}/roles/${roleName}`)
    .then(({ data }: AxiosResponse<RoleRepresentationDto>) => data);

export const createClientRole = ({
  clientId,
  payload,
}: CreateClientRoleParams) =>
  axiosInstance.post<
    AxiosResponse<{
      roleName: string;
    }>
  >(`/clients/${clientId}/roles`, payload);

export const updateClientRole = ({
  clientId,
  roleName,
  payload,
}: UpdateClientRoleParams) =>
  axiosInstance.put<AxiosResponse<void>>(
    `/clients/${clientId}/roles/${roleName}`,
    payload
  );

export const deleteClientRole = ({
  clientId,
  roleName,
}: DeleteClientRoleParams) =>
  axiosInstance.delete(`/clients/${clientId}/roles/${roleName}`);

export const fetchClientUserInRole = ({
  clientId,
  roleName,
  params,
}: GetClientRoleByRoleNameParams) =>
  axiosInstance
    .get(`/clients/${clientId}/roles/${roleName}/users`, {
      params: { ...params },
    })
    .then(({ data }: AxiosResponse<IUserList>) => data);

export const fetchClients = (params: ClientQuery) =>
  axiosInstance
    .get(`/clients`, {
      params: { ...params },
    })
    .then(({ data }: AxiosResponse<ClientDto[]>) => data);
