import { ClientRoleMemberQueryDto } from '@bom-nextgen-keycloak/models';
import {
  selectPermissionRoleDetail,
  selectPermissionUserDetail,
  useAlertMessage,
  useResource,
} from '@bom-nextgen-keycloak/web/core';
import { UserTable } from '@bom-nextgen-keycloak/web/pages/feature-user';
import {
  ErrorMessage,
  FeaturePage,
  fetchClientUserInRole,
  QUERY_KEY,
} from '@bom-nextgen-keycloak/web/shared/data-access';
import { ProtectedRouter } from '@bom-nextgen-keycloak/web/shared/ui';
import { SelectChangeEvent } from '@mui/material/Select';
import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useRoleDetail } from '../../hooks/useRoleDetail';

const UserInRole: FC = () => {
  const routerParams = useParams();
  const roleId = routerParams?.id || '';
  const data = useRoleDetail(roleId);
  const { setAlertMessage } = useAlertMessage();
  const resource = useResource();
  const roleName = data?.roleDetail?.name || '';

  const { canViewPage } = useSelector(selectPermissionUserDetail);

  const [params, setParams] = useState<ClientRoleMemberQueryDto>({
    first: 0,
    max: 200,
    groupId: resource.groupId,
  });

  const { isLoading, data: userList = { items: [], totalRecord: 10 } } =
    useQuery(
      [QUERY_KEY.CLIENT_USER_IN_ROLE, roleId, params],
      () =>
        fetchClientUserInRole({
          clientId: resource.clientId,
          roleName,
          params,
        }),
      {
        enabled: !!roleName,
        cacheTime: 0,
        onError: (error: AxiosError<ErrorMessage>) => {
          const message =
            error.response?.data.message ||
            'Cannot get client user in role list';
          setAlertMessage({
            message,
            typeStatusMessage: 'error',
            statusCode: error.response?.status,
          });
        },
      }
    );

  const handleChangePage = (event: SelectChangeEvent<unknown>) => {
    const selectedPage = event.target.value as number;
    setParams((previous) => ({
      ...previous,
      first: selectedPage,
    }));
  };

  return (
    <UserTable
      data={userList.items || []}
      totalRecord={userList.totalRecord}
      loading={isLoading}
      canViewEditBtn={canViewPage}
      selectedPage={params.first}
      handleChange={handleChangePage}
      isShowDeleteButton={false}
    />
  );
};

const WrappedUserInRolePage: FC = () => {
  const { canViewPage, canViewUserTab } = useSelector(
    selectPermissionRoleDetail
  );
  return (
    <ProtectedRouter
      feature={FeaturePage.ROLE}
      isEditPage
      canView={canViewPage && canViewUserTab}
      navigatePath="/roles"
    >
      <UserInRole />
    </ProtectedRouter>
  );
};

export { WrappedUserInRolePage };
